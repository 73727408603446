import './App.css';
import { AppRouter } from './navigation/AppRouter';
import React, { useEffect } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';

function App() {
    // useEffect(() => {
    //     const unloadCallback = (event) => {
    //       event.preventDefault();
    //       event.returnValue = "";
    //       return "";
    //     };
    // window.addEventListener("beforeunload", unloadCallback);
    //     return () => window.removeEventListener("beforeunload", unloadCallback);
    // }, []);

    return (
        <>
            <ProSidebarProvider>
                <AppRouter />
            </ProSidebarProvider>
        </>
    );
}

export default App;
