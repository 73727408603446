import React, { useEffect, useState } from 'react';

export const AppInputDepartment = ({
    value,
    handleChange,
    handleError,
    submit,
    departments,
}) => {
    const [errorDepartment, setErrorDepartment] = useState('');

    useEffect(() => {
        setErrorDepartment('');
        if (submit > 0 && value === '') {
            setErrorDepartment('*this field is required');
        }
        handleError(errorDepartment);
    }, [value, submit, errorDepartment, departments]);

    // const options = () => {
    //     try {
    //         departments.map((departmentItem, index) => {
    //             return (
    //                 <option value={departmentItem.picDepartmentId}>
    //                     {departmentItem.picDepartmentName}
    //                 </option>
    //             );
    //         });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const handleChangeDepartment = (e) => {
        handleChange(e.target.value);
    };

    return (
        <div className="department">
            <label>Department</label>
            <select
                className="department-dropdown"
                value={value}
                onChange={handleChangeDepartment}
            >
                <option value="" disabled selected hidden>
                    --select department--
                </option>
                {departments.length === undefined ? null : departments.map((departmentItem, index) => {
                return (
                    <option value={departmentItem.picDepartmentId}>
                        {departmentItem.picDepartmentName}
                    </option>
                );
            })}
            </select>
            <small className="error">
                <strong>
                    <i>{errorDepartment}</i>
                </strong>
            </small>
        </div>
    );
};
