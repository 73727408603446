import React from "react";

export const ticketService = ({ doGet, doPost, doPut, downloadFile }) => {
  const getAllTicket = async (page, sort, userId, roleId, order) => {
    try {
      return await doGet({
        url:
          "/api/ticket/list/" +
          String(page) +
          "/" +
          String(sort) +
          "/" +
          String(order),
        params: { uid: userId, rid: roleId },
      });
    } catch (error) {
      throw error;
    }
  };

  const getAllTicketForAdmin = async (
    page,
    sort,
    deptId,
    statusId,
    order,
    ticketNumberorTitle,
    userId,
    roleId
  ) => {
    try {
      return await doPost({
        url: "/api/ticket/list-new",
        data: {
          order: order,
          page: page,
          sortBy: sort,
          search: ticketNumberorTitle,
          did: deptId,
          sid: statusId,
          uid: userId,
          rid: roleId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const getAllTicketByDepartmentId = async (page, sort, deptId) => {
    try {
      return await doGet({
        url:
          "api/ticket/department/open/" +
          String(deptId) +
          "/" +
          String(page) +
          "/" +
          String(sort),
      });
    } catch (error) {
      throw error;
    }
  };

  const getSummaryTicket = async () => {
    try {
      return await doGet({ url: "/api/ticket/summary" });
    } catch (error) {
      throw error;
    }
  };

  const getSummaryTicketFilter = async (date, month, year) => {
    try {
      return await doGet({
        url: "/api/ticket/summary-filter",
        params: { date: date, month: month, year: year },
      });
    } catch (error) {
      throw error;
    }
  };

  const createTicket = async (ticket) => {
    try {
      return await doPost({
        url: "/api/ticket",
        data: ticket,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      throw error;
    }
  };

  const updatePicTicket = async (newPic) => {
    try {
      return await doPut({ url: "/api/ticket/update-pic", data: newPic });
    } catch (error) {
      throw error;
    }
  };

  const updateStatusTicket = async (newStatus) => {
    try {
      return await doPut({
        url: "/api/ticket/update-status",
        data: newStatus,
      });
    } catch (error) {
      throw error;
    }
  };

  const updateDeptTicket = async (ticketId, newDept) => {
    try {
      return await doPut({
        url: "/api/ticket/department/update/" + ticketId,
        data: newDept,
      });
    } catch (error) {
      throw error;
    }
  };

  const setNewDeptPic = async (ticketId, newDeptPic) => {
    try {
      return await doPut({
        url: `api/ticket/department-pic/update/${ticketId}`,
        data: newDeptPic,
      });
    } catch (error) {
      throw error;
    }
  };

  const getTicketById = async (id) => {
    try {
      return await doGet({ url: "/api/ticket/" + String(id) });
    } catch (error) {
      throw error;
    }
  };

  const getPicByPicId = async (id) => {
    try {
      return await doGet({ url: "/api/ticket/pic/" + String(id) });
    } catch (error) {
      throw error;
    }
  };

  const getSummaryChart = async () => {
    try {
      return await doGet({ url: "/api/ticket/summary-chart" });
    } catch (error) {
      throw error;
    }
  };

  const getSummaryChartMonthly = async () => {
    try {
      return await doGet({ url: "/api/ticket/summary-month" });
    } catch (error) {
      throw error;
    }
  };

  const downloadAttachment = async (fileName) => {
    try {
      return await downloadFile(
        fileName,
        "/api/ticket/attachment/download/" + String(fileName)
      );
    } catch (error) {
      throw error;
    }
  };

  const getAllTicketFilterDept = async (
    userId,
    roleId,
    deptId,
    page,
    sort,
    order
  ) => {
    try {
      return await doGet({
        url: `/api/ticket/department/${userId}/${roleId}/${deptId}/${page}/${sort}/${order}`,
      });
    } catch (error) {
      throw error;
    }
  };
  const getAllTicketFilterStatus = async (
    userId,
    roleId,
    statusId,
    page,
    sort,
    order
  ) => {
    try {
      return await doGet({
        url: `/api/ticket/status/${userId}/${roleId}/${statusId}/${page}/${sort}/${order}`,
      });
    } catch (error) {
      throw error;
    }
  };

  const deleteTicket = async (ticketNumber) => {
    try {
      return await doPut({ url: `/api/ticket/delete/${ticketNumber}` });
    } catch (error) {
      throw error;
    }
  };

  const postSolution = async (roleId, ticketId, ticketSolution) => {
    try {
      return await doPost({
        url: `/api/ticket/solution`,
        data: {
          roleId: roleId,
          ticketId: ticketId,
          ticketSolution: ticketSolution,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const postSolutionWithFile = async (solutionFormData) => {
    try {
      return await doPost({
        url: `/api/ticket/solution`,
        data: solutionFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (e) {
      throw e;
    }
  };

  const updateResolve = async (roleId, ticketId, userId) => {
    try {
      return await doPut({
        url: `/api/ticket/resolve`,
        data: {
          roleId: roleId,
          ticketId: ticketId,
          resolverId: userId,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const searchTicket = async (ticketNumberorTitle, userId, roleId) => {
    try {
      return await doGet({
        url: `/api/ticket/search/${ticketNumberorTitle}`,
        params: { uid: userId, rid: roleId },
      });
    } catch (error) {
      throw error;
    }
  };

  const getTicketHistory = async (ticketNumber) => {
    try {
      return await doGet({ url: `/api/ticket/history/${ticketNumber}` });
    } catch (error) {
      throw error;
    }
  };

  const editTicket = async (ticketId, title, priority, description) => {
    try {
      return await doPut({
        url: `/api/ticket/update-ticket`,
        data: {
          ticketId: ticketId,
          ticketSubject: title,
          priorityId: priority,
          ticketMessage: description,
        },
      });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketCreateCS = async (id) => {
    try {
      return await doGet({ url: `/api/ticket/summary/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketByDateCreateCS = async (id, date, month, year) => {
    try {
      return await doGet({
        url: `/api/ticket/summary-filter/${id}`,
        params: { date: date, month: month, year: year },
      });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartCreateCS = async (id) => {
    try {
      return await doGet({ url: `/api/ticket/summary-chart/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartByMonthCreateCS = async (id) => {
    try {
      return await doGet({ url: `api/ticket/summary-month/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketAllCS = async (id) => {
    try {
      return await doGet({ url: `api/ticket/summary-all/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketByDateAllCS = async (id, date, month, year) => {
    try {
      return await doGet({
        url: `/api/ticket/summary-filter-all/${id}`,
        params: { date: date, month: month, year: year },
      });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartAllCS = async (id) => {
    try {
      return await doGet({ url: `/api/ticket/summary-chart-all/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartByMonthAllCS = async (id) => {
    try {
      return await doGet({ url: `api/ticket/summary-month-all/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketAssign = async (id) => {
    try {
      return await doGet({ url: `api/ticket/summary-pic/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryTicketByDateAssign = async (id, date, month, year) => {
    try {
      return await doGet({
        url: `/api/ticket/summary-filter-pic/${id}`,
        params: { date: date, month: month, year: year },
      });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartAssign = async (id) => {
    try {
      return await doGet({ url: `/api/ticket/summary-chart-pic/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getTotalSummaryChartByMonthAssign = async (id) => {
    try {
      return await doGet({ url: `api/ticket/summary-month-pic/${id}` });
    } catch (e) {
      throw e;
    }
  };

  const getCreatedCountPerUserId = async (id) => {
    try {
      return await doGet({ url: `api/ticket/total-created-per-user` });
    } catch (e) {
      throw e;
    }
  };

  const getAssignedCountPerUserId = async (id) => {
    try {
      return await doGet({ url: `api/ticket/total-assigned-per-user` });
    } catch (e) {
      throw e;
    }
  };

  const getSla = async (role, page) => {
    try {
      return await doGet({ url: `sla/${role}/${page}` });
    } catch (e) {
      throw e;
    }
  };

  return {
    getAllTicket,
    getSummaryTicket,
    createTicket,
    updatePicTicket,
    updateStatusTicket,
    getAllTicketByDepartmentId,
    getAllTicketForAdmin,
    getTicketById,
    getPicByPicId,
    getSummaryChart,
    downloadAttachment,
    getAllTicketFilterDept,
    getAllTicketFilterStatus,
    getSummaryTicketFilter,
    deleteTicket,
    updateDeptTicket,
    postSolution,
    updateResolve,
    searchTicket,
    getTicketHistory,
    getSummaryChartMonthly,
    editTicket,
    setNewDeptPic,
    getTotalSummaryTicketCreateCS,
    getTotalSummaryTicketByDateCreateCS,
    getTotalSummaryChartCreateCS,
    getTotalSummaryChartByMonthCreateCS,
    getTotalSummaryTicketAllCS,
    getTotalSummaryTicketByDateAllCS,
    getTotalSummaryChartAllCS,
    getTotalSummaryChartByMonthAllCS,
    getTotalSummaryTicketAssign,
    getTotalSummaryTicketByDateAssign,
    getTotalSummaryChartAssign,
    getTotalSummaryChartByMonthAssign,
    postSolutionWithFile,
    getCreatedCountPerUserId,
    getAssignedCountPerUserId,
    getSla,
  };
};
