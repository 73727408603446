import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUnauthorized } from "../../../shared/components/Popup/AppUnauthorized/useUnauthorized";
import { useDependency } from "../../../shared/hook/UseDependency";
var fileDownload = require("js-file-download");

export const useTicketDetail = () => {
  const navigate = useNavigate();
  const {
    state: { ticketId, origin },
  } = useLocation();
  const [ticket, setTicket] = useState({
    ticketId: "",
    ticketDate: "",
    PicDepartment: { picDepartmentName: "" },
    Pic: { picUser: "" },
    ticketSolution: "",
  });
  const [updatePic, setUpdatePic] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateDept, setUpdateDept] = useState(false);
  const [newDept, setNewDept] = useState(3);
  const [deptList, setDeptList] = useState([]);
  const [newPic, setNewPic] = useState("{picId : 0, picName : ''}");
  const [newStatus, setNewStatus] = useState("{id : 0, statusName : ''}");
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [confirmPic, setConfirmPic] = useState(false);
  const [confirmDept, setConfirmDept] = useState(false);
  const [successDept, setSuccessDept] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [successPic, setSuccessPic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listPic, setListPic] = useState([]);
  const { picService, ticketService, notificationService, departementService } =
    useDependency();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const { unAuthorized, setUnauthorized } = useUnauthorized();
  const [file, setFile] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [solution, setSolution] = useState("");
  const [confirmSolution, setConfirmSolution] = useState(false);
  const [successSolution, setSuccessSolution] = useState(false);
  const [confirmResolve, setConfirmResolve] = useState(false);
  const [successResolve, setSuccessResolve] = useState(false);
  const [errorSolution, setErrorSolution] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [editSubmisson, setEditSubmisson] = useState(false);
  const [cancelEdit, setCancelEdit] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescriprion] = useState("");
  const [priority, setPriority] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [editPrior, setEditPrior] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [disabledSelectPic, setDisableSelectPic] = useState(true);
  const [failedUpdate, setFailedUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [picId, setPicId] = useState("");
  const [editDeptPic, setEditDeptPic] = useState(false);
  const [confirmDeptPic, setConfirmDeptPic] = useState(false);
  const [successDeptPic, setSuccessDeptPic] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [score, setScore] = useState(0);
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");

  const handleBack = () => {
    navigate(-1);
    setSuccessPic(false);
    setSuccessStatus(false);
    setSuccessDeptPic(false);
  };

  const handleSubmitSolution = () => {
    setSubmitCount(submitCount + 1);
    if (solution.trim() !== "" && errorSolution === "") {
      setConfirmSolution(true);
    }
  };

  const handleSolutionValidation = () => {
    setErrorSolution("");
    if (submitCount > 0 && solution.trim() === "") {
      setErrorSolution("*this field is required");
    } else if (solution.length >= 1000) {
      setErrorSolution("*max 1000 char!");
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const onGetPicDepartement = async (deptId) => {
    setLoading(true);
    try {
      const response = await picService.getPicByDepartement(deptId);
      if (response.status === "SUCCESS") {
        if (response.message !== null) {
          setListPic(response.message);
        } else {
          setListPic([]);
        }
      } else {
        setListPic([]);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onGetAllDepartement = async () => {
    setLoading(true);
    try {
      const response = await departementService.getAllDepartement();
      if (response.status === "SUCCESS") {
        setDeptList(response.message);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onCreateNotif = async (userId, ticketId, type) => {
    let newNotif = {
      ticketId: ticketId,
      type: type,
    };
    try {
      await notificationService.addNotif(userId, newNotif);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePic = (e) => {
    setNewPic(e.target.value);
    setDisableSelectPic(false);
  };

  const onUpdatePic = async () => {
    setConfirmPic(false);
    setLoading(true);
    let pic = JSON.parse(newPic);
    try {
      const updated = {
        ticketId: ticket.ticketId,
        picId: pic.picId,
      };
      if (pic.picId === 0) {
        const updatedStatus = {
          ticketId: ticket.ticketId,
          statusId: 1,
        };
        // try {
        const response = await ticketService.updatePicTicket(updated);
        if (response.status === "SUCCESS") {
          ticket.picId = pic.picId;
          ticket.Pic = {
            picId: pic.picId,
            picUser: pic.picName,
            picName: pic.picname,
          };
          onCreateNotif(pic.picUserId, updated.ticketId, "assignment");
          setUpdatePic(false);
          setNewPic("{picId : 0, picName : ''}");
          setSuccessPic(true);
        }
        // } catch (e) {
        // console.log(e);
        // }
        await ticketService.updateStatusTicket(updatedStatus);
      } else {
        const response = await ticketService.updatePicTicket(updated);
        if (response.status === "SUCCESS") {
          ticket.picId = pic.picId;
          ticket.Pic = {
            picId: pic.picId,
            picUser: pic.picName,
            picName: pic.picname,
          };
          onCreateNotif(pic.picUserId, updated.ticketId, "assignment");
          setUpdatePic(false);
          setNewPic("{picId : 0, picName : ''}");
          setSuccessPic(true);
        }
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      if (error.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to update PIC");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //     console.log('ticket', ticket.PicDepartment.picDepartmentCode);
  // }, [ticket])

  const validateFileSize = () => {
    if (file.length === 0) {
      return true;
    }

    for (let i = 0; i < file.length; i++) {
      if (file[i].size > 2000000) {
        return false;
      }
    }

    return true;
  };

  const onSetDepartmentPic = async () => {
    setConfirmDeptPic(false);
    setLoading(true);
    let pic = JSON.parse(newPic);
    try {
      const setNewDeptPic = {
        newPicDeptId: Number(newDept),
        newPicId: pic.picId,
        oldDeptCode: ticket.PicDepartment.picDepartmentCode,
        currentCS: user.userId,
      };
      const response = await ticketService.setNewDeptPic(
        ticket.ticketId,
        setNewDeptPic
      );
      if (response.status === "SUCCESS") {
        setEditDeptPic(false);
        setSuccessDeptPic(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateDept = async () => {
    setConfirmDept(false);
    setLoading(true);
    try {
      const updated = {
        picDepartmentId: Number(newDept),
        oldDepartmentCode: ticket.PicDepartment.picDepartmentCode,
      };
      const response = await ticketService.updateDeptTicket(
        ticket.ticketId,
        updated
      );
      if (response.status === "SUCCESS") {
        setUpdateDept(false);
        setNewDept(3);
        setSuccessDept(true);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      if (error.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to update department");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateStatus = async () => {
    setConfirmStatus(false);
    setLoading(true);
    let status = JSON.parse(newStatus);
    try {
      const updated = {
        ticketId: ticket.ticketId,
        statusId: status.id,
      };
      const response = await ticketService.updateStatusTicket(updated);
      if (response.status === "SUCCESS") {
        ticket.statusId = status.id;
        ticket.Status = status;
        setUpdateStatus(false);
        setNewStatus("{id : 0, statusName : ''}");
        setSuccessStatus(true);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      if (error.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to update status");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticket.ticketSolution !== "") {
      setSolution(ticket.ticketSolution);
      setSubmitted(true);
    }
    if (ticket.ticketMessage !== undefined) {
      setDescriprion(ticket.ticketMessage);
    }
  }, [ticket]);

  const onGetDetailTicket = async () => {
    setLoading(true);
    try {
      const response = await ticketService.getTicketById(ticketId);
      if (response.status === "SUCCESS") {
        setTicket(response.message);
        setTitle(response.message.ticketSubject);
        setDescriprion(response.message.ticketMessage);
        setPriority(response.message.priorityId);
        if (response.message.ticketSolution !== "") {
          setSolution(response.message.ticketSolution);
          setSubmitted(true);
        }
        onGetPicDepartement(response.message.departmentId);
        setCategory(response.message.Category.categoryName);
        setProduct(response.message.Product.productName);
        setPicId(response.message.Pic.picUser);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onEditTicket = async () => {
    setLoading(true);
    try {
      const response = await ticketService.editTicket(
        ticketId,
        title,
        priority,
        description
      );
      if (response.status === "SUCCESS") {
        setSuccessEdit(true);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      if (error.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to update ticket");
      }
    } finally {
      setLoading(false);
      setConfirmEdit(false);
    }
  };

  const onDownloadAttachment = async (fileName) => {
    setLoading(true);
    try {
      await ticketService.downloadAttachment(fileName);
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true);
      // } else {
      //     console.log(error);
      // }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("ticket", ticket);
  }, [ticket]);

  const onPostSolution = async (roleId, ticketId, ticketSolution) => {
    setLoading(true);

    const solutionFormData = new FormData();
    solutionFormData.append("roleId", roleId);
    solutionFormData.append("ticketId", ticketId);
    solutionFormData.append("ticketSolution", ticketSolution);
    for (let i = 0; i < file.length; i++) {
      solutionFormData.append("fileAttachment", file[i]);
    }

    try {
      //const response = await ticketService.postSolution(roleId, ticketId, ticketSolution);
      const response = await ticketService.postSolutionWithFile(
        solutionFormData
      );
      if (response.status === "SUCCESS") {
        onCreateNotif(ticket.csId, ticketId, "solution submitted");
        setSuccessSolution(true);
        console.log("POST SOLUTION SUCCESS");
        // console.log('solution posted', ticketSolution);
      }
    } catch (e) {
      console.log("error post solution:", e);
      if (e.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to update submitted solution");
      }
    } finally {
      setLoading(false);
    }
  };

  const onResolve = async (roleId, ticketId, userId) => {
    console.log("user id", userId);
    setLoading(true);
    try {
      const response = await ticketService.updateResolve(
        roleId,
        ticketId,
        userId
      );
      if (response.status === "SUCCESS") {
        setSuccessResolve(true);
        console.log("RESOLVE SUCCESS");
      }
      setScore(response.score);
    } catch (e) {
      console.log("error resolve:", e);
      if (e.message === "Network Error") {
        setFailedUpdate(true);
        setErrorMessage("Connection problem");
      } else {
        setFailedUpdate(true);
        setErrorMessage("Failed to resolve ticket");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetDetailTicket();
    onGetAllDepartement();
  }, [ticketId, successResolve, editSubmisson]);

  useEffect(() => {
    onGetAllDepartement();
    onGetPicDepartement(Number(newDept));
    console.log("newDept", newDept);
  }, [newDept]);

  useEffect(() => {
    if (updatePic === true) {
      onGetPicDepartement(ticket.PicDepartment.picDepartmentId);
    }
  }, [updatePic]);

  useEffect(() => {
    console.log("listPic", listPic);
  }, [listPic]);

  useEffect(() => {
    console.log("deptList", deptList);
  }, [deptList]);

  useEffect(() => {
    handleSolutionValidation();
  }, [solution, submitCount, errorSolution]);

  return {
    user,
    ticket,
    handleBack,
    formatDate,
    updatePic,
    setUpdatePic,
    updateStatus,
    setUpdateStatus,
    newPic,
    setNewPic,
    newStatus,
    setNewStatus,
    listPic,
    onUpdatePic,
    onUpdateStatus,
    onDownloadAttachment,
    file,
    loading,
    confirmStatus,
    setConfirmStatus,
    confirmPic,
    setConfirmPic,
    successStatus,
    successPic,
    updateDept,
    setUpdateDept,
    setNewDept,
    deptList,
    confirmDept,
    successDept,
    onUpdateDept,
    setConfirmDept,
    unAuthorized,
    submitted,
    setSubmitted,
    solution,
    setSolution,
    onPostSolution,
    onResolve,
    confirmSolution,
    setConfirmSolution,
    successSolution,
    setSuccessSolution,
    confirmResolve,
    setConfirmResolve,
    successResolve,
    setSuccessResolve,
    errorSolution,
    submitCount,
    setSubmitCount,
    handleSubmitSolution,
    editSubmisson,
    setEditSubmisson,
    cancelEdit,
    setCancelEdit,
    editTitle,
    setEditTitle,
    editDesc,
    setEditDesc,
    editPrior,
    setEditPrior,
    title,
    setTitle,
    description,
    setDescriprion,
    priority,
    setPriority,
    confirmEdit,
    setConfirmEdit,
    successEdit,
    setSuccessEdit,
    onEditTicket,
    handleChangePic,
    disabledSelectPic,
    failedUpdate,
    setFailedUpdate,
    errorMessage,
    picId,
    editDeptPic,
    setEditDeptPic,
    confirmDeptPic,
    setConfirmDeptPic,
    onSetDepartmentPic,
    successDeptPic,
    setSuccessDeptPic,
    validateFileSize,
    confirmation,
    setConfirmation,
    file,
    setFile,
    score,
    product,
    category,
  };
};
