import { async } from '@firebase/util';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useUnauthorized } from '../../../shared/components/Popup/AppUnauthorized/useUnauthorized';
import { USER } from '../../../shared/constants';
import { useDependency } from '../../../shared/hook/UseDependency';

export const useTicketComment = () => {
    const [buttonSubmit, setButtonSubmit] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [commentListFireStore, setCommentListFireStore] = useState([])
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(window.localStorage.getItem("user"));
    const {state : {ticketId}} = useLocation();
    const [ticket, setTicket] = useState({ticketId : '', ticketDate : '', PicDepartment : {picDepartmentName : ''}, Pic: {picUser : ''}});
    const {notificationService, ticketService, commentServiceFireStore} = useDependency();
    const {unAuthorized, setUnauthorized} = useUnauthorized();

    const onCreateComment = async () => {
        // setLoading(true)
        try {
            let inputComment = {
                ticketId : ticket.ticketId,
                userId : user.userId,
                userName: user.userName,
                commentMessage : newComment,
            }
            commentServiceFireStore.createComment(ticket.ticketId, inputComment) // make firebase
            setNewComment('')
            onCreateNotif();
        } catch (error) {
            console.log(error);
        } finally {
            // setLoading(false)
        }
    }

    const onCreateNotif = async () => {
        let newComment = {
            ticketId : ticket.ticketId,
            type : "comment"
        }
        let userId = '';
        if (user.roleName === USER.ROLE.PIC){
            userId = ticket.csId
        } else {
            if (ticket.picId !== 0){
                userId = ticket.Pic.picUser
            }
        }

        try {
            await notificationService.addNotif(userId, newComment)
        } catch (error) {
            console.log(error);
        }
    }

    const onGetDetailTicket = async () => {
        setLoading(true);
        try {
            const response = await ticketService.getTicketById(ticketId)
            if(response.status === 'SUCCESS'){
                setTicket(response.message)
            }
        } catch (error) {
            // if (error.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(error);
            // }
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const onGetAllCommentFireStore = async (ticketId) => {
        try {
            const response = await commentServiceFireStore.getAllCommentByTicketId(ticketId, setCommentListFireStore)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        onGetDetailTicket();
        onGetAllCommentFireStore(ticketId);
    }, [ticketId])

    return { buttonSubmit, setButtonSubmit, user, newComment,setNewComment , commentList, onCreateComment, loading, unAuthorized, commentListFireStore}
}
