import React, { useEffect, useState } from 'react'
import { useUnauthorized } from '../../shared/components/Popup/AppUnauthorized/useUnauthorized';
import { useDependency } from '../../shared/hook/UseDependency';
import useViewState from '../../shared/hook/UseViewState'

export const useDashboard = () => {
    const {viewState, setLoading, setData, setError} = useViewState();
    const [summaryData, setSummaryData] = useState({open : 0, onProgress : 0, closed : 0})
    const [chartData, setChartData] = useState({labels : [], datasets : [{}]});
    const [showDate, setShowDate] = useState(false);
    const [showMonth, setShowMonth] = useState(false);
    const [filterDate, setFilterDate] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const {unAuthorized,setUnauthorized} = useUnauthorized();
    const [showBy, setShowBy] = useState('daily');
    const {ticketService} = useDependency();

    useEffect(() => {
        onGetTicketChart();
    }, [showBy])
    useEffect(() => {
        onGetTicketSummary();
    }, [filterDate, filterMonth])

    const formatDate = (dateString) => {
        if (showBy === 'daily') {
            const options = { year: "numeric", month: "long", day: "numeric"}
            return new Date(dateString).toLocaleDateString(undefined, options)
        }
        const options = { year: "numeric", month: "long"}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleFilterDate = (e) => {
        setFilterDate(e.target.value);
        setFilterMonth('');
    }

    const handleFilterMonth = (e) => {
        setFilterMonth(e.target.value);
        setFilterDate('');
    }

    const onGetTicketSummary = async () => {
        setLoading();
        try {
            let totalOpen = 0;
            let totalClose = 0;
            let totalProgress = 0;
            let response = '' 
            if (filterDate !== '') {
                let [year, month, date] = filterDate.split('-')
                response = await ticketService.getSummaryTicketFilter(date, month, year)
            } else if (filterMonth !== ''){
                let [year, month] = filterMonth.split('-')
                response = await ticketService.getSummaryTicketFilter('', month, year)
            } else {
                response = await ticketService.getSummaryTicket();
            }
            if (response.status === 'SUCCESS') {
                response.message.summary.map((data) => {
                    if (data.statusId === 1){
                        totalOpen = data.totalTicket
                    } else if (data.statusId === 2) {
                        totalProgress = data.totalTicket
                    } else {
                        totalClose = data.totalTicket
                    }
                })
                setSummaryData({open : totalOpen, onProgress : totalProgress, closed : totalClose})
            }
        } catch (error) {
            // if (error.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(error);
            // }
            console.log(error);
        }
    }

    const handleClearFilter = () => {
        setFilterDate('');
        setFilterMonth('');
        setShowDate(false);
        setShowMonth(false);
    }

    const onGetTicketChart = async () => {
        setLoading();
        try {
            let response = {}
            if (showBy === 'daily') {
                response = await ticketService.getSummaryChart();
            } else {
                response = await ticketService.getSummaryChartMonthly();
            }

            if (response.status === 'SUCCESS') {
                let labels = [];
                if (showBy === 'daily'){
                    labels = response.message.summary.map(data => formatDate(data.date));
                } else {
                    labels = response.message.summary.map(data => formatDate(data.month));
                }

                const chartData = {
                    labels : labels,
                    datasets : [
                        {
                            label : 'Open Ticket',
                            data : response.message.summary.map(data => data.open),
                            backgroundColor : "#B70621",
                            maxBarThickness : 50
                        },
                        {
                            label : 'On Progress Ticket',
                            data : response.message.summary.map(data => data.onProgress),
                            backgroundColor : "#333132",
                            maxBarThickness : 50
                        },
                        {
                            label : 'Closed Ticket',
                            data : response.message.summary.map(data => data.closed),
                            backgroundColor : "#00AA91",
                            maxBarThickness : 50
                        }
                    ]
                }
                setChartData(chartData);
            }
        } catch (error) {
            // if (error.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(error);
            // }
            console.log(error);
        }
    }

    return {
        summaryData, chartData, showDate, setShowDate ,showMonth, setShowMonth, handleFilterDate, handleFilterMonth, filterDate, filterMonth, handleClearFilter, unAuthorized, setShowBy
    }
}
