import React from 'react';
import './InputText.css';

export const AppInputText = ({ label, value, handleChange, placeholder, disable=false }) => {
    const onChange = (e) => {
        handleChange(e.target.value);
    };

    return (
        <div>
            <label>
                {label}
                <br />
            </label>
            <input
                type="text"
                className="input form-control input-text"
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                disabled={disable}
            />
        </div>
    );
};
