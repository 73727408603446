import './AddUserCancel.css';
import { CgClose } from 'react-icons/cg';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../../../shared/components/AppButton/AppButtonSubmitPrimary';

const AddUserCancelView = ({ handleYes, handleClose }) => {
    return (
        <div className="warn-add-user-container">
            <div className="cancel-ellips">
                <CgClose
                    className="close-icon"
                    color="#FFFFFF"
                    size={100}
                />
            </div>
            <div className="warn-add-user-content-cancel">
                <br />
                <h5 style={{ color: '#B70621', padding: '5px' }}>
                    Are you sure want to Cancel?
                </h5>
                <br />
                <div className="buttons">
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'Yes'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleYes}
                        />
                    </div>
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-secondary new-user-btn'}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserCancelView;
