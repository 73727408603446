import React, { useEffect, useState } from 'react';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import useEditDepartment from './useEditDepartment';
import './EditDepartment.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Tooltip } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import AppUnauthorized from '../../../shared/components/Popup/AppUnauthorized/AppUnauthorized';
import Collapse from 'react-bootstrap/Collapse';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';

const EditDepartmentView = (props) => {
    const {
        name,
        handleErrorName,
        handleChangeName,
        code,
        handleErrorCode,
        handleChangeCode,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        showEditName,
        setShowEditName,
        showEditCode,
        setShowEditCode,
        unAuthorized,
        failedEditDept,
        setFailedEditDept,
        errorMessage
    } = useEditDepartment(props);
    const navigate = useNavigate();
    const location = useLocation();

    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        handleChangeName(location.state.name);
        handleChangeCode(location.state.code);
    }, []);

    return (
        <>
            <div>
                <button
                    onClick={() => {
                        navigate('/home/department');
                    }}
                    className="btn btn-outline-secondary btn-add-user"
                >
                    <IoMdArrowRoundBack size={20} /> Back
                </button>
                <div className="edit-depart-flex-container">
                    <div className="edit-depart-container">
                        <h3 style={{ color: '#b70621', textAlign: 'center' }}>
                            Edit Department
                        </h3>
                        <div className="underline"></div>
                        <br />
                        <table className="table table-hover">
                            <tbody>
                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <strong>UserId</strong>*/}
                                {/*    </td>*/}
                                {/*    <td colSpan={2}>{location.state.id}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>{name}</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setShowEditName(true);
                                                setShowButtons(true);
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Name">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Code</strong>
                                    </td>
                                    <td colSpan={2}>{code}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Collapse in={showEditName}>
                            <div>
                                <AppInputName
                                    label={'Department Name'}
                                    handleError={handleErrorName}
                                    name={name}
                                    placeholder={'max 100char'}
                                    handleChange={handleChangeName}
                                    submit={submit}
                                />
                            </div>
                        </Collapse>
                        {/* {showEditName && (
                            <AppInputName
                                label={'Department Name'}
                                handleError={handleErrorName}
                                name={name}
                                placeholder={'max 100char'}
                                handleChange={handleChangeName}
                                submit={submit}
                            />
                        )} */}
                        {/* {showEditCode && (
                            <AppInputName
                                label={'Department Code'}
                                handleError={handleErrorCode}
                                name={code}
                                placeholder={'max 100char'}
                                handleChange={handleChangeCode}
                                submit={submit}
                            />
                        )} */}
                        <br />
                        {showButtons && (
                            <div className="edit-buttons">
                                <div className="edit-user-btn-left">
                                    <AppButtonSubmitPrimary
                                        label={'Save Changes'}
                                        className={
                                            'btn btn-danger edit-user-btn'
                                        }
                                        onClick={handleSave}
                                    />
                                </div>
                                <div className="edit-user-btn-right">
                                    <AppButtonSubmitPrimary
                                        label={'Close'}
                                        className={
                                            'btn btn-outline-danger edit-user-btn'
                                        }
                                        onClick={() => {
                                            setShowButtons(false);
                                            setShowEditName(false);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {save && (
                            <AddUserConfirmView
                                label={'Edit This Department'}
                                handleYes={handleSaveYes}
                                handleClose={() => {
                                    setSave(!save);
                                }}
                            />
                        )}
                        {success && (
                            <AddUserSuccessView
                                label={'Edit Department'}
                                handleYes={() => {
                                    setSuccess(!success);
                                    navigate('/home/department');
                                }}
                            />
                        )}
                        {cancel && (
                            <AddUserCancelView
                                handleYes={handleCancel}
                                handleClose={() => {
                                    setCancel(!cancel);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {unAuthorized && <AppUnauthorized />}
            {failedEditDept && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedEditDept(false)}/>}
        </>
    );
};

export default EditDepartmentView;
