import React from 'react'
import { useEffect } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import  useEditCategory  from './useEditCategory'
import { Tooltip } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import Collapse from 'react-bootstrap/Collapse';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';
import { useState } from 'react';
import './EditCategory.css'
import { AppInputDepartment } from '../../../shared/components/AppInput/AppInputDepartment';

export const EditCategory = () => {
    const {
        handleErrorName,
        save,
        setSave,
        handleChangeName,
        handleSaveYes,
        handleCancel,
        handleSave,
        isLoading,
        categoryName,
        showEditName,
        setShowEditName,
        failedEditCategory,
        setFailedEditCategory,
        errorMessage,
        submit,
        success,
        setSuccess,
        cancel,
        setCancel,
        id,
        setId,
        departmentName,
        departmentList,
        handleChangeDepartmentName,
        handleErrorDepartmentName,
        showEditDepartment,
        setShowEditDepartment,
        department,
        onGetAllDepartement,
        departmentId,
        setDepartmentId,
        handleChange
      } = useEditCategory()

    const navigate = useNavigate();
    const location = useLocation();
    // console.log('INI LOCATION --> ', location);

    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        onGetAllDepartement();
    }, [])

    useEffect(() => {
        handleChangeName(location.state.categoryName);
        setId(location.state.categoryId)
        handleChangeDepartmentName(location.state.departmentName);
    }, [])

    return (
        <>
            <div>
                <button
                    onClick={() => {
                    navigate('/home/category-list');
                }}
                className="btn btn-outline-secondary btn-add-user">
                    <IoMdArrowRoundBack size={20} /> Back
                </button>
                <div className="edit-category-flex-container">
                    <div className="edit-category-container">
                        <h3 style={{ color: '#b70621', textAlign: 'center' }}>
                            Edit Category
                        </h3>
                        <div className="underline"></div>
                        <br />
                        <table className="table table-hover">
                            <tbody>
                                 {/* FIXME Unit Test biar bagian ini pastin gak ada */}
                                <tr>
                                   <td>
                                       <strong>Category Id</strong>
                                   </td>
                                   <td colSpan={2}>{location.state.categoryId}</td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>{categoryName}</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setShowEditName(true);
                                                setShowButtons(true);
                                                setShowEditDepartment(false);
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Name">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Department</strong>
                                    </td>
                                    <td>{departmentName}</td>
                                    <td>
                                    <button
                                            onClick={() => {
                                                setShowEditDepartment(true);
                                                setShowButtons(true);
                                                setShowEditName(false)
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Name">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Collapse in={showEditName}>
                            <div>
                                <AppInputName
                                    label={'Category Name'}
                                    handleError={handleErrorName}
                                    name={categoryName}
                                    placeholder={'max 100char'}
                                    handleChange={handleChangeName}
                                    submit={submit}
                                />
                            </div>
                        </Collapse>
                        <Collapse in={showEditDepartment}>
                            <div>
                            <label className="mb-2">
                    Department
                    <br />
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChange}
                  >
                    <option value={0} selected disabled>
                      Select departement
                    </option>
                    {departmentList.map((d) => {
                      return (
                        <option
                          key={d.picDepartmentId}
                          value={d.picDepartmentId}
                        >
                          {d.picDepartmentName}
                        </option>
                      );
                    })}
                  </select>
                  <br/>
                </div>
                        </Collapse>
                        <br />
                        {showButtons && (
                            <div className="edit-buttons">
                                <div className="edit-user-btn-left">
                                    <AppButtonSubmitPrimary
                                        label={'Save Changes'}
                                        className={
                                            'btn btn-danger edit-user-btn'
                                        }
                                        onClick={handleSave}
                                    />
                                </div>
                                <div className="edit-user-btn-right">
                                    <AppButtonSubmitPrimary
                                        label={'Close'}
                                        className={
                                            'btn btn-outline-danger edit-user-btn'
                                        }
                                        onClick={() => {
                                            setShowButtons(false);
                                            setShowEditName(false);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {save && (
                            <AddUserConfirmView
                                label={'Edit This Department'}
                                handleYes={handleSaveYes}
                                handleClose={() => {
                                    setSave(!save);
                                }}
                            />
                        )}
                        {success && (
                            <AddUserSuccessView
                                label={'Edit Department'}
                                handleYes={() => {
                                    setSuccess(!success);
                                    navigate('/home/category-list');
                                }}
                            />
                        )}
                        {cancel && (
                            <AddUserCancelView
                                handleYes={handleCancel}
                                handleClose={() => {
                                    setCancel(!cancel);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {failedEditCategory && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedEditCategory(false)}/>}
        </>
    )
}
