import { useState, useEffect } from 'react';
import { useDependency } from '../../../shared/hook/UseDependency';
import { useNavigate } from 'react-router-dom';
import './AddUser.css';
import { useUnauthorized } from '../../../shared/components/Popup/AppUnauthorized/useUnauthorized';

const useAddUser = (props) => {
    const { userService, firebaseService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // jaga2 kalau perlu loading
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [role, setRole] = useState('');
    const [errorRole, setErrorRole] = useState('');
    const [department, setDepartment] = useState('');
    const [errorDepartment, setErrorDepartment] = useState('');
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [departmentResponse, setDepartmentResponse] = useState({});
    const { unAuthorized, setUnauthorized } = useUnauthorized();
    const [failedAddUser, setFailedAddUser] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        onGetAllDepartments();
    }, []);

    const onGetAllDepartments = async () => {
        setIsLoading(true);
        try {
            const response = await userService.getAllDepartments();
            if (response.status === 'SUCCESS') {
                setDepartmentResponse(response.message);
            }
        } catch (e) {
            // if (e.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(e);
            // }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onPostCS = async (email, name) => {
        setIsLoading(true);
        try {
            const response = await userService.postUserCS(email, name);
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS POST CS');
                onCreateNewFirebaseCollection(response.userId, name, 'cs');
                setSuccess(!success);
            }
        } catch (e) {
            // if (e.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(e);
            // }
            if (e.message === 'Network Error'){
                setFailedAddUser(true);
                setErrorMessage('Connection problem')
                console.log(e.message);
            } else if (e.response.data.message === 'can not create a new user with same email'){
                setFailedAddUser(true);
                setErrorMessage(e.response.data.message)
            } else {
                setFailedAddUser(true);
                setErrorMessage('New user can\'t be created')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onPostPIC = async (email, name, department) => {
        setIsLoading(true);
        try {
            const response = await userService.postUserPIC(
                email,
                name,
                department
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS POST PIC');
                onCreateNewFirebaseCollection(response.userId, name, 'pic');
                setSuccess(!success);
            }
        } catch (e) {
            // if (e.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(e);
            // }
            if (e.message === 'Network Error'){
                setFailedAddUser(true);
                setErrorMessage('Connection problem')
                console.log(e.message);
            } else if (e.response.data.message === 'can not create a new user with same email'){
                setFailedAddUser(true);
                setErrorMessage(e.response.data.message)
            } else {
                setFailedAddUser(true);
                setErrorMessage('New user can\'t be created')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onPostSA = async (email, name) => {
        setIsLoading(true);
        try {
            const response = await userService.postUserSA(email, name);
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS POST SA');
                onCreateNewFirebaseCollection(response.userId, name, 'sa');
                setSuccess(!success);
            }
        } catch (e) {
            if (e.message === 'Network Error'){
                setFailedAddUser(true);
                setErrorMessage('Connection problem')
                console.log(e.message);
            } else if (e.response.data.message === 'can not create a new user with same email'){
                setFailedAddUser(true);
                setErrorMessage(e.response.data.message)
            } else {
                setFailedAddUser(true);
                setErrorMessage('New user can\'t be created')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onCreateNewFirebaseCollection = async (userId, name, role) => {
        setIsLoading(true);
        try {
            await firebaseService.createNewCollection(userId, name, role);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = (value) => {
        setName(value);
    };

    const handleErrorName = (value) => {
        setErrorName(value);
    };

    const handleChangeEmail = (value) => {
        setEmail(value);
    };

    const handleErrorEmail = (value) => {
        setErrorEmail(value);
    };

    const handleChangeRole = (value) => {
        setRole(value);
    };

    const handleErrorRole = (value) => {
        setErrorRole(value);
    };

    const handleChangeDepartment = (value) => {
        setDepartment(value);
    };

    const handleErrorDepartment = (value) => {
        setErrorDepartment(value);
    };

    const handleSaveYes = () => {
        switch (role) {
            case 'CUSTOMER SERVICE':
                onPostCS(email, name);
                break;
            case 'PIC':
                onPostPIC(email, name, department);
                break;
            case 'SUPER ADMIN':
                onPostSA(email, name);
                break;
        }
        setSave(!save);
    };

    const handleCancel = () => {
        setName('');
        setEmail('');
        setRole('');
        setDepartment('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/user-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            email !== '' &&
            errorEmail === '' &&
            name !== '' &&
            errorName === '' &&
            role !== '' &&
            errorRole === ''
        ) {
            if (role === 'PIC') {
                if (department !== '' && errorDepartment === '') {
                    setSave(!save);
                }
            } else if (role === 'CUSTOMER SERVICE' || role === 'SUPER ADMIN') {
                setSave(!save);
            }
        }
    };
    return {
        name,
        handleErrorName,
        handleChangeName,
        email,
        handleErrorEmail,
        handleChangeEmail,
        role,
        handleErrorRole,
        handleChangeRole,
        department,
        handleChangeDepartment,
        handleErrorDepartment,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        departmentResponse,
        unAuthorized,
        failedAddUser,
        setFailedAddUser,
        errorMessage
    };
};

export default useAddUser;
