import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hook/UseLocalStorage';
import React from 'react';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [latestLocation, setLatestLocation] = useState('');
    const [token, setToken] = useLocalStorage('token', null);
    const [user, setUser] = useLocalStorage('user', null);
    const [refreshToken, setRefreshToken] = useLocalStorage(
        'refreshToken',
        null
    );

    const onLogin = (useCred) => {
        const userInfo = {
            userId: useCred.userId,
            userEmail: useCred.userEmail,
            userName: useCred.userName,
            roleName: useCred.roleName,
        };
        setUser(userInfo);
        setToken(useCred.token);
        setRefreshToken(useCred.refreshToken);

        // if (latestLocation !== '') {
        //     navigate(latestLocation, { replace: true });
        //     return
        // }

        navigate('/home/dashboard', { replace: true });
    };
    const onLogout = (lastLoc = '') => {
        setUser(null);
        setToken(null);
        setRefreshToken(null);
        if (lastLoc !== '') {
            setLatestLocation(lastLoc);
        }
        navigate('/', { replace: true });
    };

    // const onRefreshLogin = async (useCred) => {
    //     setToken(useCred.token);
    //     setRefreshToken(useCred.refreshToken);
    // };

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                refreshToken,
                onLogin,
                onLogout,
                // onRefreshLogin,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
