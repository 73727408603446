import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import {useDependency} from './../../../shared/hook/UseDependency'

export const useTicketHistory = () => {
    const {state : {ticketId}} = useLocation();
    const [ticketLog, setTicketLog] = useState([]);
    const {ticketService} = useDependency();

    useEffect(() => {
        onGetTicketHistory();
    }, [])
    
    const onGetTicketHistory = async () => {
        const response = await ticketService.getTicketHistory(ticketId);
        if (response.status === 'SUCCESS') {
            setTicketLog(response.message)
        } else {
            setTicketLog([])
        }
    }

    return {
        ticketLog,
    }
}
