import './GetScore.css';
import { GiLaurelsTrophy  } from 'react-icons/gi';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../../../shared/components/AppButton/AppButtonSubmitPrimary';

const GetScore = ({ label, handleYes}) => {
    return (
        <div className="warn-add-user-container-success">
            <div className="success-ellips">
                <GiLaurelsTrophy size={125} color="#FFFFFF"/>
            </div>
            <div className="warn-add-user-content-success">
                <br />
                <h5 style={{ color: '#7C8691', padding: '5px', fontSize: '18px' }}>
                    <strong>YOUR SCORE FOR THIS TICKET</strong>
                </h5>
                <h6 style={{color: '#7C8691', fontSize: '25px' }}>{label}</h6>
                <br />
                <div className="buttons">
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'OK'}
                            className={'btn success-user-btn'}
                            onClick={handleYes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetScore;