import { useEffect } from 'react';
import { useState } from 'react';
import { useDependency } from '../../shared/hook/UseDependency'


const useCategoryList = () => {
   
    const { categoryService } = useDependency();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryListPage, setCategoryListPage] = useState([])
    const [page, setPage] = useState(1);
    const [searchCategory, setSearchCategory] = useState('');

    useEffect(() => {
        onGetAllCategoryPagination();
    }, [page])

    useEffect(() => {
        onSearchCategoryPagination();
        console.log('search', searchCategory);
    }, [searchCategory])

    useEffect(() => {
        console.log('clp', categoryListPage);
    }, [categoryListPage])    

    const onGetAllCategoryPagination = async () => {
        setIsLoading(true)
        try {
            const response = await categoryService.getAllCategory(page)
            // console.log('response category', response);
            if (response.status === 'SUCCESS') {    
                setCategoryListPage(response.message);
            }
            // console.log('ini response',response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const onSearchCategoryPagination = async () => {
        if(searchCategory.length < 2){
            onGetAllCategoryPagination();
            return
        }

        const response = await categoryService.searchNameCategory(page, searchCategory);
        if (response.status === 'SUCCESS') {
            if (response.message !== null){
                setCategoryListPage(response.message)
            } else {
                setCategoryListPage([])
            }
        }
    }

    const handleClearFilter = () => {
        setPage(1)
    }

    const handleChangePageAll = (task) => {
        if (task === 'next') {
            setPage(page + 1)
            return
        }
        setPage(page - 1)
    }

    return {
        isLoading,
        categoryListPage,
        page,
        searchCategory,
        setSearchCategory,
        handleClearFilter,
        handleChangePageAll
    }

}

export default useCategoryList