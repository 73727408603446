import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputDepartment } from '../../../shared/components/AppInput/AppInputDepartment';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import './AddCategory.css';
import { useAddCategory } from './useAddCategory';

export const AddCategory = () => {
    const {
        isLoading,
        categoryName,
        errorCategoryName,
        save,
        setSave,
        success,
        setSuccess,
        cancel,
        setCancel,
        failedAddCategory,
        failedAddDepartment,
        setFailedAddCategory,
        errorMessage,
        onPostCategory,
        handleChangeName,
        handleErrorName,
        handleSaveYes,
        handleCancel,
        handleSave,
        submit,
        setSubmit,
        handleChangeDepartmentName,
        handleErrorDepartmentName,
        setDepartmentId,
        departmentId,
        setErrorDepartmentName,
        errorDepartmentName,
        departmentList
    } = useAddCategory()
    const navigate = useNavigate();

    return (
        <>
            <div className='add-category-flex-container'>
                <div className='add-category-container'>
                    <h3 style={{ color: '#b70621', textAlign: 'center' }}>Add Category</h3>
                    <div className="underline"></div>
                    <AppInputName
                        label={'Category Name'}
                        handleError={handleErrorName}
                        name={categoryName}
                        placeholder={'max 100char'}
                        handleChange={handleChangeName}
                        submit={submit}
                    />
                    <div>
                  <label className="mb-2">
                    Department
                    <br />
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChangeDepartmentName}
                    style={{
                        textAlign: "center",
                        width: 300,
                    }}
                  >
                    <option value={0} selected disabled>
                      Select department
                    </option>
                    {departmentList.map((d) => {
                      return (
                        <option
                          key={d.picDepartmentId}
                          value={d.picDepartmentId}
                        >
                          {d.picDepartmentName}
                        </option>
                      );
                    })}
                  </select>
                  <br/>
                </div>
                    <small className="error">
                        <strong>
                            <i>{errorDepartmentName}</i>
                        </strong>
                    </small>
                    <div className='buttons'>
                        <div className='add-user-btn-left'>
                        <AppButtonSubmitPrimary
                            label={'Save'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleSave}
                        />
                        </div>
                        <div className="add-user-btn-right">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-outline-danger'}
                            onClick={() => {
                                setCancel(!cancel);
                            }}
                        />
                    </div>
                    </div>
                </div>
                {save && (
                    <AddUserConfirmView
                        label={'Add New Category'}
                        handleYes={handleSaveYes}
                        handleClose={() => {
                            setSave(!save);
                        }}
                    />
                )}
                {success && (
                    <AddUserSuccessView
                        label={'Add New Category'}
                        handleYes={() => {
                            setSuccess(!success);
                            navigate('/home/category-list')
                        }}
                    />
                )}
                {cancel && (
                    <AddUserCancelView
                        handleYes={handleCancel}
                        handleClose={() => {
                            setCancel(!cancel);
                        }}
                    />
                )}
            </div>
            {failedAddCategory && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedAddCategory(false)}/>}
        </>
    )
}
