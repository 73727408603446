import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { USER } from '../shared/constants';

const CsPage = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    return (user.roleName !== USER.ROLE.PIC) ? <Outlet /> : <Navigate to="/home/dashboard" replace />;
};

export default CsPage;
