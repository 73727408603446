import './UnregisteredEmail.css';
import { AiFillWarning } from 'react-icons/ai';
import React from 'react';

const UnregisteredEmailView = (props) => {
    return (
        <div className="unregistered-email-container">
            <div className="unregistered-email-content">
                <AiFillWarning color="#FFDD15" size={150} />
                <h5 style={{ color: '#B70621' }}>
                    The email hasn't registered yet.
                </h5>
                <h5 style={{ color: '#6A6A6A' }}>
                    Please input the registered email
                </h5>
                <div>
                    <button
                        onClick={props.handleClose}
                        className="btn btn-danger"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UnregisteredEmailView;
