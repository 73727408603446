import React, { useEffect } from 'react';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputEmail } from '../../../shared/components/AppInput/AppInputEmail';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import { AppInputRole } from '../../../shared/components/AppInput/AppInputRole';
import useAddUser from './UseAddUser';
import { AppInputDepartment } from '../../../shared/components/AppInput/AppInputDepartment';
import { useNavigate } from 'react-router-dom';
import './AddUser.css';
import AppUnauthorized from '../../../shared/components/Popup/AppUnauthorized/AppUnauthorized';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView'

const AddUserView = (props) => {
    const {
        name,
        handleErrorName,
        handleChangeName,
        email,
        handleErrorEmail,
        handleChangeEmail,
        role,
        handleErrorRole,
        handleChangeRole,
        department,
        handleChangeDepartment,
        handleErrorDepartment,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        departmentResponse,
        unAuthorized,
        failedAddUser,
        setFailedAddUser,
        errorMessage,
    } = useAddUser(props);
    const navigate = useNavigate();

    return (
    <>
        <div className="add-user-flex-container-ori">
            <div className="add-user-container-ori">
                <h2>Add User</h2>
                <div className="underline"></div>
                <AppInputName
                    label={'Name'}
                    handleError={handleErrorName}
                    name={name}
                    placeholder={'max 100char'}
                    handleChange={handleChangeName}
                    submit={submit}
                />
                <br />
                <AppInputEmail
                    label={'Email'}
                    handleError={handleErrorEmail}
                    email={email}
                    placeholder={'should contain @ and .'}
                    handleChange={handleChangeEmail}
                    submit={submit}
                />
                <br />
                <AppInputRole
                    role={role}
                    handleChange={handleChangeRole}
                    handleError={handleErrorRole}
                    submit={submit}
                />
                <br />
                <div>
                    {role === 'PIC' && (
                        <AppInputDepartment
                            value={department}
                            handleChange={handleChangeDepartment}
                            handleError={handleErrorDepartment}
                            submit={submit}
                            departments={departmentResponse}
                        />
                    )}
                </div>
                <div className="buttons">
                    <div className="add-user-btn-left">
                        <AppButtonSubmitPrimary
                            label={'Add'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleSave}
                        />
                    </div>
                    <div className="add-user-btn-right">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-outline-danger'}
                            onClick={() => {
                                setCancel(!cancel);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                {save && (
                    <AddUserConfirmView
                        label={'Add New User'}
                        handleYes={handleSaveYes}
                        handleClose={() => {
                            setSave(!save);
                        }}
                    />
                )}
                {success && (
                    <AddUserSuccessView
                        label={'Add User'}
                        handleYes={() => {
                            setSuccess(!success);
                            navigate('/home/user-list');
                        }}
                    />
                )}
                {cancel && (
                    <AddUserCancelView
                        handleYes={handleCancel}
                        handleClose={() => {
                            setCancel(!cancel);
                        }}
                    />
                )}
            </div>
        </div>
        {unAuthorized && <AppUnauthorized/>}
        {failedAddUser && <AddFailedView failedMsg={errorMessage} handleYes={() => {setFailedAddUser(false)}}/>}
    </>
        
    );
};

export default AddUserView;
