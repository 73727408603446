import { useTicketHistory } from "./UseTicketHistory"
import { IoGitCommitOutline } from "react-icons/io5";
import '../ticketDetail/TicketDetail.css';

export const TicketHistory = () => {

    const {ticketLog} = useTicketHistory();

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString()
    }


    return (
        <div className="card border-light shadow-sm mt-5 p-3">
            <div className="p-2 bg-title text-white fs-5 fw-semibold w-100 position-absolute top-0 start-50 translate-middle text-center">
                Ticket History
            </div>
            {ticketLog.length === 0 ? 
            <div className="fw-bold text-center pt-3">
                {`The history of this ticket is not registered`}
            </div> : 
            <>
                {ticketLog.map((log, index) => {
                return (
                    <div key={index} className='d-flex gap-2 mt-2 p-1 border-bottom border-danger border-opacity-25'>
                        <div>
                            <IoGitCommitOutline color="#B70621" size={20}/>
                        </div>
                        <div>
                            <div className="fw-bold">
                                {formatDate(log.date)}
                            </div>
                            <div style={{color : '#6A6A6A'}} className="d-flex">
                                <div className="fw-bold">
                                    {`[${log.description}]`}
                                </div>
                                &nbsp;
                                {log.detail}
                            </div>
                        </div>
                    </div>
                )
                })}
            </>
            }
        </div>
    )
}
