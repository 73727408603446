export const LoginService = ({ doPost }) => {
    const postEmail = async (email) => {
        try {
            return await doPost({
                url: '/login',
                data: { userEmail: email },
            });
        } catch (e) {
            throw e;
        }
    };

    return { postEmail };
};
