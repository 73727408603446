export const OTPService = ({ doPost }) => {
    const postOTP = async (email, otp) => {
        try {
            return await doPost({
                url: '/login/otp',
                data: {
                    userEmail: email,
                    otp: otp,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    return { postOTP };
};
