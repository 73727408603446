import './AddUserSuccess.css';
import { FiCheck } from 'react-icons/fi';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../../../shared/components/AppButton/AppButtonSubmitPrimary';

const AddUserSuccessView = ({ label, handleYes, handleClose }) => {
    return (
        <div className="warn-add-user-container-success">
            <div className="success-ellips">
                <FiCheck
                    className="success-icon"
                    color="#FFFFFF"
                    size={125}
                />
            </div>
            <div className="warn-add-user-content-success">
                <br />
                <h5 style={{ color: '#7C8691', padding: '5px', fontSize: '25px' }}>
                    <strong>Success</strong>
                </h5>
                <h6 style={{color: '#7C8691'}}>{label} Success!</h6>
                <br />
                <div className="buttons">
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'OK'}
                            className={'btn success-user-btn'}
                            onClick={handleYes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserSuccessView;
