import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { MdNotifications } from 'react-icons/md';
import {IoPersonCircle} from 'react-icons/io5'
import './AppHeader.css';
import { useHeader } from './useHeader';
import { useAuth } from '../../hook/UseAuth';
import { Link } from 'react-router-dom';
import { AiOutlineMenu } from "react-icons/ai";
import { BiLeftArrowCircle } from "react-icons/bi"

export const AppHeader = ({handleClick}) => {
    const [notif, setNotif] = useState(false);
    const { listNotif, onClickNotif, user } = useHeader();
    const { onLogout } = useAuth();

    return (
        <header className="w-100 mb-3 appHeader" style={{zIndex: 1}}>
            <div className="container-fluid shadow-sm rounded">
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className='container-header-left' >
                        <div>
                            <AiOutlineMenu onClick={handleClick} size={'1.5em'} cursor={"pointer"}/>
                        </div>
                        <div>
                            <h5 className="mt-2" style={{marginLeft:'1em'}}>Hello, {user.roleName}</h5>
                            <h4 style={{marginLeft:'1em'}}>{user.userName}</h4> 
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={() => setNotif(!notif)}
                            className="btn btn-light position-relative me-4 rounded-circle"
                            style={{ backgroundColor: '#F3E8E5' }}
                        >
                            <MdNotifications size={20} color="#B70621" />
                            {listNotif.length > 0 && (
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {listNotif.length}
                                </span>
                            )}
                            {notif && (
                                <div className="modalNotif">
                                    <div className="card border-light shadow-sm">
                                        <ul className="list-group list-group-flush">
                                            {listNotif ? (
                                                listNotif.map(
                                                    (d, index) => {
                                                        return (
                                                            <Link key={index} onClick={() => onClickNotif({ticketId : d.ticketId, type : d.type})} className='detail' to={'/home/ticket-detail'} state={{ticketId : d.ticketId}}>
                                                                {d.type === 'created' ? 
                                                                    <li
                                                                        className="list-group-item list-group-item-action"
                                                                    >
                                                                        {`New ticket with number ${d.ticketId} ${d.type} without department or pic`}
                                                                    </li> :
                                                                    <li
                                                                        className="list-group-item list-group-item-action"
                                                                    >
                                                                        {`New ${d.type} on ticket number ${d.ticketId}`}
                                                                    </li>
                                                                }
                                                            </Link>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </button>

                        <Dropdown as={ButtonGroup} align="end">
                            <Dropdown.Toggle
                                variant=""
                                id="dropdown-split-basic"
                            >
                                <IoPersonCircle size={32} color='#333132' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onLogout('')}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </header>
    );
};
