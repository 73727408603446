import { onSnapshot, doc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';

export const commentServiceFireStore = (db) => {
    const getAllCommentByTicketId = async (ticketId, setCommentList) => {
        // setNotif -> hooks
        try {
            const unsub = onSnapshot(doc(db, 'comments', ticketId), (doc) => {
                const data = doc.data();
                setCommentList(data.commentList);
            });
        } catch (e) {
            throw e;
        }
    };

    const createComment = async (ticketId, newComment) => {
        try {
            const docRef = doc(db, 'comments', ticketId);
            await updateDoc(docRef, {
                commentList: arrayUnion(newComment),
            });
        } catch (e) { // buat dokument baru di firebase kalau nggak ada comment
            if (e.message.slice(0, 11) === 'No document') {
                try {
                    await setDoc(doc(db, 'comments', ticketId), {
                        commentList: [],
                    });
                    const docRef = doc(db, 'comments', ticketId);
                    await updateDoc(docRef, {
                        commentList: arrayUnion(newComment),
                    });
                } catch (e) {
                    throw e;
                }
            }
            throw e;
        }
    };

    return {
        getAllCommentByTicketId,
        createComment,
    };
};
