import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUnauthorized } from "../../../shared/components/Popup/AppUnauthorized/useUnauthorized";
import { USER } from "../../../shared/constants";
import { useAuth } from "../../../shared/hook/UseAuth";
import { useDependency } from "../../../shared/hook/UseDependency";

export const useTicketList = (
  showAllTicket = false,
  showAssignedToMe = false
) => {
  const [ticketList, setTicketList] = useState([]);
  const [ticketListDept, setTicketListDept] = useState([]);
  const [picDepartment, setPicDepartment] = useState({});
  const [departements, setDepartements] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [page, setPage] = useState(1);
  const [pageTicketDept, setPageTicketDept] = useState(1);
  const [sort, setSort] = useState("CreatedAt");
  const [order, setOrder] = useState("DESC");
  const [clickOrder, setClickOrder] = useState(false);
  const [searchTicket, setSearchTicket] = useState("");
  const [filterDept, setFilterDept] = useState(0);
  const [showDept, setShowDept] = useState(false);
  const [filterStatus, setFilterStatus] = useState(0);
  const [showStatus, setShowStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const { ticketService, picService, departementService } = useDependency();
  const [success, setSuccess] = useState(false);
  const { unAuthorized, setUnauthorized } = useUnauthorized();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState({ ticketDate: "" });

  const onGetAllDepartement = async () => {
    setLoading(true);
    try {
      const response = await departementService.getAllDepartement();
      if (response.status === "SUCCESS") {
        setDepartements(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    // setSearchTicket(searchTicket);
    onSearchTicket();
  };

  const onSearchTicket = async () => {
    // setLoading(true);
    // console.log("searchTicket.length === 0", searchTicket);
    let roleId = 1;
    if (user.roleName === USER.ROLE.PIC || showAssignedToMe) {
      roleId = 3;
    } else if (user.roleName === USER.ROLE.CS) {
      roleId = 2;
    }

    setPage(1);

    onGetAllTicket();
  };

  const onGetAllTicket = async () => {
    // setPage(1);
    console.log("page", page);
    setLoading(true);

    // console.log("user role name", user.roleName);
    // console.log("user role", user);
    console.log();
    try {
      if (user.roleName === USER.ROLE.SA || showAllTicket) {
        console.log("SA, All tickets");
        const response = await ticketService.getAllTicketForAdmin(
          page,
          sort,
          filterDept,
          filterStatus,
          order,
          searchTicket
        );
        // console.log("response", response);
        if (response.status === "SUCCESS") {
          if (response.message !== null) {
            setTicketList(response.message);
            setLoading(false);
          } else {
            setTicketList([]);
            setLoading(false);
          }
        } else {
          setTicketList([]);
          setLoading(false);
        }
      } else {
        let roleId;
        if (user.roleName === USER.ROLE.PIC || showAssignedToMe) {
          console.log("PIC", user.roleName);
          roleId = 3;
        } else if (user.roleName === USER.ROLE.CS) {
          console.log("CS", user.roleName);
          roleId = 2;
        }

        console.log("filter department", filterDept);
        console.log("filter status", filterStatus);

        if (filterDept !== "") {
          const response = await ticketService.getAllTicketForAdmin(
            page,
            sort,
            filterDept,
            filterStatus,
            order,
            searchTicket,
            user.userId,
            roleId
          );
          if (response.status === "SUCCESS") {
            if (response.message !== null) {
              setTicketList(response.message);
              setLoading(false);
            } else {
              setTicketList([]);
              setLoading(false);
            }
          } else {
            setTicketList([]);
            setLoading(false);
          }
        } else if (filterStatus !== "") {
          const response = await ticketService.getAllTicketForAdmin(
            page,
            sort,
            filterDept,
            filterStatus,
            order,
            searchTicket,
            user.userId,
            roleId
          );
          if (response.status === "SUCCESS") {
            if (response.message !== null) {
              setTicketList(response.message);
              setLoading(false);
            } else {
              setTicketList([]);
              setLoading(false);
            }
          } else {
            setTicketList([]);
            setLoading(false);
          }
        } else {
          const response = await ticketService.getAllTicketForAdmin(
            page,
            sort,
            filterDept,
            filterStatus,
            order,
            searchTicket,
            user.userId,
            roleId
          );
          if (response.status === "SUCCESS") {
            if (response.message !== null) {
              setTicketList(response.message);
              setLoading(false);
            } else {
              setTicketList([]);
              setLoading(false);
            }
          } else {
            setTicketList([]);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true)
      // } else {
      //     console.log(error);
      // }
      console.log("eror");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onGetDeptByUser = async () => {
    if (
      (user.roleName === USER.ROLE.CS && !showAssignedToMe) ||
      user.roleName === USER.ROLE.SA
    ) {
      return;
    }
    setLoading(true);
    try {
      const response = await picService.getDeptByUserId(user.userId);
      if (response.status === "SUCCESS") {
        setPicDepartment(response.message);
        setLoading(false);
        return response.message;
      } else {
        setPicDepartment([]);
        setLoading(false);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //     setUnauthorized(true)
      // } else {
      //     console.log(error);
      // }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onGetAllTicketByDept = async () => {
    if (
      (user.roleName === USER.ROLE.CS && !showAssignedToMe) ||
      user.roleName === USER.ROLE.SA
    ) {
      return;
    }
    setLoading(true);
    try {
      let dept = await onGetDeptByUser();
      setPage(1);
      console.log("dept", dept);
      console.log("dept", dept);

      // const response = await ticketService.getAllTicketByDepartmentId(
      //   pageTicketDept,
      //   sort,
      //   dept.picDepartment
      // );

      const response = await ticketService.getAllTicketForAdmin(
        pageTicketDept,
        sort,
        dept.picDepartment,
        1,
        order,
        searchTicket,
        user.userId,
        dept.picRole
      );

      console.log("response", response);

      if (response.status === "SUCCESS") {
        setTicketListDept(response.message);
        setLoading(false);
      } else {
        setTicketListDept([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePageAllTicket = (task) => {
    if (task === "next") {
      setPage(page + 1);
      return;
    }
    setPage(page - 1);
  };

  const handleChangePageTicketDept = (task) => {
    if (task === "next") {
      setPageTicketDept(pageTicketDept + 1);
      return;
    }
    setPageTicketDept(pageTicketDept - 1);
  };

  const handleFilterDept = (deptId) => {
    // setFilterStatus(0);
    setFilterDept(deptId);
    setShowDept(false);
    setShowStatus(false);
    setPage(1);
  };

  const handleFilterStatus = (statusId) => {
    // setFilterDept("");
    setFilterStatus(statusId);
    setShowDept(false);
    setShowStatus(false);
    setPage(1);
  };

  const handleClearFilter = () => {
    setShowDept(false);
    setShowStatus(false);
    setFilterStatus(0);
    setFilterDept(0);
    setPage(1);
  };

  const handleOrder = () => {
    setClickOrder(!clickOrder);
    setOrder(!clickOrder ? "ASC" : "DESC");
  };

  const triggerDelete = (id = "") => {
    if (id !== "") {
      setShowDelete(true);
      setDeletedId(id);
    } else {
      setShowDelete(false);
      setDeletedId("");
    }
  };

  const onDeleteTicket = async () => {
    setLoading(true);
    try {
      const response = await ticketService.deleteTicket(deletedId);
      if (response.status === "SUCCESS") {
        setShowDelete(false);
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessDelete = () => {
    setSuccess(false);
    navigate(0);
  };

  // useEffect(() => {
  //   onSearchTicket();
  // }, [searchTicket, showAssignedToMe, showAllTicket]);

  useEffect(() => {
    onGetAllTicketByDept();
  }, [pageTicketDept, showAllTicket, showAssignedToMe]);

  useEffect(() => {
    onGetAllTicket();
  }, [
    page,
    filterDept,
    filterStatus,
    sort,
    clickOrder,
    order,
    showAllTicket,
    showAssignedToMe,
    pageTicketDept,
  ]);

  useEffect(() => {
    onGetAllDepartement();
  }, []);

  return {
    ticketList,
    user,
    ticketListDept,
    page,
    pageTicketDept,
    handleChangePageAllTicket,
    handleChangePageTicketDept,
    showDept,
    setShowDept,
    departements,
    handleFilterDept,
    filterDept,
    handleClearFilter,
    filterStatus,
    showStatus,
    setShowStatus,
    handleFilterStatus,
    showDelete,
    triggerDelete,
    onDeleteTicket,
    loading,
    success,
    setSuccess,
    handleSuccessDelete,
    unAuthorized,
    setSort,
    searchTicket,
    setSearchTicket,
    handleOrder,
    order,
    ticket,
    handleSearch,
  };
};
