import { useState } from 'react';
import './Login.css';
import { useDependency } from '../../../shared/hook/UseDependency';

const useLoginPage = (props) => {
    const { loginService } = useDependency();
    const [isLoading, setIsLoading] = useState(false); // jaga2 kalau perlu loading
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [showOTPVerify, setShowOTPVerify] = useState(false);
    const [showUnregisteredEmail, setShowUnregisteredEmail] = useState(false);
    const [submit, setSubmit] = useState(0);

    const onPostEmail = async (email, resend) => {
        setIsLoading(true);
        try {
            const response = await loginService.postEmail(email);
            if (response.status === 'SUCCESS') {
                if (!resend) {
                    setShowOTPVerify(!showOTPVerify);
                }
            }
        } catch (e) {
            setShowUnregisteredEmail(!showUnregisteredEmail);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeEmail = (value) => {
        setEmail(value);
    };

    const handleErrorEmail = (value) => {
        setErrorEmail(value);
    };

    const handleCloseOTP = () => {
        setShowOTPVerify(!showOTPVerify);
    };

    const handleCloseUnregisteredEmail = () => {
        setShowUnregisteredEmail(!showUnregisteredEmail);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(submit + 1);
        if (errorEmail === '' && email !== '') {
            onPostEmail(email, false);
        }
    };

    return {
        email,
        showOTPVerify,
        showUnregisteredEmail,
        isLoading,
        submit,
        handleSubmit,
        handleChangeEmail,
        handleErrorEmail,
        handleCloseOTP,
        handleCloseUnregisteredEmail,
        onPostEmail,
    };
};

export default useLoginPage;
