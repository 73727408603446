import React from 'react'
import { useEffect } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import { useEditProduct } from './useEditProduct'
import { Tooltip } from '@mui/material';
import { BiEdit } from 'react-icons/bi';
import Collapse from 'react-bootstrap/Collapse';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';
import { useState } from 'react';
import './EditProduct.css'

export const EditProduct = (props) => {
    const {
        handleErrorName,
        save,
        setSave,
        handleChangeName,
        handleSaveYes,
        handleCancel,
        handleSave,
        isLoading,
        productName,
        showEditName,
        setShowEditName,
        failedEditProduct,
        setFailedEditProduct,
        errorMessage,
        submit,
        success,
        setSuccess,
        cancel,
        setCancel,
        id, 
        setId,
    } = useEditProduct(props)

    const navigate = useNavigate();
    const location = useLocation();

    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        handleChangeName(location.state.productName);
        setId(location.state.productId)
        console.log('id', location.state.productId);
    }, [])

    return (
        <>
            <div>
                <button
                    onClick={() => {
                    navigate('/home/product-list');
                }}
                className="btn btn-outline-secondary btn-add-user">
                    <IoMdArrowRoundBack size={20} /> Back
                </button>
                <div className="edit-product-flex-container">
                    <div className="edit-product-container">
                        <h3 style={{ color: '#b70621', textAlign: 'center' }}>
                            Edit Product
                        </h3>
                        <div className="underline"></div>
                        <br />
                        <table className="table table-hover">
                            <tbody>
                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <strong>Product Id</strong>*/}
                                {/*    </td>*/}
                                {/*    <td colSpan={2}>{location.state.productId}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>{productName}</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setShowEditName(true);
                                                setShowButtons(true);
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Name">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Collapse in={showEditName}>
                            <div>
                                <AppInputName
                                    label={'Department Name'}
                                    handleError={handleErrorName}
                                    name={productName}
                                    placeholder={'max 100char'}
                                    handleChange={handleChangeName}
                                    submit={submit}
                                />
                            </div>
                        </Collapse>
                        <br />
                        {showButtons && (
                            <div className="edit-buttons">
                                <div className="edit-user-btn-left">
                                    <AppButtonSubmitPrimary
                                        label={'Save Changes'}
                                        className={
                                            'btn btn-danger edit-user-btn'
                                        }
                                        onClick={handleSave}
                                    />
                                </div>
                                <div className="edit-user-btn-right">
                                    <AppButtonSubmitPrimary
                                        label={'Close'}
                                        className={
                                            'btn btn-outline-danger edit-user-btn'
                                        }
                                        onClick={() => {
                                            setShowButtons(false);
                                            setShowEditName(false);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {save && (
                            <AddUserConfirmView
                                label={'Edit This Department'}
                                handleYes={handleSaveYes}
                                handleClose={() => {
                                    setSave(!save);
                                }}
                            />
                        )}
                        {success && (
                            <AddUserSuccessView
                                label={'Edit Department'}
                                handleYes={() => {
                                    setSuccess(!success);
                                    navigate('/home/product-list');
                                }}
                            />
                        )}
                        {cancel && (
                            <AddUserCancelView
                                handleYes={handleCancel}
                                handleClose={() => {
                                    setCancel(!cancel);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {failedEditProduct && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedEditProduct(false)}/>}
        </>
    )
}
