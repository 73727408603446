import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import "./TicketDetail.css";
import { useTicketDetail } from "./useTicketDetail";
import { TicketComment } from "../ticketComment/TicketComment";
import { USER } from "../../../shared/constants";
import { Link } from "react-router-dom";
import { AppLoading } from "../../../shared/components/AppLoading/AppLoading";
import AddUserConfirmView from "../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView";
import AddUserSuccessView from "../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView";
import AppUnauthorized from "../../../shared/components/Popup/AppUnauthorized/AppUnauthorized";
import { AppButtonSubmitPrimary } from "../../../shared/components/AppButton/AppButtonSubmitPrimary";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import AddUserCancelView from "../../../shared/components/Popup/AddUserCancel/AddUserCancelView";
import { TicketHistory } from "../ticketHistory/TicketHistory";
import { color, style } from "@mui/system";
import AddFailedView from "../../../shared/components/Popup/AddFailed/AddFailedView";
import GetScore from "../../../shared/components/Popup/GetScore/GetScore";

export const TicketDetail = () => {
  const {
    user,
    ticket,
    handleBack,
    formatDate,
    updatePic,
    setUpdatePic,
    newPic,
    setNewPic,
    listPic,
    onUpdatePic,
    onUpdateStatus,
    onDownloadAttachment,
    file,
    loading,
    confirmStatus,
    setConfirmStatus,
    confirmPic,
    setConfirmPic,
    successStatus,
    successPic,
    updateDept,
    setUpdateDept,
    setNewDept,
    deptList,
    confirmDept,
    successDept,
    onUpdateDept,
    setConfirmDept,
    unAuthorized,
    submitted,
    setSubmitted,
    solution,
    setSolution,
    onPostSolution,
    onResolve,
    confirmSolution,
    setConfirmSolution,
    successSolution,
    setSuccessSolution,
    confirmResolve,
    setConfirmResolve,
    successResolve,
    setSuccessResolve,
    errorSolution,
    submitCount,
    setSubmitCount,
    handleSubmitSolution,
    editSubmisson,
    setEditSubmisson,
    cancelEdit,
    setCancelEdit,
    editTitle,
    setEditTitle,
    editDesc,
    setEditDesc,
    editPrior,
    setEditPrior,
    title,
    setTitle,
    description,
    setDescriprion,
    priority,
    setPriority,
    confirmEdit,
    setConfirmEdit,
    successEdit,
    onEditTicket,
    handleChangePic,
    disabledSelectPic,
    failedUpdate,
    setFailedUpdate,
    errorMessage,
    picId,
    editDeptPic,
    setEditDeptPic,
    confirmDeptPic,
    setConfirmDeptPic,
    onSetDepartmentPic,
    successDeptPic,
    setSuccessDeptPic,
    validateFileSize,
    confirmation,
    setConfirmation,
    setFile,
    score,
    product,
    category,
  } = useTicketDetail();

  //const canSubmit = title.length <= 500 && title.length !== 0 && description.length <= 1000 && description.length !== 0 && Number(departement) !== 0 && priority !== -1 && file.length <= 10 && validateFileSize()

  const minutesToDhms = (minutes) => {
    minutes = Number(minutes);
    var d = Math.floor(minutes / 1440);
    var h = Math.floor((minutes % 1440) / 60);
    var m = Math.floor(minutes % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const onGoingTicket = () => {
    const subs = new Date() - new Date(ticket.ticketDate);
    const toMinute = new Date(subs).getTime() / (1000 * 60);
    return minutesToDhms(toMinute);
  };

  const [showSubmit, setShowSubmit] = useState(false);

  return (
    <>
      <button className="btn btn-light btn-sm text-black" onClick={handleBack}>
        <MdOutlineArrowBackIosNew /> Back
      </button>
      <div className="card border-light shadow-sm p-3 mt-4">
        <div className="p-2 bg-title text-white shadow-sm fs-5 fw-semibold w-100 position-absolute top-0 start-50 translate-middle text-center">
          Detail Ticket
        </div>
        <div className="mt-3 ps-1 pe-1">
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">Ticket Number </div>
              <div className="content-detail">: {ticket.ticketId}</div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail">Created At</div>
              <div className="content-detail-prop">:&nbsp;</div>
              <div className="content-detail">
                {formatDate(ticket.ticketDate)}
              </div>
            </div>
          </div>
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">Product </div>
              <div className="content-detail">: {product}</div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail">Category</div>
              <div className="content-detail-prop">:&nbsp;</div>
              <div className="content-detail">{category}</div>
            </div>
          </div>
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">Department</div>
              <div className="content-detail">
                : {ticket.PicDepartment.picDepartmentName}{" "}
                {(user.roleName === USER.ROLE.SA ||
                  (user.roleName === USER.ROLE.CS &&
                    ticket.PicDepartment.picDepartmentCode !== "CS")) &&
                  ticket.statusId !== 3 && (
                    <span
                      className="ms-2 edit"
                      onClick={() => {
                        // setUpdateDept(true)
                        // console.log('coba');
                        // setEditDeptPic(true)
                        {
                          user.roleName !== USER.ROLE.PIC
                            ? setEditDeptPic(true)
                            : setUpdateDept(true);
                        }
                      }}
                    >
                      <FaRegEdit />
                    </span>
                  )}
                {user.roleName === USER.ROLE.CS &&
                  ticket.statusId !== 3 &&
                  ticket.PicDepartment.picDepartmentCode === "CS" && (
                    <span
                      className="ms-2 edit"
                      onClick={() => setEditDeptPic(true)}
                    >
                      <FaRegEdit />
                    </span>
                  )}
              </div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail ">Last Update</div>
              <div className="content-detail-prop">:&nbsp;</div>
              <div className="content-detail">
                {formatDate(ticket.updatedAt)}
              </div>
            </div>
          </div>
          {updateDept && (
            <div className="row mt-2 mb-3 w-50">
              <div className="col">
                <span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setNewDept(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Select Dept
                    </option>
                    {/* <option value="3">OTHERS</option> */}
                    {deptList.map((d) => {
                      return (
                        <option
                          key={d.picDepartmentId}
                          value={d.picDepartmentId}
                        >
                          {d.picDepartmentName}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
              {user.roleName !== USER.ROLE.SA && (
                <div className="col">
                  <button
                    className="btn btn-dark"
                    onClick={() => setConfirmDept(true)}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-dark ms-1"
                    onClick={() => setUpdateDept(false)}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}
          {editDeptPic && (
            <div className="row mt-2 mb-3 w-50">
              <div className="col">
                <span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setNewDept(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Select Dept
                    </option>
                    {deptList.map((d) => {
                      return (
                        <option
                          key={d.picDepartmentId}
                          value={d.picDepartmentId}
                        >
                          {d.picDepartmentName}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
            </div>
          )}
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">PIC</div>
              <div className="content-detail">
                : &nbsp;
                {!updatePic && (
                  <>
                    {ticket.picId === 0 ? "-" : ticket.Pic.picName}{" "}
                    {/* {console.log('status id', ticket.statusId)} */}
                    {ticket.statusId !== 3 &&
                      user.roleName === USER.ROLE.PIC && (
                        <span
                          className="ms-2 edit"
                          onClick={() => setUpdatePic(true)}
                        >
                          <FaRegEdit />
                        </span>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail">Ticket Duration</div>
              <div className="content-detail-prop">:&nbsp;</div>
              <div className="content-detail">
                {ticket.solvingDuration === 0
                  ? onGoingTicket()
                  : minutesToDhms(ticket.solvingDuration)}
              </div>
            </div>
          </div>
          {updatePic && (
            <div className="row mt-2 mb-3 w-50">
              <div className="col">
                <span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChangePic}
                  >
                    <option value="" disabled selected hidden>
                      Select PIC
                    </option>
                    <option
                      value={JSON.stringify({
                        picId: 0,
                        picName: null,
                        picUserId: null,
                      })}
                    >
                      -
                    </option>
                    {listPic.map((p) => {
                      return (
                        <option
                          key={p.picUser}
                          value={JSON.stringify({
                            picId: p.picId,
                            picName: p.picName,
                            picUserId: p.picUser,
                          })}
                        >
                          {p.picName}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
              <div className="col">
                <button
                  disabled={disabledSelectPic}
                  className="btn btn-dark"
                  onClick={() => setConfirmPic(true)}
                >
                  Save
                </button>
                <button
                  className="btn btn-dark ms-1"
                  onClick={() => setUpdatePic(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {editDeptPic && (
            <div className="row mt-2 mb-3 w-50">
              <div className="col">
                <span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChangePic}
                  >
                    <option value="" disabled selected hidden>
                      Select PIC
                    </option>
                    <option
                      value={JSON.stringify({
                        picId: 0,
                        picName: null,
                        picUserId: null,
                      })}
                    >
                      -
                    </option>
                    {listPic.map((p) => {
                      return (
                        <option
                          key={p.picUser}
                          value={JSON.stringify({
                            picId: p.picId,
                            picName: p.picName,
                            picUserId: p.picUser,
                          })}
                        >
                          {p.picName}
                        </option>
                      );
                    })}
                  </select>
                </span>
              </div>
              <div className="col">
                <button
                  disabled={disabledSelectPic}
                  className="btn btn-dark"
                  onClick={() => setConfirmDeptPic(true)}
                >
                  Save
                </button>
                <button
                  className="btn btn-dark ms-1"
                  onClick={() => setEditDeptPic(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">Priority</div>
              <div className="content-detail">
                :
                {!editPrior && (
                  <>
                    {ticket.priorityId === 1 && (
                      <span className="p-1 ms-2 border border-secondary text-secondary border-3 rounded">
                        {ticket.Priority.priorityName}
                      </span>
                    )}
                    {ticket.priorityId === 2 && (
                      <span className="p-1 ms-2 border border-warning text-warning border-3 rounded">
                        {ticket.Priority.priorityName}
                      </span>
                    )}
                    {ticket.priorityId === 3 && (
                      <span className="p-1 ms-2 border border-danger text-danger border-3 rounded">
                        {ticket.Priority.priorityName}
                      </span>
                    )}
                    {ticket.priorityId === 4 && (
                      <span className="p-1 ms-2 border border-primary text-primary border-3 rounded">
                        SPESIAL CASE
                      </span>
                    )}
                    {(user.roleName === USER.ROLE.CS ||
                      user.roleName === USER.ROLE.SA) &&
                      ticket.statusId !== 3 && (
                        <span
                          className="ms-2 edit"
                          onClick={() => setEditPrior(true)}
                        >
                          <FaRegEdit />
                        </span>
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail">Status</div>
              <div className="content-detail">
                :
                {ticket.statusId === 3 && (
                  <span className="p-1 ms-2 border border-success text-success border-3 rounded">
                    {ticket.Status.statusName}
                  </span>
                )}
                {ticket.statusId === 2 && (
                  <span className="p-1 ms-2 border border-secondary text-secondary border-3 rounded">
                    {ticket.Status.statusName}
                  </span>
                )}
                {ticket.statusId === 1 && (
                  <span className="p-1 ms-2 border border-danger text-danger border-3 rounded">
                    {ticket.Status.statusName}
                  </span>
                )}
              </div>
            </div>
          </div>
          {editPrior && (
            <div className="row mt-2 mb-3">
              <div className="col">
                <span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setPriority(Number(e.target.value))}
                  >
                    <option defaultValue="-1">Select Priority</option>
                    <option value={1}>LOW</option>
                    <option value={2}>MEDIUM</option>
                    <option value={3}>HIGH</option>
                    <option value={4}>SPECIAL CASE</option>
                  </select>
                </span>
              </div>
              <div className="col">
                <button
                  className="btn btn-dark"
                  onClick={() => setConfirmEdit(true)}
                >
                  Save
                </button>
                <button
                  className="btn btn-dark ms-1"
                  onClick={() => setEditPrior(false)}
                >
                  Cancel
                </button>
              </div>
              <div className="col"></div>
            </div>
          )}
          <div className="row mt-2 fw-semibold">
            <div className="col d-flex column">
              <div className="content-detail">Title</div>
              <div className="content-detail">
                : &nbsp;
                {!editTitle ? ticket.ticketSubject : ""}
                {user.roleName === USER.ROLE.SA &&
                  ticket.statusId === 1 &&
                  !editTitle && (
                    <span
                      className="ms-2 edit"
                      onClick={() => setEditTitle(true)}
                    >
                      <FaRegEdit />
                    </span>
                  )}
              </div>
            </div>
            <div className="col d-flex column">
              <div className="content-detail">Submition</div>
              <div className="content-detail">
                : &nbsp;
                {submitted ? (
                  ticket.resolved ? (
                    <span style={{ color: "#80d979" }}> Resolved</span>
                  ) : (
                    <span style={{ color: "#fca314" }}> Submitted</span>
                  )
                ) : (
                  <span style={{ color: "red" }}> Not Submitted</span>
                )}
              </div>
            </div>
          </div>
          {editTitle && (
            <>
              <div className="row mt-2 ps-2 pe-4">
                <input
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {title.length > 500 && (
                  <span className="text-danger" style={{ fontSize: "15px" }}>
                    *title can not exceed 500 character
                  </span>
                )}
              </div>
              <div className="mt-1 d-flex justify-content-end pe-3">
                <button
                  className="btn btn-dark"
                  onClick={() => setConfirmEdit(true)}
                  disabled={title.length > 500 || title.length === 0}
                >
                  Save
                </button>
                <button
                  className="btn btn-dark ms-1"
                  onClick={() => {
                    setEditTitle(false);
                    setTitle(ticket.ticketSubject);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
          <div className="row mt-2 fw-semibold">
            <div className="col">
              {(user.roleName === USER.ROLE.CS ||
                user.roleName === USER.ROLE.SA) &&
              !(
                user.userId === picId ||
                (ticket.PicDepartment.picDepartmentCode === "CS" &&
                  picId === "")
              ) &&
              !submitted ? (
                <></>
              ) : (
                <div className="center">
                  <Button
                    onClick={() => setShowSubmit(!showSubmit)}
                    aria-expanded={showSubmit}
                    className={
                      !submitted
                        ? "btn btn-danger"
                        : ticket.resolved
                        ? "btn btn-success"
                        : "btn btn-warning btn-warning-txt-color"
                    }
                    style={{
                      width: "250px",
                    }}
                  >
                    {(((user.roleName === USER.ROLE.CS ||
                      user.roleName === USER.ROLE.SA) &&
                      user.userId !== picId &&
                      !(
                        ticket.PicDepartment.picDepartmentCode === "CS" &&
                        picId === ""
                      )) ||
                      submitted) &&
                      (showSubmit
                        ? "Close Submitted Solution"
                        : "Show Submitted Solution")}
                    {(user.roleName === USER.ROLE.PIC ||
                      user.userId === picId ||
                      (ticket.PicDepartment.picDepartmentCode === "CS" &&
                        picId === "")) &&
                      !submitted &&
                      (showSubmit ? "Cancel" : "Submit Solution")}
                  </Button>
                </div>
              )}

              <div style={{ marginTop: "50px" }}>
                <Collapse in={showSubmit}>
                  <div id="example-collapse-text">
                    <div className="col mb-2">
                      {user.roleName === USER.ROLE.PIC &&
                        !submitted &&
                        "Submit Solution :"}
                      {(user.roleName === USER.ROLE.CS ||
                        user.roleName === USER.ROLE.SA ||
                        submitted) &&
                        "Solution Submitted :"}
                    </div>
                    {((user.roleName === USER.ROLE.PIC ||
                      user.userId === picId ||
                      (ticket.PicDepartment.picDepartmentCode === "CS" &&
                        picId === "")) &&
                      !ticket.resolved &&
                      editSubmisson) ||
                    !submitted ? (
                      <>
                        {" "}
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="7"
                          placeholder="Type solution..."
                          value={solution}
                          onChange={(e) => {
                            setSolution(e.target.value);
                          }}
                        ></textarea>
                        <small className="error">
                          <i>{errorSolution}</i>
                        </small>
                        <div className="d-flex align-items-end justify-content-between mb-4">
                          <div>
                            <label
                              htmlFor="formFileMultiple"
                              className="form-label"
                            >
                              File Attachment
                            </label>
                            <input
                              className={
                                window.screen.width >= 700
                                  ? "form-control"
                                  : "form-control input-create-ticket"
                              }
                              type="file"
                              id="formFileMultiple"
                              onChange={(e) => setFile(e.target.files)}
                              multiple
                              accept=".xls, .xlsx, .doc, .docx, .ppt, .pptx, .txt, .png, .jpeg, .jpg, .zip, .pdf"
                            />
                            {file.length > 10 && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "15px" }}
                              >
                                *maximum attachment is 10 files
                              </span>
                            )}
                            {!validateFileSize() && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "15px" }}
                              >
                                *maximum size of each attachment is 2MB
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col p-3 border border-2 border-secondary border-opacity-25 rounded">
                        {ticket.ticketSolution === ""
                          ? solution
                          : ticket.ticketSolution}
                      </div>
                    )}
                    <div className="col">
                      Attachment :
                      {ticket.Attachment ? (
                        <ul className="list-group list-group-flush">
                          {ticket.Attachment.map((file) => {
                            if (file.solutionAttachment === true) {
                              return (
                                <li
                                  key={file.id}
                                  onClick={() =>
                                    onDownloadAttachment(file.attachmentName)
                                  }
                                  className="list-group-item attachment"
                                >
                                  {file.attachmentName}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      ) : (
                        "No Attachment"
                      )}
                    </div>
                    {(user.roleName === USER.ROLE.PIC ||
                      user.userId === picId ||
                      (ticket.PicDepartment.picDepartmentCode === "CS" &&
                        picId === "")) &&
                      !ticket.resolved && (
                        <div className="mb-3 d-flex justify-content-end gap-2">
                          <div style={{ marginTop: "10px" }}>
                            {submitted && !editSubmisson ? (
                              <AppButtonSubmitPrimary
                                label={"Edit Submission"}
                                className={"btn success-user-btn"}
                                onClick={() => {
                                  setEditSubmisson(true);
                                }}
                              />
                            ) : (
                              <>
                                <AppButtonSubmitPrimary
                                  label={"Submit"}
                                  className={"btn success-user-btn"}
                                  onClick={() => {
                                    handleSubmitSolution();
                                  }}
                                />
                                {editSubmisson && (
                                  <Button
                                    onClick={() => {
                                      setCancelEdit(true);
                                    }}
                                    className={"btn btn-danger"}
                                    style={{
                                      width: "150px",
                                    }}
                                  >
                                    Cancel Edit
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}

                    {((user.roleName === USER.ROLE.CS && submitted) ||
                      (user.userId === picId &&
                        ticket.csId === picId &&
                        ticket.PicDepartment.picDepartmentCode === "CS" &&
                        picId === "")) && ( // edit submission
                      <div className="mb-3 d-flex justify-content-end gap-2">
                        <div style={{ marginTop: "5px" }}>
                          {!ticket.resolved && (
                            <AppButtonSubmitPrimary
                              label="Resolved"
                              className={"btn success-user-btn resolve-reject"}
                              onClick={() => {
                                setConfirmResolve(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>

          <div className="row mt-2 fw-semibold">
            <div className="col">
              Description :
              {user.roleName === USER.ROLE.SA &&
                ticket.statusId === 1 &&
                !editDesc && (
                  <span className="ms-2 edit" onClick={() => setEditDesc(true)}>
                    <FaRegEdit />
                  </span>
                )}
            </div>
          </div>
          <div className="row mt-2  pe-3">
            <div className="col">
              <textarea
                className="form-control"
                value={description}
                rows={5}
                readOnly={editDesc ? false : true}
                onChange={(e) => setDescriprion(e.target.value)}
              />
              {description.length > 1000 && (
                <span className="text-danger" style={{ fontSize: "15px" }}>
                  *description can not exceed 1000 character
                </span>
              )}
            </div>
          </div>
          {editDesc && (
            <div className="mt-1 d-flex justify-content-end pe-3">
              <button
                className="btn btn-dark"
                onClick={() => setConfirmEdit(true)}
                disabled={description.length > 1000 || description.length === 0}
              >
                Save
              </button>
              <button
                className="btn btn-dark ms-1"
                onClick={() => {
                  setEditDesc(false);
                  setDescriprion(ticket.ticketMessage);
                }}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="row mt-3 fw-semibold">
            <div className="col">
              Attachment :
              {ticket.Attachment ? (
                <ul className="list-group list-group-flush">
                  {ticket.Attachment.map((file) => {
                    if (file.solutionAttachment === false) {
                      return (
                        <li
                          key={file.id}
                          onClick={() =>
                            onDownloadAttachment(file.attachmentName)
                          }
                          className="list-group-item attachment"
                        >
                          {file.attachmentName}
                        </li>
                      );
                    }
                  })}
                </ul>
              ) : (
                "No Attachment"
              )}
            </div>
            <div className="col"></div>
            <div className="col"></div>
          </div>

          <div style={{ display: "none" }}>
            <iframe src={file} />
          </div>
        </div>
      </div>

      <TicketHistory />

      <TicketComment />

      {loading && <AppLoading />}
      {confirmStatus && (
        <AddUserConfirmView
          label={"update status"}
          handleYes={onUpdateStatus}
          handleClose={() => setConfirmStatus(false)}
        />
      )}
      {confirmDept && (
        <AddUserConfirmView
          label={"update department"}
          handleYes={onUpdateDept}
          handleClose={() => setConfirmDept(false)}
        />
      )}
      {confirmPic && (
        <AddUserConfirmView
          label={"update The PIC"}
          handleYes={onUpdatePic}
          handleClose={() => setConfirmPic(false)}
        />
      )}
      {confirmDeptPic && (
        <AddUserConfirmView
          label={"set new Department & PIC"}
          handleYes={onSetDepartmentPic}
          handleClose={() => setConfirmDeptPic(false)}
        />
      )}
      {confirmEdit && (
        <AddUserConfirmView
          label={"update detail ticket"}
          handleYes={onEditTicket}
          handleClose={() => setConfirmEdit(false)}
        />
      )}
      {confirmSolution && (
        <AddUserConfirmView
          label={"submit this solution"}
          handleYes={() => {
            onPostSolution(3, ticket.ticketId, solution);
            setConfirmSolution(false);
            setSubmitted(true);
          }}
          handleClose={() => {
            setConfirmSolution(false);
          }}
        />
      )}
      {confirmResolve && (
        <AddUserConfirmView
          label={"resolve this ticket"}
          handleYes={() => {
            onResolve(2, ticket.ticketId, user.userId);
            setConfirmResolve(false);
          }}
          handleClose={() => {
            setConfirmResolve(false);
          }}
        />
      )}
      {successStatus && (
        <AddUserSuccessView label={"Update Status"} handleYes={handleBack} />
      )}
      {successPic && (
        <AddUserSuccessView label={"Update PIC"} handleYes={handleBack} />
      )}
      {successDept && (
        <AddUserSuccessView
          label={"Update Department"}
          handleYes={handleBack}
        />
      )}
      {successDeptPic && (
        <AddUserSuccessView
          label={"Set New Department and PIC"}
          handleYes={handleBack}
        />
      )}
      {successEdit && (
        <AddUserSuccessView
          label={"Update Detail Ticket"}
          handleYes={handleBack}
        />
      )}
      {successSolution && (
        <AddUserSuccessView
          label={"Submit Solution"}
          handleYes={() => {
            setSuccessSolution(false);
            setShowSubmit(false);
            setEditSubmisson(false);
          }}
        />
      )}
      {successResolve && (
        <GetScore
          label={score}
          handleYes={() => {
            setSuccessResolve(false);
            setShowSubmit(false);
          }}
        />
      )}
      {cancelEdit && (
        <AddUserCancelView
          handleYes={() => {
            setEditSubmisson(false);
            setCancelEdit(false);
          }}
          handleClose={() => {
            setCancelEdit(false);
          }}
        />
      )}
      {unAuthorized && <AppUnauthorized />}
      {failedUpdate && (
        <AddFailedView
          failedMsg={errorMessage}
          handleYes={() => setFailedUpdate(false)}
        />
      )}
    </>
  );
};
