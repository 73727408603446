import './AddFailed.css';
import { ImCross } from 'react-icons/im';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../../../shared/components/AppButton/AppButtonSubmitPrimary';

const AddFailedView = ({ failedMsg, handleYes }) => {
    return (
        <div className="warn-add-user-container">
            <div className="failed-ellips">
                <ImCross
                    className="failed-icon"
                    color="#FFFFFF"
                    size={90}
                />
            </div>
            <div className="warn-add-user-content">
                <br />
                <h5 style={{ color: '#7C8691', padding: '5px', fontSize: '25px' }}>
                    <strong>Failed</strong>
                </h5>
                <h6 style={{color: '#7C8691'}}>{failedMsg}</h6>
                <br />
                <div className="buttons">
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'OK'}
                            className={'btn failed-btn'}
                            onClick={handleYes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFailedView;
