import React, { useEffect, useState } from 'react';
import './AppLayout.css';
import { AppSidebar } from '../AppSidebar/AppSidebar';
import { AppHeader } from '../AppHeader/AppHeader';
import { Outlet } from 'react-router-dom';
import { useHeader } from '../AppHeader/useHeader';
import { useProSidebar } from 'react-pro-sidebar';
import { AppSidebarV2 } from './AppSidebar/AppSidebarV2';

export const AppLayout = () => {
    const [sidebar, setSidebar] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false)
    const { collapseSidebar } = useProSidebar();

  useEffect (()=>{
    if (window.screen.width <= 900) {
      setSidebar(false)
      setHideSidebar(true)
    } else {
        setHideSidebar(false)
    }
  }, [])

    return (
        <div className="AppLayout">
            <div className="d-flex flex-row AppLayout">
                {/* <div>{true && <AppSidebar />}</div> */}
                {!hideSidebar && <AppSidebarV2 sidebar={sidebar} /> }
                <div className="px-3 AppContent">
                    <AppHeader
                        handleClick={() => {

                            if (window.screen.width <= 900) {
                                setHideSidebar(!hideSidebar)
                                setSidebar(!sidebar)
                            } else {
                                // collapseSidebar();
                                setSidebar(!sidebar);
                            }
                             collapseSidebar();
                        }}
                    />
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
