import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../shared/hook/UseDependency';
import { BiEdit } from 'react-icons/bi';
import { Tooltip } from '@mui/material';
import './ProductList.css'

export const ProductList = () => {
    const navigate = useNavigate();
    const { productService } = useDependency();
    const [isLoading, setIsLoading] = useState(false);
    const [productListPage, setProductListPage] = useState([])
    const [page, setPage] = useState(1);
    const [searchProduct, setSearchProduct] = useState('');

    useEffect(() => {
        onGetAllProductPagination();
    }, [page])

    useEffect(() => {
        onSearchProductPagination(searchProduct);
        console.log('search', searchProduct);
    }, [searchProduct])

    const onGetAllProductPagination = async () => {
        setIsLoading(true)
        try {
            const response = await productService.getAllProductPagination(page)
            // console.log('response product', response);
            if (response.status === 'SUCCESS') {    
                setProductListPage(response.message);
            }
            // console.log('ini response',response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const onSearchProductPagination = async (searchProduct) => {
        if(searchProduct.length < 2){
            onGetAllProductPagination();
            return
        }

        const response = await productService.searchNameProduct(page, searchProduct);
        if (response.status === 'SUCCESS') {
            if (response.message !== null){
                setProductListPage(response.message)
            } else {
                setProductListPage([])
            }
        }
    }

    const handleClearFilter = () => {
        setPage(1)
    }

    const handleChangePageAll = (task) => {
        if (task === 'next') {
            setPage(page + 1)
            return
        }
        setPage(page - 1)
    }

  return (
    <>
        <div>
            <button
                onClick={() => {
                    navigate('/home/product-list/add-product');
                }}
                className="btn btn-outline-secondary btn-add-product">
                    Add New Product
                </button>
                <div className='table table-responsive'>
                <div className="card border-0 mt-4">
                <div className='d-flex justify-content-between'>
                <div>
                <h4 className='card-title search-form-product'>List of Products</h4>
                </div>
                <div className='d-flex gap-2'>
                <input type="text" className="form-control search-form-product" id="searchProduct" placeholder="Search product name" value={searchProduct} onChange={(e)=> setSearchProduct(e.target.value)}/>
                </div>
                </div>
                <div className="card-body">
                    <table className="table" class="table table-striped">
                        <thead>
                        <tr>
                            {/*<th>Product Code</th>*/}
                            <th>Product Name</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                            {productListPage.length === 0 ? (
                                <tr>
                                    <td colSpan='3' className='fw-bold text-center p-3'>
                                        Data is not found
                                    </td>
                                </tr>
                            ) : (
                                productListPage.map(product => (
                                    <tr key={product.productId}>
                                        {/*<td>{product.productId}</td>*/}
                                        <td>{product.productName}</td>
                                        <td><button  onClick={() => {navigate('/home/product-list/edit-product',{state: {productId: product.productId, productName: product.productName},});}} type="button" className="btn btn-icons">
                                                        <Tooltip title="Edit Product">
                                                            <div className="changeColorEdit">
                                                                <BiEdit size={25} />
                                                            </div>
                                                        </Tooltip>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table> 
                    <div className='d-flex justify-content-center'>
                        <ul className="pagination">
                        {page !== 1 && 
                            <li className="page-item" onClick={() => handleChangePageAll('prev')}>
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                        }
                        <li className="page-item disabled"><a className="page-link" href="#">{page}</a></li>
                        {productListPage.length >= 10 && 
                            <li className="page-item" onClick={() => handleChangePageAll ('next')}>
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        }
                        </ul>
                    </div>
                </div>
                </div>
                </div>
        </div>
    
    </>
  )
}
