import { LoginService } from './LoginService';
import { OTPService } from './OTPService';
import { notificationService } from './NotificationService';
import { ticketService } from './TicketService';
import { userService } from './UserService';
import { departementService } from './DepartementService';
import { departmentService } from './DepartmentService';
import { picService } from './PicService';
import { dbInstance } from '../firebase';
import { firebaseService } from './FirebaseService';
import { commentServiceFireStore } from './CommentServiceFireStore';
import { categoryService } from './CategoryService';
import { productService } from './ProductService';

export const serviceFactory = (apiClient) => {
    return {
        loginService: LoginService(apiClient),
        otpService: OTPService(apiClient),
        ticketService: ticketService(apiClient),
        userService: userService(apiClient),
        departementService: departementService(apiClient),
        departmentService: departmentService(apiClient),
        picService: picService(apiClient),
        // commentService: commentService(apiClient),
        categoryService: categoryService(apiClient),
        productService: productService(apiClient),
        notificationService: notificationService(dbInstance),
        firebaseService : firebaseService(dbInstance),
        commentServiceFireStore: commentServiceFireStore(dbInstance),
    };
};
