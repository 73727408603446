import React from 'react'
import { useNavigate } from 'react-router-dom';

import { BiEdit } from 'react-icons/bi';
import { Tooltip } from '@mui/material';
import './CategoryList.css'
import useCategoryList from './useCategoryList';

export const CategoryList = () => {
    const navigate = useNavigate();
    const { isLoading,
        categoryListPage,
        page,
        searchCategory,
        setSearchCategory,
        handleClearFilter,
        handleChangePageAll
    } = useCategoryList();

  return (
    <>
        <div>
            <button
                onClick={() => {
                    navigate('/home/category-list/add-category');
                }}
                className="btn btn-outline-secondary btn-add-category">
                    Add New Category
                </button>
                <div className='table table-responsive'>
                <div className="card border-0 mt-4">
                <div className='d-flex justify-content-between'>
                <div>
                <h4 className='card-title search-form-category'>List of Category</h4>
                </div>
                <div className='d-flex gap-2'>
                <input type="text" className="form-control search-form-category" id="searchCategory" placeholder="Search category name" value={searchCategory} onChange={(e)=> setSearchCategory(e.target.value)}/>
                </div>
                </div>
                <div className="card-body">
                    <table className="table" class="table table-striped">
                        <thead>
                        <tr>
                            {/*<th>Category Code</th>*/}
                            <th>Category Name</th>
                            <th>Department</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                            {categoryListPage.length === 0 ? (
                                <tr>
                                    <td colSpan='4' className='fw-bold text-center p-3'>
                                        Data is not found
                                    </td>
                                </tr>
                            ) : (
                                categoryListPage.map(category => (
                                    <tr key={category.categoryId}>
                                        {/*<td>{category.categoryId}</td>*/}
                                        <td>{category.categoryName}</td>
                                        <td>{category.departmentName}</td>
                                        <td>
                                            <button  onClick={() => {navigate('/home/category-list/edit-category',{state: {categoryId: category.categoryId, categoryName: category.categoryName, departmentName: category.departmentName, departmentId: category.departmentId},});}} type="button" className="btn btn-icons">
                                                        <Tooltip title="Edit Category">
                                                            <div className="changeColorEdit">
                                                                <BiEdit size={25} />
                                                            </div>
                                                        </Tooltip>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table> 
                    <div className='d-flex justify-content-center'>
                        <ul className="pagination">
                        {page !== 1 && 
                            <li className="page-item" onClick={() => handleChangePageAll('prev')}>
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                        }
                        <li className="page-item disabled"><a className="page-link" href="#">{page}</a></li>
                        {categoryListPage.length >= 10 && 
                            <li className="page-item" onClick={() => handleChangePageAll ('next')}>
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        }
                        </ul>
                    </div>
                </div>
                </div>
                </div>
        </div>
    
    </>
  )
}
