import './OtpExpired.css';
import { FcExpired } from 'react-icons/fc';
import React from 'react';

const OtpExpiredView = (props) => {
    return (
        <div className="otp-expired-container">
            <div className="unregistered-email-content">
                <FcExpired color="#FFDD15" size={120} />
                <h5 style={{ color: '#B70621' }}>OTP has expired.</h5>
                <h6 style={{ color: '#6A6A6A' }}>
                    Please resend the OTP to your email!
                </h6>
                <div>
                    <button
                        onClick={props.handleResend}
                        className="btn btn-danger"
                    >
                        Resend OTP
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OtpExpiredView;
