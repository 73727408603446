import useLoginPage from './UseLoginPage';
import { RiLock2Fill } from 'react-icons/ri';
import 'bootstrap/dist/css/bootstrap.css';
import OTPVerifyView from '../OTPVerify/OTPVerifyView';
import UseUnregisteredEmail from '../UnregisteredEmail/UnregisteredEmailView';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputEmail } from '../../../shared/components/AppInput/AppInputEmail';
import { AppLoading } from '../../../shared/components/AppLoading/AppLoading';
import OtpExpiredView from '../OtpExpired/OtpExpiredView';
// import { OTPVerifyViewMemo } from '../OTPVerify/OTPVerifyView';

const LoginPageView = (props) => {
    const {
        email,
        showOTPVerify,
        showUnregisteredEmail,
        isLoading,
        submit,
        handleSubmit,
        handleChangeEmail,
        handleErrorEmail,
        handleCloseOTP,
        handleCloseUnregisteredEmail,
        onPostEmail,
    } = useLoginPage(props);

    return (
        <div className='row'>
            <div className="col-lg-7 col-md-7 d-none d-md-block  body">
            <img src="../narindoLogo.png" alt="narindo logo" width="200px" />
            </div>
            <div className="col-lg-5 col-md-5 login-background">
                <div className="flex-container">
                    <div className="login-container">
                        <div className="ellipse">
                            <RiLock2Fill color="white" size={50} />
                        </div>
                        <div className="login-form text-center">
                            <p
                                style={{
                                    marginTop: '0px',
                                    marginBottom: '0px',
                                    fontSize: '18px',
                                    color: '#6A6A6A',
                                }}
                            >
                                Welcome to
                            </p>
                            <h3 className="login-header email-verify">
                                Ticket Narindo
                            </h3>
                            <br />
                            <form>
                                <div className="login-form-item">
                                    <AppInputEmail
                                        label={'Enter your email address'}
                                        email={email}
                                        placeholder={'narindo@gmail.com'}
                                        handleChange={handleChangeEmail}
                                        handleError={handleErrorEmail}
                                        submit={submit}
                                        disable={showOTPVerify}
                                    />
                                </div>
                                <br />
                                <br />
                                <AppButtonSubmitPrimary
                                    disabled={isLoading}
                                    label="Sign in"
                                    className={'btn btn-danger get-otp-btn'}
                                    onClick={handleSubmit}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {showOTPVerify && (
                    <OTPVerifyView
                        handleClose={handleCloseOTP}
                        email={email}
                        onPostEmail={onPostEmail}
                    />
                )}
                {showUnregisteredEmail && (
                    <UseUnregisteredEmail
                        handleClose={handleCloseUnregisteredEmail}
                    />
                )}
                {isLoading && <AppLoading />}
                {/* <OtpExpiredView /> */}
            </div>
        </div>
    );
};

export default LoginPageView;
