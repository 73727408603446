import axios from 'axios';
import { authHeaderInterceptor } from './interceptor/AuthHeaderInterceptor';

export const clientInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

clientInstance.interceptors.request.use(authHeaderInterceptor);

clientInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response.status === 401) {
            let user = window.localStorage.getItem('user')
            let refreshToken = window.localStorage.getItem('refreshToken')
            try {
                const refresh = await clientInstance.post('/login/refresh', {
                    userEmail: JSON.parse(user).userEmail,
                    refreshToken: refreshToken,
                })

                if (refresh.data.status === 'SUCCESS') {
                    window.localStorage.setItem('token', refresh.data.loginAsUser.token);
                    window.localStorage.setItem('refreshToken', refresh.data.loginAsUser.refreshToken);
                }
            } catch (e) {
                console.log('Interceptors 401 Error:', e);
            }
        } 
        return Promise.reject(error)
    }
)