export const apiClientFactory = (client) => {
    const doPost = async ({url = "", data = null}) => {
        try {
            const response = await client.post(url,data);
            return response.data
        } catch (error) {
            throw error
        }
    }

    const doGet = async ({url = "", params = {}}) => {
        try {
            if (params !== {}){
                const response = await client.get(url, { params : params});
                return response.data
            }
            const response = await client.get(url);
            return response.data
        } catch (error) {
            throw error
        }
    }

    const doPut = async ({url = "", data = null}) => {
        try {
            const response = await client.put(url, data);
            return response.data
        } catch (error) {
            throw error
        }
    }

    const doDelete = async ({url = ""}) => {
        try {
            const response = await client.delete(url);
            return response.data
        } catch (error) {
            throw error
        }
    }

    const downloadFile = async (filename, url) => {
        try {
            client({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
              });
        } catch (error) {
            throw error
        }
    }

    return {doPost, doGet, doPut, doDelete, downloadFile}
}