import React, { useEffect, useState } from 'react';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputEmail } from '../../../shared/components/AppInput/AppInputEmail';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import { AppInputRoleChecked } from '../../../shared/components/AppInput/AppInputRoleChecked';
import useEditUser from './useEditUser';
import { AppInputDepartment } from '../../../shared/components/AppInput/AppInputDepartment';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { Tooltip } from '@mui/material';
import { IoMdArrowRoundBack } from 'react-icons/io';
import AppUnauthorized from '../../../shared/components/Popup/AppUnauthorized/AppUnauthorized';
import Collapse from 'react-bootstrap/Collapse';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';
import { AppLoading } from '../../../shared/components/AppLoading/AppLoading';

const EditUserView = (props) => {
    const {
        name,
        handleErrorName,
        handleChangeName,
        email,
        handleErrorEmail,
        handleChangeEmail,
        setUserId,
        role,
        handleErrorRole,
        handleChangeRole,
        department,
        handleChangeDepartment,
        handleErrorDepartment,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        hadnleResetSubmit,
        showEditName,
        setShowEditName,
        showEditEmail,
        setShowEditEmail,
        showEditRole,
        setShowEditRole,
        oldRole,
        handleChangeOldRole,
        selectedDept,
        oldDepart,
        handleChangeOldDepart,
        departmentResponse,
        unAuthorized,
        emailExist,
        failedEditUser,
        setFailedEditUser,
        errorMessage,
        isLoading,
        setSelectedDept,
    } = useEditUser(props);
    const navigate = useNavigate();
    const location = useLocation();

    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        setUserId(location.state.id);
        handleChangeName(location.state.name);
        handleChangeEmail(location.state.email);
        handleChangeRole(location.state.role);
        handleChangeOldRole(location.state.role);
        handleChangeOldDepart(location.state.department);
        
    }, []);

    useEffect(() => {
        setSelectedDept(departmentResponse.filter((departmentItem) => {
            return (
                departmentItem.picDepartmentId === location.state.department
            )
        }))
        handleChangeDepartment(selectedDept.picDepartmenId) //diconvert ke angka
    }, [])

    return (
        <>
            <div>
                <button
                    onClick={() => {
                        navigate('/home/user-list');
                    }}
                    className="btn btn-outline-secondary btn-add-user"
                >
                    <IoMdArrowRoundBack size={20} /> Back
                </button>
                <div className="add-user-flex-container">
                    <div className="edit-user-container">
                        <h2>Edit User</h2>
                        <div className="underline"></div>
                        <br />
                        <table className="table table-hover">
                            <tbody>
                                {/*<tr>*/}
                                {/*    <td>*/}
                                {/*        <strong>UserId</strong>*/}
                                {/*    </td>*/}
                                {/*    <td colSpan={2}>{location.state.id}</td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td>
                                        <strong>Name</strong>
                                    </td>
                                    <td>{name}</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setShowEditName(true);
                                                setShowEditEmail(false);
                                                setShowEditRole(false);
                                                setShowButtons(true);
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Name">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Email</strong>
                                    </td>
                                    <td>{email}</td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setShowEditEmail(true);
                                                setShowEditName(false);
                                                setShowEditRole(false);
                                                setShowButtons(true);
                                            }}
                                            type="button"
                                            className="btn btn-icons"
                                        >
                                            <Tooltip title="Edit Email">
                                                <div className="changeColorEdit">
                                                    <BiEdit size={25} />
                                                </div>
                                            </Tooltip>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Role</strong>
                                    </td>
                                    <td>{role}</td>
                                    <td>
                                        {role !== 'SUPER ADMIN' &&
                                        <button
                                        onClick={() => {
                                            setShowEditRole(true);
                                            setShowEditEmail(false);
                                            setShowEditName(false);
                                            setShowButtons(true);
                                        }}
                                        type="button"
                                        className="btn btn-icons"
                                        >
                                        <Tooltip title="Edit Role">
                                            <div className="changeColorEdit">
                                                <BiEdit size={25} />
                                            </div>
                                        </Tooltip>
                                        </button>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Department</strong>
                                    </td>
                                    <td colSpan={2}>
                                        {selectedDept.length === 0 &&
                                        role !== 'CUSTOMER SERVICE'
                                            ? oldDepart // dalam string ENGINEERING BUSINESS OTHERS
                                            : role === 'CUSTOMER SERVICE' //kalau customer service
                                            ? '-'
                                            : selectedDept[0].picDepartmentName}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Collapse in={showEditName}>
                            <div>
                                <AppInputName
                                    label={'Edit Name'}
                                    handleError={handleErrorName}
                                    name={name}
                                    placeholder={'max 100char'}
                                    handleChange={handleChangeName}
                                    submit={submit}
                                />
                            </div>
                        </Collapse>
                        {/* {showEditName && (
                            <AppInputName
                                label={'Edit Name'}
                                handleError={handleErrorName}
                                name={name}
                                placeholder={'max 100char'}
                                handleChange={handleChangeName}
                                submit={submit}
                            />
                        )} */}
                        <Collapse in={showEditEmail}>
                            <div>
                                <AppInputEmail
                                    label={'Edit Email'}
                                    handleError={handleErrorEmail}
                                    email={email}
                                    placeholder={'should contain @ and .'}
                                    handleChange={handleChangeEmail}
                                    submit={submit}
                                    emailExist={emailExist}
                                />
                            </div>
                        </Collapse>
                        {/* {showEditEmail && (
                            <AppInputEmail
                                label={'Edit Email'}
                                handleError={handleErrorEmail}
                                email={email}
                                placeholder={'should contain @ and .'}
                                handleChange={handleChangeEmail}
                                submit={submit}
                            />
                        )} */}
                        <Collapse in={showEditRole}>
                            <div>
                            <AppInputRoleChecked
                                    role={role}
                                    handleChange={handleChangeRole}
                                    handleError={handleErrorRole}
                                    submit={submit}
                                    checked={role}
                                />
                                <br />
                                <div>
                                    {role === 'PIC' && (
                                        <AppInputDepartment
                                            value={department}
                                            handleChange={
                                                handleChangeDepartment
                                            }
                                            handleError={handleErrorDepartment}
                                            submit={submit}
                                            departments={departmentResponse}
                                        />
                                    )}
                                </div>
                            </div>
                        </Collapse>
                        {/* {showEditRole && (
                            <>
                                <AppInputRoleChecked
                                    role={role}
                                    handleChange={handleChangeRole}
                                    handleError={handleErrorRole}
                                    submit={submit}
                                    checked={role}
                                />
                                <br />
                                <div>
                                    {role === 'PIC' && (
                                        <AppInputDepartment
                                            value={department}
                                            handleChange={
                                                handleChangeDepartment
                                            }
                                            handleError={handleErrorDepartment}
                                            submit={submit}
                                            departments={departmentResponse}
                                        />
                                    )}
                                </div>
                            </>
                        )} */}
                        <br />
                        {showButtons && (
                            <div className="edit-buttons">
                                <div className="edit-user-btn-left">
                                    <AppButtonSubmitPrimary
                                        label={'Save Changes'}
                                        className={
                                            'btn btn-danger edit-user-btn'
                                        }
                                        onClick={handleSave}
                                    />
                                </div>
                                <div className="edit-user-btn-right">
                                    <AppButtonSubmitPrimary
                                        label={'Close'}
                                        className={
                                            'btn btn-outline-danger edit-user-btn'
                                        }
                                        onClick={() => {
                                            setShowButtons(false);
                                            setShowEditName(false);
                                            setShowEditEmail(false);
                                            setShowEditRole(false);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {save && (
                            <AddUserConfirmView
                                label={'Edit This User'}
                                handleYes={() => {
                                    handleSaveYes();
                                }}
                                handleClose={() => {
                                    setSave(!save);
                                }}
                            />
                        )}
                        {success && (
                            <AddUserSuccessView
                                label={'Edit User'}
                                handleYes={() => {
                                    setSuccess(!success);
                                    setShowButtons(false);
                                    setShowEditName(false);
                                    setShowEditEmail(false);
                                    setShowEditRole(false);
                                }}
                            />
                        )}
                        {cancel && (
                            <AddUserCancelView
                                handleYes={handleCancel}
                                handleClose={() => {
                                    setCancel(!cancel);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {unAuthorized && <AppUnauthorized />}
            {failedEditUser && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedEditUser(false)}/>}
            {isLoading && <AppLoading/>}
        </>
    );
};

export default EditUserView;