export const userService = ({ doGet, doPost, doPut }) => {
    const getAllUsers = async (page,sort,order,deptId,rId) => {
        try {
            // if (page=="1") {
            //     return await doGet({ url: '/api/user/list/'+String(page)});
            // }
            console.log("roleid",rId);
            return await doGet({ url: '/api/user/list-all/'+String(page)+'/'+String(sort)+'/'+String(order), params: { departmentId: deptId,roleId:rId} });
        } catch (error) {
            throw error;
        }
    };

    const getAllRole = async () => {
        try {
            return await doGet({ url: `/api/user/role` });
        } catch (error) {
            throw error;
        }
    }

    // const getAllUserByRole = async (page,sort,order,deptId,rId) => {
    //     try {
    //         return await doGet({ url: '/api/user/list-all/'+String(page)+'/'+String(sort)+'/'+String(order), params: { departementId: deptId,roleId:rId} });
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    const getAllDepartments = async () => {
        try {
            return await doGet({ url: `/api/user/department` });
        } catch (error) {
            throw error;
        }
    }

    const postUserCS = async (email, name) => {
        try {
            return await doPost({
                url: '/api/user/create/cs',
                data: {
                    userEmail: email,
                    userName: name,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateUserEmail = async (userId, email) => {
        try {
            return await doPut({
                url: `/api/user/email/update/${userId}`,
                data: {
                    userEmail: email,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateUserName = async (userId, name) => {
        try {
            return await doPut({
                url: `/api/user/name/update/${userId}`,
                data: {
                    userName: name,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const deleteUser = async (userId) => {
        try {
            return await doPut({
                url: `/api/user/status/update/${userId}`,
            });
        } catch (e) {
            throw e;
        }
    };

    const postUserPIC = async (email, name, department) => {
        try {
            return await doPost({
                url: '/api/user/create/pic',
                data: {
                    userEmail: email,
                    userName: name,
                    picDetail: {
                        picDepartment: Number(department),
                    },
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateRoleFromPic = async (userId, id, name, code) => {
        try {
            return await doPut({
                url: `/api/user/role/update/npic/${userId}`,
                data: {
                    roleId: id,
                    roleName: name,
                    roleCode: code,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateRoleFromCS = async (userId, id, name, code) => {
        try {
            return await doPut({
                url: `/api/user/role/update/pic/${userId}`,
                data: {
                    picDepartmentId: id,
                    picDepartmentName: name,
                    picDepartmentCode: code,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateDeparment = async (userId, id, name, code) => {
        try {
            return await doPut({
                url: `/api/user/department/update/${userId}`,
                data: {
                    picDepartmentName: name,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const postUserSA = async (email, name) => {
        try {
            return await doPost({
                url: '/api/user/create/sa',
                data: {
                    userEmail: email,
                    userName: name,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const searchUser = async (userName) => {
        try {
            return await doGet({ url: `/api/user/search/${userName}`});
        } catch (error) {
            throw error;
        }
    };

    return {
        getAllUsers,
        getAllDepartments,
        postUserCS,
        postUserSA,
        postUserPIC,
        updateUserEmail,
        updateUserName,
        deleteUser,
        updateDeparment,
        updateRoleFromPic,
        updateRoleFromCS,
        searchUser,
        getAllRole,
    };
};
