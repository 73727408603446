import { useEffect, useState } from 'react';
import { useDependency } from '../../../shared/hook/UseDependency';
import { useNavigate } from 'react-router-dom';
import './EditUser.css';
import { useUnauthorized } from '../../../shared/components/Popup/AppUnauthorized/useUnauthorized';

const useEditUser = (props) => {
    const { userService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // jaga2 kalau perlu loading
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [role, setRole] = useState('');
    const [errorRole, setErrorRole] = useState('');
    const [department, setDepartment] = useState('');
    const [errorDepartment, setErrorDepartment] = useState('');
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);

    const [showEditName, setShowEditName] = useState(false);
    const [showEditEmail, setShowEditEmail] = useState(false);
    const [showEditRole, setShowEditRole] = useState(false);
    const [departmentResponse, setDepartmentResponse] = useState([]);
    const [selectedDept, setSelectedDept] = useState([]);
    const [oldRole, setOldRole] = useState('');
    const [oldDepart, setOldDepart] = useState('');
    const {unAuthorized, setUnauthorized} = useUnauthorized();
    const [emailExist, setEmailExist] = useState(false);
    const [failedEditUser, setFailedEditUser] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        onGetAllDepartments();
        console.log('sdsds');
    }, [department]);

    useEffect(() => {
        onGetAllDepartments()
        console.log('getAlldep');
    }, [])

    useEffect(() => {
        console.log('deptResponse', departmentResponse);
        /// set dari name department
    }, [departmentResponse])

    const onUpdateEmail = async (userId, email) => {
        setIsLoading(true);
        try {
            const response = await userService.updateUserEmail(userId, email);
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE EMAIL');
                setSuccess(!success)
            }           
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditUser(true)
                setErrorMessage('Connection problem')
                setErrorEmail('Connection problem')
            } else if ('ERROR: duplicate key value violates unique constraint "u_user_email_key" (SQLSTATE 23505)'){
                setEmailExist(true)
                setErrorEmail('Email already exist')
            } else {
                setFailedEditUser(true)
                setErrorMessage('Email failed to update')
                setErrorEmail('Email failed to update')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdateName = async (userId, name) => {
        setIsLoading(true);
        try {
            const response = await userService.updateUserName(userId, name);
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE NAME');
                setSuccess(!success)
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditUser(true)
                setErrorName('Connection problem')
                setErrorMessage('Connection problem')
            } else {
                setFailedEditUser(true)
                setErrorName('Username failed to update')
                setErrorMessage('Username failed to update')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdateRoleFromPic = async (userId, id, name, code) => {
        setIsLoading(true);
        try {
            const response = await userService.updateRoleFromPic(
                userId,
                id,
                name,
                code
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE ROLE FROM PIC');
                setSuccess(true);
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditUser(true)
                setErrorRole('Connection problem')
                setErrorMessage('Connection problem')
            } else {
                setFailedEditUser(true)
                setErrorRole('Role failed to update')
                setErrorMessage('Role failed to update')
            }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdateRoleFromCS = async (userId, id, name, code) => {
        setIsLoading(true);
        try {
            const response = await userService.updateRoleFromCS(
                userId,
                id,
                name,
                code
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE ROLE FROM CS');
                setSuccess(true);
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditUser(true)
                setErrorRole('Connection problem')
                setErrorMessage('Connection problem')
            } else {
                setFailedEditUser(true)
                setErrorRole('Role failed to update')
                setErrorMessage('Role failed to update')
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdateDepartment = async (userId, id, name, code) => {
        setIsLoading(true);
        try {
            const response = await userService.updateDeparment(
                userId,
                id,
                name,
                code
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE DEPARTMENT');
                setSuccess(true);
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditUser(true)
                setErrorRole('Connection problem')
                setErrorMessage('Connection problem')
            } else {
                setFailedEditUser(true)
                setErrorRole('Role failed to update')
                setErrorMessage('Role failed to update')
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log('selcDept', selectedDept);
        console.log('response', departmentResponse);
        console.log('department', department);
    }, [selectedDept, departmentResponse, department])


    const onGetAllDepartments = async () => {
        setIsLoading(true);
        try {
            const response = await userService.getAllDepartments();
            if (response.status === 'SUCCESS') {
                // console.log('message', response.message);
                setDepartmentResponse(response.message);
                setSelectedDept(
                    response.message.filter((departmentItem) => {
                        return (
                            departmentItem.picDepartmentId ===
                            Number(department)
                        );
                    })
                );
            }
        } catch (e) {
                console.log('error depat', e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeOldRole = (value) => {
        setOldRole(value);
    };

    const handleChangeOldDepart = (value) => {
        setOldDepart(value);
    };

    const handleChangeName = (value) => {
        setName(value);
    };

    const handleErrorName = (value) => {
        setErrorName(value);
    };

    const handleChangeEmail = (value) => {
        setEmail(value);
        setEmailExist(false)
    };

    const handleErrorEmail = (value) => {
        setErrorEmail(value);
    };

    const handleChangeRole = (value) => {
        setRole(value);
    };

    const handleErrorRole = (value) => {
        setErrorRole(value);
    };

    const handleChangeDepartment = (value) => {
        setDepartment(value);
    };

    const handleErrorDepartment = (value) => {
        setErrorDepartment(value);
    };

    useEffect(() => {
        console.log('error name', errorName);
        console.log('error email', errorEmail);
    }, [errorName, errorEmail])

    const handleSaveYes = () => {;
        if (showEditName === true && errorName === '') {
            onUpdateName(userId, name)
            setSave(!save)
        } else if (showEditEmail === true && errorEmail === '') {
            onUpdateEmail(userId, email)
            setSave(!save)
        } else if (showEditRole === true){
            if (department !== '' || role === 'CUSTOMER SERVICE') {
                console.log('if resullt');
                console.log("role: ", role);
                switch (role) {
                    case 'CUSTOMER SERVICE':
                        console.log('case cs');
                        onUpdateRoleFromPic(userId, 2, 'CUSTOMER SERVICE', 'CS');
                        setSave(!save)
                        break;
                    case 'PIC':
                        console.log('case pic');
                        if (oldRole === 'CUSTOMER SERVICE') {
                            console.log('if cs');
                            console.log(selectedDept);
                            console.log(department);
                            if (selectedDept.length !== 0) {
                                onUpdateRoleFromCS(
                                    userId,
                                    Number(department),
                                    selectedDept[0].picDepartmentName,
                                    selectedDept[0].picDepartmentCode
                                );
                            } else {
                                if (department === undefined) {
                                    console.log('depart kosong');
                                    setErrorDepartment('Department field must be filled')
                                    setFailedEditUser(true)
                                    setErrorMessage('Department must be filled')
                                } else {
                                    setFailedEditUser(true)
                                    setErrorRole('Connection problem')
                                    setErrorMessage('Connection problem')
                                }
                            }
                            setSave(!save)  
                        } else {
                            console.log('else cs');
                            console.log(selectedDept);
                            console.log(department);
                            if (selectedDept.length !== 0) {
                                onUpdateDepartment(
                                    userId,
                                    Number(department),
                                    selectedDept[0].picDepartmentName,
                                    selectedDept[0].picDepartmentCode
                                );
                            } else {
                                if (department === undefined) {
                                    console.log('depart kosong');
                                    setErrorDepartment('Department must be filled')
                                    setFailedEditUser(true)
                                    setErrorMessage('Department must be filled')
                                } else {
                                    setFailedEditUser(true)
                                    setErrorRole('Connection problem')
                                    setErrorMessage('Connection problem')
                                }
                            }
                            setSave(!save)
                        }
                        break;
                }
            }
        }
    };

    const hadnleResetSubmit = () => {
        setSubmit(0);
    };

    const handleCancel = () => {
        setName('');
        setEmail('');
        setRole('');
        setDepartment('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/user-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        // if (
        //     email !== '' &&
        //     errorEmail === '' &&
        //     name !== '' &&
        //     errorName === '' &&
        //     role !== '' &&
        //     errorRole === ''
        // ) {
        //     if (role === 'PIC') {
        //         if (department !== '' && errorDepartment === '') {
        //             setSave(!save);
        //         }
        //     } else if (role == 'CS') {
        //         setSave(!save);
        //     }
        // }

        if (
            role === 'PIC' && department!=='' && errorDepartment===''
        ) {
            setSave(!save);
        } else if (
        
        email !== '' &&
        errorEmail === '' &&
        name !== '' &&
        errorName === ''
        //  &&
        // role !== 'PIC'
        // role !== '' &&
        // errorRole === ''
        ){
            setSave(!save)
        }

        // if (name !== '' && errorName === ''){
        //     setSave(!save)
        // } else {
        //     setSave(!save)
        // }

        // belum handle kalau errorDepartment

        // setSave(!save);
    };

   
    return {
        name,
        handleErrorName,
        handleChangeName,
        email,
        handleErrorEmail,
        handleChangeEmail,
        setUserId,
        role,
        handleErrorRole,
        handleChangeRole,
        department,
        handleChangeDepartment,
        handleErrorDepartment,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        hadnleResetSubmit,
        showEditName,
        setShowEditName,
        showEditEmail,
        setShowEditEmail,
        showEditRole,
        setShowEditRole,
        oldRole,
        handleChangeOldRole,
        selectedDept,
        oldDepart,
        handleChangeOldDepart,
        departmentResponse,
        unAuthorized,
        emailExist,
        failedEditUser,
        setFailedEditUser,
        errorMessage,
        setSelectedDept,
        isLoading
    };
};

export default useEditUser;