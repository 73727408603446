import React from 'react'

export const AppButtonSubmitPrimary = ({disabled, label, className, onClick}) => {
  return (
    <button
        variant="primary"
        type="submit"
        value="submit"
        className={className}
        disabled={disabled}
        onClick={onClick}
    >
        {label}
    </button>
  )
}
