import React from 'react'

export const departementService = ({doGet}) => {
  
    const getAllDepartement = async () => { // cari semua
        try {
            return await doGet({url : '/api/user/department'})
        } catch (error) {
            throw error
        }
    }
  
  return {
    getAllDepartement, 
  }
}
