import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../../shared/hook/UseDependency'

export const useEditProduct = (props) => {
    const { productService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [productName, setProductName] = useState('');
    const [errorProductName, setErrorProductName] = useState('');
    const [showEditName, setShowEditName] = useState(false);
    const [failedEditProduct, setFailedEditProduct] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [id, setId] = useState(0)

    const onUpdateProduct = async (id, productName) => {
        setIsLoading(true)
        try {
            const response = await productService.updateProduct(id, productName)
            if (response.status === 'SUCCESS') {
                setSuccess(!success)
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditProduct(true)
                setErrorMessage('Connection problem')
            } else {
                setFailedEditProduct(true)
                setErrorMessage('Failed to update product name');
            }
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    }

    const handleChangeName = (value) => {
        setProductName(value);
    };

    const handleErrorName = (value) => {
        setErrorProductName(value);
    };

    const handleSaveYes = () => {
        onUpdateProduct(id, productName);
        setSave(!save);
    };

    const handleCancel = () => {
        setProductName('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/product-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            productName !== '' &&
            errorProductName === ''
        ) {
            setSave(!save);
        }
    };

    return {
        handleErrorName,
        save,
        setSave,
        handleChangeName,
        handleSaveYes,
        handleCancel,
        handleSave,
        isLoading,
        productName,
        showEditName,
        setShowEditName,
        failedEditProduct,
        setFailedEditProduct,
        errorMessage,
        submit,
        success,
        setSuccess,
        cancel,
        setCancel,
        id, 
        setId,
  }
}
