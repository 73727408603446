import React from "react";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import {
  MdOutlineKeyboardArrowDown,
  MdImportContacts,
  MdSchedule,
  MdTaskAlt,
} from "react-icons/md";
import { BarChart } from "../../../shared/components/Barchart/BarChart";
import { useDashboard2 } from "./useDashboard2";
import "./Dashboard2View.css";
import { USER } from '../../../shared/constants';

export const Dashboard2View = () => {
  const {
    summaryData,
    chartData,
    setShowBy,
    setShowDate,
    setShowMonth,
    showDate,
    filterDate,
    showMonth,
    filterMonth,
    handleFilterDate,
    handleFilterMonth,
    handleClearFilter,
    clickCreatedTicket,
    clickAssignedTicket,
    summaryDataAssign,
    handleClickSummaryCreate,
    handleClickSummaryAssign,
    assignedSummary,
    handleClickSummaryAll,
    chartDataAssign
  } = useDashboard2();

  const user = JSON.parse(window.localStorage.getItem('user'));

  return (
    <>
      <div className="card border-light shadow-sm p-3">
        {clickCreatedTicket && (
          <div className="pt-2 text-center" style={{ color: "#B70621" }}>
            <h3 style={{ fontWeight: "bolder" }}>Created Ticket by Me</h3>
          </div>
        )}
        {clickAssignedTicket && (
          <div className="pt-2 text-center" style={{ color: "#B70621" }}>
            <h3 style={{ fontWeight: "bolder" }}>Assigned Ticket on Me</h3>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between">
        {user.roleName !== USER.ROLE.PIC ? (<div className="dropdown">
            <div className="pt-2 text-start" style={{ color: "#B70621" }}>
              <div className="d-flex align-items-center">
                <h4 style={{ fontWeight: "bolder", marginRight: "2px" }}>
                  Summary Info
                </h4>
                <MdOutlineKeyboardArrowDown size={25} />
              </div>
              <div className="dropdown-content">
                <p
                  onClick={handleClickSummaryAll}
                >
                  All My Tickets
                </p>
                <p
                  onClick={handleClickSummaryCreate}
                >
                  Created Ticket by Me
                </p>
                <p
                  onClick={handleClickSummaryAssign}
                >
                  Assigned Ticket on Me
                </p>
              </div>
            </div>
          </div>) : (
            <div className="pt-2 text-start" style={{ color: "#B70621" }}>
              <div className="d-flex align-items-center">
                <h4 style={{ fontWeight: "bolder", marginRight: "2px" }}>
                  Summary Info
                </h4>
              </div>
            </div>
          )}
          
          <DropdownButton title="Filter by" variant="light text-black">
            <Dropdown.Item
              className="text-black"
              onMouseEnter={() => {
                setShowDate(true);
                setShowMonth(false);
              }}
            >
              Date
            </Dropdown.Item>
            {showDate && (
              <>
                <Dropdown.Divider />
                <input
                  className="ms-2 me-2"
                  type="date"
                  value={filterDate}
                  onChange={handleFilterDate}
                />
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item
              className="text-black"
              onMouseEnter={() => {
                setShowMonth(true);
                setShowDate(false);
              }}
            >
              Month
            </Dropdown.Item>
            {showMonth && (
              <>
                <Dropdown.Divider />
                <input
                  className="ms-2 me-2"
                  type="month"
                  value={filterMonth}
                  onChange={handleFilterMonth}
                />
                <Dropdown.Divider />
              </>
            )}
            <Dropdown.Item className="text-black" onClick={handleClearFilter}>Clear Filter</Dropdown.Item>
          </DropdownButton>
        </div>

        <div className={(assignedSummary !== true && user.roleName !== USER.ROLE.PIC) ? "row row-cols-1 row-cols-md-3 g-4 pt-3" : "row row-cols-1 row-cols-md-2 g-4 pt-3"}>
        {(assignedSummary !== true && user.roleName !== USER.ROLE.PIC) && <div className="col">
             <div
              className="card border-light shadow"
              style={{ backgroundColor: "#B70621" }}
            >
              <div className="card-body d-flex justify-content-evenly align-items-center">
                <div>
                  <h3 className="card-title" style={{ color: "white" }}>
                    {summaryData.open}
                  </h3>
                  <div style={{ color: "white" }}>Open Ticket</div>
                </div>
                <div className="card ps-2 pe-2 pb-1 rounded-4 shadow-sm">
                  <MdImportContacts size={40} color="#B70621" />
                </div>
              </div>
            </div>
          </div>}

          <div className="col">
            <div
              className="card border-light shadow"
              style={{ backgroundColor: "#333132" }}
            >
              <div className="card-body d-flex justify-content-evenly align-items-center">
                <div>
                  <h3 className="card-title" style={{ color: "white" }}>
                    {(assignedSummary === true) ? summaryDataAssign.onProgress : summaryData.onProgress}
                  </h3>
                  <div style={{ color: "white" }}>On Progress Ticket</div>
                </div>
                <div className="card ps-2 pe-2 pt-1 pb-1 rounded-4 shadow-sm">
                  <MdSchedule size={40} color="#333132" />
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div
              className="card border-light shadow"
              style={{ backgroundColor: "#00AA91" }}
            >
              <div className="card-body d-flex justify-content-evenly align-items-center">
                <div>
                  <h3 className="card-title" style={{ color: "white" }}>
                  {assignedSummary === true ? summaryDataAssign.closed : summaryData.closed}
                  </h3>
                  <div style={{ color: "white" }}>Closed Ticket</div>
                </div>
                <div className="card ps-2 pe-2 pt-1 pb-1 rounded-4 shadow-sm">
                  <MdTaskAlt size={40} color="#00AA91" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card border-light shadow-sm mt-3 p-2 mb-3">
        <div className="d-flex justify-content-end">
          <DropdownButton title="Show by" variant="light text-black">
            <Dropdown.Item
              className="text-black"
              onClick={() => setShowBy("daily")}
            >
              Daily
            </Dropdown.Item>
            <Dropdown.Item
              className="text-black"
              onClick={() => setShowBy("monthly")}
            >
              Monthly
            </Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="card-body d-flex justify-content-center">
          {assignedSummary !== true ? <BarChart chartData={chartData}/> : <BarChart chartData={chartDataAssign}/>}
        </div>
      </div>
    </>
  );
};
