import useOTPVerify from './UseOTPVerify';
import OtpInput from 'react-otp-input';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import OtpExpiredView from '../OtpExpired/OtpExpiredView';

const rendererResendOTP = ({ minutes, seconds, completed }) => {
    if (completed) {
        return <></>;
    } else {
        return (
            <span style={{ color: 'white' }}>
                ({minutes}:{seconds})
            </span>
        );
    }
};

export const ResendOTPCountdown = React.memo(({ resend }) => {
    const [reset, setReset] = useState(0);

    useEffect(() => {
        setReset(reset + 1);
    }, [resend]);

    return (
        <Countdown
            date={Date.now() + 60000}
            renderer={rendererResendOTP}
            zeroPadTime={2}
            key={reset}
        />
    );
});

const OTPVerifyView = (props) => {
    const {
        incorrectOTP,
        otp,
        resend,
        expired,
        handleChange,
        handleResend,
    } = useOTPVerify(props);

    const [reset, setReset] = useState(false);

    const resendOTP = () => {
        setReset(!reset);
        props.onPostEmail(props.email, true);
        handleResend();
    };

    return (
        <>
            <div className="otp-verify-container">
                <div className="otp-verify-content">
                    <div className="x" title="disabled for 1 minute">
                        <button
                            className="btn"
                            onClick={props.handleClose}
                            disabled={resend}
                        >
                            <strong>&#x2715;</strong>
                        </button>
                    </div>
                    <div className="otp">
                    <h3 style={{ color: '#B70621', textAlign: 'center' }}>
                        OTP Verification
                    </h3>
                    <p className="desc">
                        Your One Time Password has been send to your email
                        address.
                    </p>
                        <OtpInput
                            className="otp-input"
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                        />
                        {incorrectOTP ? (
                            <span
                                style={{ textAlign: 'center' }}
                                className="error"
                            >
                                <i>*OTP is not valid!</i>
                            </span>
                        ) : (
                            <br />
                        )}
                    </div>
                    <div>
                        <div className="desc">
                            <div style={{ marginTop: '7px' }}>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        resendOTP();
                                    }}
                                    disabled={resend}
                                >
                                    Get new OTP{' '}
                                    <ResendOTPCountdown resend={reset} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {expired && (
                <OtpExpiredView
                    handleResend={() => {
                        resendOTP();
                    }}
                />
            )}
        </>
    );
};

export default OTPVerifyView;
