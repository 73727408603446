import React, { useEffect, useState } from 'react'
import { AppLoading } from '../../../shared/components/AppLoading/AppLoading';
import { useTicketComment } from './useTicketComment';
import "./TicketComment.css"
import AppUnauthorized from '../../../shared/components/Popup/AppUnauthorized/AppUnauthorized';

export const TicketComment = () => {
    const { setButtonSubmit, newComment, setNewComment, onCreateComment, loading, user, unAuthorized, commentListFireStore} = useTicketComment();

    return (
    <>

        <div className='card border-light shadow-sm p-3 mt-5 mb-3'>
            <div className="p-2 bg-title text-white fs-5 fw-semibold w-100 position-absolute top-0 start-50 translate-middle text-center">
                Comment Box
            </div>

            {commentListFireStore.length !== 0 ? 
                <div className='commentBox bg-secondary bg-opacity-10 rounded pt-2 pb-2 mt-4'>
                <ul className='list-group'>
                    <div className='d-flex flex-column ps-5 pe-5'>
                    {commentListFireStore.map((cmFS, index) => {
                        if (cmFS.userId !== user.userId) {
                            return (
                                    <li key={index} className='list-group-item mt-2 rounded align-self-start shadow-sm' style={{borderColor: 'black', borderWidth: '1px'}}>
                                        <div className='fw-semibold'>{cmFS.userName}</div>
                                        <div className='ms-1'>{cmFS.commentMessage}</div> 
                                    </li>
                                )
                        } else {
                            return (
                                <li key={index} className='list-group-item mt-2 rounded align-self-end shadow-sm' style={{borderColor: '#B70621', color: 'black', borderWidth: '1px', backgroundColor:'#fce8eb'}}>
                                    <div className='d-flex justify-content-end' >
                                        <div className='fw-semibold'>{cmFS.userName}</div>
                                    </div>
                                    <div className='ms-1'>{cmFS.commentMessage}</div> 
                                </li>
                            )
                        }
                    })}
                    </div>
                </ul>
                </div>
            :
            <div className='commentBoxEmpty fw-semibold bg-secondary bg-opacity-10 rounded pt-2 pb-2 mt-4' style={{alignSelf: 'center'}}>There is no comment yet.</div>}
            <div style={{margin: '5px'}}></div>
            <h5>{commentListFireStore.length} Comment(s)</h5>
            <div className="mb-3 d-flex gap-2">
              <textarea type='submit' value={newComment.replace(/(\r\n|\n|\r)/gm, '')} 
              onKeyPress={e =>  e.code === 'Enter' && onCreateComment()} 
              onFocus={() => setButtonSubmit(true)} 
              onChange={(e) => setNewComment(e.target.value)} 
              className="form-control" id="exampleFormControlTextarea1" rows="1" 
              placeholder='Type a comment here and press Enter to send...'></textarea>

              <button className='btn btn-secondary' onClick={onCreateComment}>Send</button>
              <button className='btn btn-danger' onClick={() => setNewComment('')}>Clear</button>
            </div>
        </div>
        {loading && <AppLoading />}
        {unAuthorized && <AppUnauthorized />}
    </>
  )
}
