import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { apiClientFactory } from './shared/ApiClientFactory';
import { clientInstance } from './shared/AxiosClient';
import { serviceFactory } from './services/ServiceFactory';
import { DependencyProvider } from './shared/context/DependencyContext';
import { Provider } from 'react-redux';
// import { configureStore } from './shared/state/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const apiClient = apiClientFactory(clientInstance);
const services = serviceFactory(apiClient);

root.render(
    <React.StrictMode>
        <BrowserRouter>
        {/* <Provider store={store}> */}
          <DependencyProvider services={services}>
            <App />
          </DependencyProvider>
        {/* </Provider> */}
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
