// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore"; 

const firebaseConfig = {
  apiKey: "AIzaSyB7fP5JEYphypuqxOu5ppKmKzEQbLZcKJc",
  authDomain: "ticket-handing-narindo.firebaseapp.com",
  projectId: "ticket-handing-narindo",
  storageBucket: "ticket-handing-narindo.appspot.com",
  messagingSenderId: "226695174629",
  appId: "1:226695174629:web:7d8b1ce56ed729e461b70c",
  measurementId: "G-EBVD9VQ64R"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const dbInstance = getFirestore(firebaseApp);