import React from "react";
import "./CreateTicketView.css";
import { AppInputText } from "./../../../shared/components/AppInput/AppInputText";
import { AppButtonSubmitPrimary } from "../../../shared/components/AppButton/AppButtonSubmitPrimary";
import { useCreateTicket } from "./useCreateTicket";
import { CreateTicketConfirmation } from "./createTicketConfirmation/CreateTicketConfirmation";
import { AppLoading } from "../../../shared/components/AppLoading/AppLoading";
import AddUserSuccessView from "../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView";
import AddFailedView from "../../../shared/components/Popup/AddFailed/AddFailedView";
import AppUnauthorized from "../../../shared/components/Popup/AppUnauthorized/AppUnauthorized";
import { useEffect } from "react";

export const CreateTicketView = () => {
  const {
    departements,
    title,
    setTitle,
    departement,
    setDepartement,
    pic,
    setPic,
    listPic,
    listCategory,
    listProduct,
    priority,
    setPriority,
    description,
    setDescription,
    file,
    setFile,
    handleCreateTicket,
    confirmation,
    setConfirmation,
    loading,
    success,
    handleSuccess,
    validateFileSize,
    failed,
    setFailed,
    handleChangeDept,
    unAuthorized,
    failedCreateTicket,
    setFailedCreateTicket,
    product,
    setProduct,
    category,
    setCategory,
    productId, 
    setProductId, 
    categoryId, 
    setCategoryId,
    setSpecEstimateDone
  } = useCreateTicket();
  console.log(useCreateTicket());

  useEffect(() => {
    // console.log('priority', priority);
  }, [priority])

  const canSubmit =
    title.length <= 500 &&
    title.length !== 0 &&
    description.length <= 1000 &&
    description.length !== 0 &&
    Number(departement) !== 0 &&
    priority !== -1 &&
    file.length <= 10 &&
    validateFileSize() &&
    category.length !== 0 &&
    product.length !== 0;
    // console.log('list catgeory', category);
  return (
    <div>
      <div className="card border-light shadow-sm">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="card-title create-text bg-title-ct text-white p-2 fs-5 fw-semibold w-100 start-50 text-center">
            Create Ticket
          </div>
          {/* <hr style={{height:"2px" , width:'2px', borderWidth:"0", color: "black", backgroundColor:"black"}} /> */}
          <form className="w-75">
            <div className="row mb-2">
              <div className="col">
                {/* <AppInputText label='Title' placeholder='Max 500char' value={title} handleChange={setTitle}/> */}
                <label className="mb-2">
                  Title
                  <br />
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Max 500char"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {title.length > 500 && (
                  <span className="text-danger" style={{ fontSize: "15px" }}>
                    *title can not exceed 500 character
                  </span>
                )}
              </div>
              <div className="col">
                <label htmlFor="dataList" className="form-label">
                  Product
                </label>
                <br />
                <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setProductId(e.target.value)}
                  >
                    <option value={0} selected disabled>
                      Select product
                    </option>
                    {product.map((d) => {
                      return (
                        <option
                          key={d.productId}
                          value={d.productId}
                        >
                          {d.productName}
                          {/* {console.log("ini produk ", d.productName)} */}
                        </option>
                      );
                    })}
                  </select>
                 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div>
                  <label className="mb-2">
                    Departement
                    <br />
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChangeDept}
                  >
                    <option value={0} selected disabled>
                      Select departement
                    </option>
                    {departements.map((d) => {
                      return (
                        <option
                          key={d.picDepartmentId}
                          value={d.picDepartmentId}
                        >
                          {d.picDepartmentName}
                          {/* {console.log("INI DEPT DI FE --> ", d.picDepartmentName)} */}

                        </option>
                      );
                    })}
                    
                  </select>
                </div>
              </div>
              <div className="col">
                <label htmlFor="dataList" className="form-label">
                  Category
                </label>
                <br />
                {/*TODO set default, when change user PIC in category*/}
                <select
                  disabled={category.length === 0 }
                  className="form-select"
                  aria-label="Default select example"
                  // defaultValue={}
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  <option value={-1}>Select Category</option>

                  {/* <option value={-10}>
                    Select Category
                  </option>
                   */}

                  {category.map((c)=>{
                    return (
                        <option key={c.categoryId} value={c.categoryId}>
                          {c.categoryName}

                          {/* {console.log("INI CATEGORY DI FE --> ", c.categoryId, c.categoryName)} */}
                        </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col">
                <label htmlFor="dataList" className="form-label">
                  PIC
                </label>
                {/* {console.log('INI DEPT', departement)} */}
                <select
                  disabled={listPic.length === 0 || departement === "3"}
                  // departmenet === 3 dihapus, karena diganti -1
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setPic(e.target.value)}
                >
                  <option value={0}>Select PIC</option>
                  {listPic.map((p) => {
                    return (
                      <option key={p.picName} value={p.picId}>
                        {p.picName}

                        {/* {console.log("INI PIC DI FE --> ", p.picId, p.picName)} */}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col">
                <label className="mb-3">Priority</label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={1}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Low
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={2}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Medium
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value={3}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    High
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio3"
                    value={4}
                    onChange={(e) => setPriority(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Special Case
                  </label>
                </div>
                {priority == 4 && 
                  (<div>
                      <div className="d-flex justify-content-between">
                      <a>Estimated Done Time</a>
                      <a style={{color: 'red', fontSize: '13px'}}>*minutes</a>
                      </div>
                      <input type="number" class="form-control" placeholder="Estimate Duration" onChange={(e) => setSpecEstimateDone(e.target.value)}/>
                  </div>)
                }
              </div>
            </div>
            <div className="description mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Description
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="7"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {description.length > 1000 && (
                <span className="text-danger" style={{ fontSize: "15px" }}>
                  *description can not exceed 1000 character
                </span>
              )}
            </div>
            <div className="d-flex align-items-end justify-content-between mb-4">
              <div>
                <label htmlFor="formFileMultiple" className="form-label">
                  File Attachment
                </label>
                <input
                  className={
                    window.screen.width >= 700
                      ? "form-control"
                      : "form-control input-create-ticket"
                  }
                  type="file"
                  id="formFileMultiple"
                  onChange={(e) => setFile(e.target.files)}
                  multiple
                  accept=".xls, .xlsx, .doc, .docx, .ppt, .pptx, .txt, .png, .jpeg, .jpg, .zip, .pdf"
                />
                {file.length > 10 && (
                  <span className="text-danger" style={{ fontSize: "15px" }}>
                    *maximum attachment is 10 files
                  </span>
                )}
                {!validateFileSize() && (
                  <span className="text-danger" style={{ fontSize: "15px" }}>
                    *maximum size of each attachment is 2MB
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-end w-25 mt-3">
                <AppButtonSubmitPrimary
                  label="Submit"
                  className={"btn btn-danger"}
                  disabled={!canSubmit}
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmation(true);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {confirmation && (
        <CreateTicketConfirmation
          handleYes={handleCreateTicket}
          handleClose={() => setConfirmation(false)}
        />
      )}
      {loading && <AppLoading />}
      {success && (
        <AddUserSuccessView label="Create Ticket" handleYes={handleSuccess} />
      )}
      {failed && (
        <AddFailedView
          failedMsg={"There is unsupported file extension in your attachment"}
          handleYes={() => setFailed(false)}
        />
      )}
      {unAuthorized && <AppUnauthorized />}
      {failedCreateTicket && (
        <AddFailedView
          failedMsg={"New ticket can't be created"}
          handleYes={() => setFailedCreateTicket(false)}
        />
      )}
    </div>
  );
};
