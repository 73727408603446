import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../../shared/hook/UseDependency'

const useEditCategory = () => {
    const { categoryService, departmentService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [categoryName, setCategoryName] = useState('');
    const [errorCategoryName, setErrorCategoryName] = useState('');
    const [showEditName, setShowEditName] = useState(false);
    const [showEditDepartment, setShowEditDepartment] = useState(false)
    const [failedEditCategory, setFailedEditCategory] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [id, setId] = useState (0)
    const [departmentName, setDepartmentName] = useState('')
    const [errorDepartmentName, setErrorDepartmentName] = useState('')
    const [departmentList, setDepartmentList] = useState([])
    const [departmentId, setDepartmentId]= useState(0)

    const onUpdateCategory = async (id, categoryName, departmentId) => {
        setIsLoading(true)
        try {
            const response = await categoryService.updateCategory(id, categoryName, departmentId)
            if (response.status === 'SUCCESS') {
                setSuccess(!success)
            }
        } catch (e) {
            if (e.message === 'Network Error') {
                setFailedEditCategory(true)
                setErrorMessage('Connection problem')
            } else {
                setFailedEditCategory(true)
                setErrorMessage('Failed to update category name');
            }
            console.log(e);
        } finally {
            setIsLoading(false)
        }
    }


    const onGetAllDepartement = async () => {
        setIsLoading(true);
        try {
            const response = await departmentService.getAllDepartments();
            if (response.status === 'SUCCESS') {
                setDepartmentList(response.message);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = (value) => {
        setCategoryName(value);
    };

    const handleErrorName = (value) => {
        setErrorCategoryName(value);
    };

    const handleChangeDepartmentName = (value) => {
        setDepartmentName(value)
    }

    const handleChange = (e) => {
        setDepartmentId(e.target.value)
    }

    const handleErrorDepartmentName = () => {
        setErrorDepartmentName('*this field is required');
    }

    const handleSaveYes = () => {
        onUpdateCategory(id, categoryName, departmentId);
        setSave(!save);
    };

    const handleCancel = () => {
        setCategoryName('');
        setDepartmentName('')
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/category-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            (categoryName !== '' &&
            errorCategoryName === '') ||
            (departmentName !== '' &&
            errorDepartmentName === '')
        ) {
            setSave(!save);
        }
    };

    return {
        handleErrorName,
        save,
        setSave,
        handleChangeName,
        handleSaveYes,
        handleCancel,
        handleSave,
        isLoading,
        categoryName,
        showEditName,
        setShowEditName,
        failedEditCategory,
        setFailedEditCategory,
        errorMessage,
        submit,
        success,
        setSuccess,
        cancel,
        setCancel,
        id,
        setId,
        departmentName,
        errorDepartmentName,
        departmentList,
        handleChangeDepartmentName,
        handleErrorDepartmentName,
        onGetAllDepartement,
        departmentList,
        showEditDepartment, 
        setShowEditDepartment,
        departmentId,
        setDepartmentId,
        handleChange
  }
}

export default useEditCategory
