import React, { useEffect, useState, useRef } from 'react';
import { AppInputText } from './AppInputText';

export const AppInputEmail = ({
    label,
    email,
    placeholder,
    handleChange,
    handleError,
    submit,
    emailExist,
    disable=false
}) => {
    const [errorEmail, setErrorEmail] = useState('');
    const emailReq = useRef(false);
    const emailRegex = useRef(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    useEffect(() => {
        emailReq.current =
            email !== ''
                ? RegExp(emailRegex.current).test(email.toLocaleLowerCase())
                : false;

        if (!emailReq.current && email !== '') {
            setErrorEmail('*invalid email format');
        } else if (submit > 0 && email === '') {
            setErrorEmail('*this field is required');
        } else if (emailExist) {
            setErrorEmail('*email already exist');
        } else {
           setErrorEmail('');
        }

        handleError(errorEmail);
    }, [email, submit, errorEmail, emailExist]);

    const handleChangeEmail = (value) => {
        handleChange(value);
    };

    return (
        <div>
            <AppInputText
                label={label}
                value={email}
                placeholder={placeholder}
                handleChange={handleChangeEmail}
                disable={disable}
            />
            <small className="error">
                <strong>
                    <i>{errorEmail}</i>
                </strong>
            </small>
        </div>
    );
};
