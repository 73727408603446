import React from 'react'

export const picService = ({doGet}) => {
  
    const getPicByDepartement = async (departementId) => {
        try {
            return await doGet({url : '/api/user/department/list/' + String(departementId)})
        } catch (error) {
            throw error
        }
    }

    const getDeptByUserId = async (userId) => {
      try {
        return await doGet({url : 'api/ticket/department/user/' + String(userId)})
      } catch (error) {
        throw error
      }
    }
  
  return {
    getPicByDepartement, getDeptByUserId
  }
}
