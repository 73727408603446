import React, { useEffect, useState } from 'react'
import { useDependency } from '../../hook/UseDependency';
import useViewState from '../../hook/UseViewState'

export const useHeader = () => {
    const {viewState, setLoading, setData, setError} = useViewState();
    const [listNotif, setNotif] = useState([]);
    const {notificationService} = useDependency();
    const user = JSON.parse(window.localStorage.getItem("user"));

    const onGetAllNotif = async () => {
        setLoading();
        try {
            const response = await notificationService.getAllNotification(user.userId, setNotif)
        } catch (error) {
            setError(error)
        }
    }

    const onClickNotif = async (deletedNotif) => {
        setLoading();
        try {
            const response = await notificationService.deleteNotif(user.userId, deletedNotif)
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        onGetAllNotif();
    },[])

  return {
    listNotif, onClickNotif, user
  }
}
