import React, { useState } from 'react'
import { BarChart } from '../../shared/components/Barchart/BarChart';
import { TicketData } from '../../Data';
import {MdSchedule, MdTaskAlt, MdImportContacts} from 'react-icons/md';
import { useDashboard } from './useDashboard';
import { Dropdown, DropdownButton } from 'react-bootstrap'
import AppUnauthorized from '../../shared/components/Popup/AppUnauthorized/AppUnauthorized';

export const DashboardView = () => {
    const {summaryData, chartData, showDate, setShowDate ,showMonth, setShowMonth, handleFilterDate, handleFilterMonth, filterDate, filterMonth, handleClearFilter, unAuthorized, setShowBy} = useDashboard();

    return (
      <>
          <div className="card border-light shadow-sm p-3">
            <div className="d-flex justify-content-between">
              <div className='pt-2 text-start' style={{color : '#B70621'}}>
                <h4 style={{fontWeight: 'bolder'}}> Summary Info</h4>
              </div>
              <div>
                <DropdownButton title="Filter by" variant="light text-black">
                  <Dropdown.Item className="text-black" onMouseEnter={()=>{setShowDate(true); setShowMonth(false)}}>Date</Dropdown.Item>
                    { showDate &&
                      <>
                        <Dropdown.Divider />
                          <input className='ms-2 me-2' type='date' value={filterDate} onChange={handleFilterDate}/>
                        <Dropdown.Divider />
                      </>
                    }
                  <Dropdown.Item className="text-black" onMouseEnter={()=>{setShowMonth(true); setShowDate(false)}}>Month</Dropdown.Item>
                    { showMonth &&
                      <>
                        <Dropdown.Divider />
                        <input className='ms-2 me-2' type='month' value={filterMonth} onChange={handleFilterMonth}/>
                        <Dropdown.Divider />
                      </>
                    }
                  <Dropdown.Item className="text-black" onClick={handleClearFilter}>Clear Filter</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            <div className='row row-cols-1 row-cols-md-3 g-4 pt-3'>
              <div className="col">
                <div className="card border-light shadow" style={{backgroundColor : '#B70621'}}>
                  <div className="card-body d-flex justify-content-evenly align-items-center">
                    <div>
                      <h3 className="card-title" style={{color : 'white'}}>{summaryData.open}</h3>
                      <div style={{color : 'white'}}>
                        Open Ticket
                      </div>
                    </div>
                    <div className='card ps-2 pe-2 pb-1 rounded-4 shadow-sm'>
                      <MdImportContacts size={40} color='#B70621'/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card border-light shadow" style={{backgroundColor : '#333132'}}>
                  <div className="card-body d-flex justify-content-evenly align-items-center">
                    <div>
                      <h3 className="card-title" style={{color : 'white'}}>{summaryData.onProgress}</h3>
                      <div style={{color : 'white'}}>
                        On Progress Ticket
                      </div>
                    </div>
                    <div className='card ps-2 pe-2 pt-1 pb-1 rounded-4 shadow-sm'>
                      <MdSchedule size={40} color='#333132'/>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card border-light shadow" style={{backgroundColor : '#00AA91'}}>
                  <div className="card-body d-flex justify-content-evenly align-items-center">
                    <div>
                      <h3 className="card-title" style={{color : 'white'}}>{summaryData.closed}</h3>
                      <div style={{color : 'white'}}>
                        Closed Ticket
                      </div>
                    </div>
                    <div className='card ps-2 pe-2 pt-1 pb-1 rounded-4 shadow-sm'>
                      <MdTaskAlt size={40} color='#00AA91'/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="card border-light shadow-sm mt-3 p-2 mb-3">
          <div className='d-flex justify-content-end'>
              <DropdownButton title="Show by" variant="light text-black">
                <Dropdown.Item className="text-black" onClick={() => setShowBy('daily')}>Daily</Dropdown.Item>
                <Dropdown.Item className="text-black" onClick={() => setShowBy('monthly')}>Monthly</Dropdown.Item>
              </DropdownButton>
          </div>
          <div className="card-body d-flex justify-content-center">
            <BarChart chartData={chartData}/>
          </div>
        </div>
        {unAuthorized && <AppUnauthorized />}
      </>
    )
}
