export const productService = ({doGet, doPost, doPut, doDelete}) => {
  
    const getAllProductPagination = async (page) => {
        try {
            return await doGet({ url: `/api/product/list/${page}` });
        } catch (error) {
            throw error;
        }
    };

    const getAllProduct = async () => {
        try {
            return await doGet({ url: '/api/product/list-product' });
        } catch (error) {
            throw error;
        }
    };

    const searchNameProduct = async (page, name) => { 
        try {
            return await doGet({
                url : '/api/product/search-name/' + page + "?name=" + name
            })
        } catch (error) {
            throw error
        }
    }

    const postProduct = async (name) => {    
        try {
            return await doPost({
                url: '/api/product/create',
                data: { productName: name },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateProduct = async (id, name) => {
        try {
            return await doPut({
                url: '/api/product/update/' + id,
                data: { productName: name },
            });
        } catch (e) {
            throw e;
        }
    };


    return {
        getAllProductPagination,
        searchNameProduct,
        postProduct,
        updateProduct,
        getAllProduct
    };
}