import { useState } from 'react';
import { useDependency } from '../../../shared/hook/UseDependency';
import { useNavigate } from 'react-router-dom';
import { useUnauthorized } from '../../../shared/components/Popup/AppUnauthorized/useUnauthorized';

const useAddDepartment = (props) => {
    const { departmentService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // jaga2 kalau perlu loading
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');
    const [code, setCode] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const {unAuthorized, setUnauthorized} = useUnauthorized();

    const [showEditName, setShowEditName] = useState(false);
    const [showEditCode, setShowEditCode] = useState(false);
    const [failedEditDept, setFailedEditDept] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false)

    const onUpdateDepartment = async (id, name, code) => {
        setIsLoading(true);
        try {
            const response = await departmentService.updateDepartment(
                id,
                name,
                code
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS UPDATE DEPARTMENT');
                setSuccess(!success);
            }
        } catch (e) {
            // if (e.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(e);
            // }
            if (e.message === 'Network Error') {
                setFailedEditDept(true)
                setErrorMessage('Connection problem')
            } else {
                setFailedEditDept(true)
                setErrorMessage('Failed to update department name');
            }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = (value) => {
        setName(value);
    };

    const handleErrorName = (value) => {
        setErrorName(value);
    };

    const handleChangeCode = (value) => {
        setCode(value);
    };

    const handleErrorCode = (value) => {
        setErrorCode(value);
    };

    const handleSaveYes = () => {
        onUpdateDepartment(name, code);
        setSave(!save);
    };

    const handleCancel = () => {
        setName('');
        setCode('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/department');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            code !== '' &&
            errorCode === '' &&
            name !== '' &&
            errorName === ''
        ) {
            setSave(!save);
        }
    };
    return {
        name,
        handleErrorName,
        handleChangeName,
        code,
        handleErrorCode,
        handleChangeCode,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        showEditName,
        setShowEditName,
        showEditCode,
        setShowEditCode,
        unAuthorized,
        failedEditDept,
        setFailedEditDept,
        errorMessage
    };
};

export default useAddDepartment;
