import './Delete.css';
import { FaTrashAlt } from 'react-icons/fa';
import React from 'react';
import { AppButtonSubmitPrimary } from '../../AppButton/AppButtonSubmitPrimary';

const DeleteView = ({ handleYes, handleClose }) => {
    return (
        <div className="warn-add-user-container">
            <div className="delete-ellips">
                <FaTrashAlt
                    className="delete-icon"
                    color="#6A6A6A"
                    size={90}
                />
            </div>
            <div className="delete-content">
                <br />
                <h5 style={{ color: '#6A6A6A', padding: '5px' }}>
                    Are you sure want to delete?
                </h5>
                <br />
                <div className="buttons">
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'Yes'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleYes}
                        />
                    </div>
                    <div className="warning-add-user-btn">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-secondary new-user-btn'}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteView;
