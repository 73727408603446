import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { USER } from '../../../shared/constants';
import { useDependency } from '../../../shared/hook/UseDependency';
import useViewState from '../../../shared/hook/UseViewState';

export const useDashboard2 = () => {
    const {viewState, setLoading, setData, setError} = useViewState();
    const [summaryData, setSummaryData] = useState({open : 0, onProgress : 0, closed : 0});
    const [summaryDataAssign, setsummaryDataAssign] = useState({onProgress : 0, closed : 0});
    const [chartData, setChartData] = useState({labels : [], datasets : [{}]});
    const [chartDataAssign, setChartDataAssign] = useState({labels : [], datasets : [{}]});
    const [showDate, setShowDate] = useState(false);
    const [showMonth, setShowMonth] = useState(false);
    const [filterDate, setFilterDate] = useState('');
    const [filterMonth, setFilterMonth] = useState('');
    const [showBy, setShowBy] = useState('daily');
    const {ticketService} = useDependency();
    const user = JSON.parse(window.localStorage.getItem('user'));
    const [clickCreatedTicket, setClickCreatedTicket] = useState(false);
    const [clickAssignedTicket, setAssignedTicket] = useState(false);
    const [assignedSummary, setSummaryAssigned] = useState(false)

    useEffect(() => {
        if (user.roleName === USER.ROLE.CS){
            if (clickAssignedTicket === true && clickCreatedTicket === false) {
                onGetTicketChartAssign(user.userId)
            } else if (clickAssignedTicket === false && clickCreatedTicket === true) {
                onGetTicketChartCreateCS(user.userId);
            } else {
                onGetTicketChartAllCS(user.userId)
            }
        } else {
            onGetTicketChartAssign(user.userId)
        }
    }, [showBy])

    useEffect(() => {
        if (user.roleName === USER.ROLE.CS) {
            if (clickAssignedTicket === true && clickCreatedTicket === false) {
                onGetTicketSummaryAssign(user.userId)
            } else if (clickAssignedTicket === false && clickCreatedTicket === true) {
                onGetTicketSummaryCSCreate(user.userId)
            } else {
                onGetTicketSummaryAllCS(user.userId)
            }
        } else {
            onGetTicketSummaryAssign(user.userId)
        }
    }, [filterDate, filterMonth])

    useEffect(() => {
        console.log('created', clickCreatedTicket);
        console.log('assigned', clickAssignedTicket);
    }, [clickCreatedTicket, clickAssignedTicket])

    const formatDate = (dateString) => {
        if (showBy === 'daily') {
            const options = {year : "numeric", month: "long", day: "numeric"}
            return new Date(dateString).toLocaleDateString(undefined, options)
        }
        const options = {year: "numeric", month: "long"}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const handleFilterDate = (e) => {
        setFilterDate(e.target.value);
        setFilterMonth('');
    }

    const handleFilterMonth = (e) => {
        setFilterMonth(e.target.value);
        setFilterDate('');
    }

    const onGetTicketSummaryAllCS = async (id) => {
        setLoading();
        try {
            let totalOpen = 0;
            let totalClose = 0;
            let totalProgress = 0;
            let response = '';
            if (filterDate !== '') {
                let [year, month, date] = filterDate.split('-');
                response = await ticketService.getTotalSummaryTicketByDateAllCS(id, date, month, year);
            } else if (filterMonth !== ''){
                let [year, month] = filterMonth.split('-');
                response = await ticketService.getTotalSummaryTicketByDateAllCS(id, '', month, year)
            } else {
                response = await ticketService.getTotalSummaryTicketAllCS(id)
            }
            if (response.status === 'SUCCESS') {
                response.message.summary.map((data) => {
                    if (data.statusId === 1) {
                        totalOpen = data.totalTicket
                    } else if (data.statusId === 2) {
                        totalProgress = data.totalTicket
                    } else {
                        totalClose = data.totalTicket
                    }
                })
            }
            // console.log('response cs semua', response);
            setSummaryAssigned(false)
            setSummaryData({open: totalOpen, onProgress: totalProgress, closed: totalClose})
        } catch (e) {
            console.log(e);
        } 
    }

    const onGetTicketSummaryCSCreate= async (csId) => {
        setLoading();
        try {
            let totalOpen = 0;
            let totalClose = 0;
            let totalProgress = 0;
            let response = '';
            if (filterDate !== '') {
                let [year, month, date] = filterDate.split('-');
                response = await ticketService.getTotalSummaryTicketByDateCreateCS(csId, date, month, year);
            } else if (filterMonth !== ''){
                let [year, month] = filterMonth.split('-');
                response = await ticketService.getTotalSummaryTicketByDateCreateCS(csId, '', month, year)
            } else {
                response = await ticketService.getTotalSummaryTicketCreateCS(csId)
            }
            if (response.status === 'SUCCESS') {
                response.message.summary.map((data) => {
                    if (data.statusId === 1) {
                        totalOpen = data.totalTicket
                    } else if (data.statusId === 2) {
                        totalProgress = data.totalTicket
                    } else {
                        totalClose = data.totalTicket
                    }
                })
            }
            setSummaryAssigned(false)
            setSummaryData({open: totalOpen, onProgress: totalProgress, closed: totalClose})
        } catch (e) {
            console.log(e);
        } 
    }

    const onGetTicketSummaryAssign= async (id) => {
        setLoading();
        try {
            let totalClose = 0;
            let totalProgress = 0;
            let response = '';
            if (filterDate !== '') {
                let [year, month, date] = filterDate.split('-');
                response = await ticketService.getTotalSummaryTicketByDateAssign(id, date, month, year);
            } else if (filterMonth !== ''){
                let [year, month] = filterMonth.split('-');
                response = await ticketService.getTotalSummaryTicketByDateAssign(id, '', month, year)
            } else {
                response = await ticketService.getTotalSummaryTicketAssign(id)
            }
            if (response.status === 'SUCCESS') {
                response.message.summary.map((data) => {
                    if (data.statusId === 2) {
                        totalProgress = data.totalTicket
                    } else {
                        totalClose = data.totalTicket
                    }
                })
            }
            setSummaryAssigned(true)
            setsummaryDataAssign({onProgress: totalProgress, closed: totalClose})
        } catch (e) {
            console.log(e);
        } 
    }

    const handleClickSummaryCreate = () => {
        setClickCreatedTicket(true)
        setAssignedTicket(false)
        onGetTicketSummaryCSCreate(user.userId)
        onGetTicketChartCreateCS(user.userId)
    }

    const handleClickSummaryAssign = () => {
        setClickCreatedTicket(false)
        setAssignedTicket(true)
        onGetTicketSummaryAssign(user.userId)
        onGetTicketChartAssign(user.userId)
    }

    const handleClickSummaryAll = () => {
        setClickCreatedTicket(false)
        setAssignedTicket(false)
        onGetTicketSummaryAllCS(user.userId)
        onGetTicketChartAllCS(user.userId)
    }

    const handleClearFilter = () => {
        setFilterDate('');
        setFilterMonth('');
        setShowDate(false);
        setShowMonth(false);
    }
    const onGetTicketChartCreateCS = async (id) => {
        setLoading();
        try {
            let response = {}
            if (showBy === 'daily') {
                response = await ticketService.getTotalSummaryChartCreateCS(id);
            } else {
                response = await ticketService.getTotalSummaryChartByMonthCreateCS(id);
            }

            if (response.status === 'SUCCESS') {
                let labels = [];
                if (showBy === 'daily') {
                    labels = response.message.summary.map(data => formatDate(data.date));
                } else {
                    labels = response.message.summary.map(data => formatDate(data.month));
                }

                const chartData = {
                    labels: labels,
                    datasets : [
                        {
                            label : 'Open Ticket',
                            data : response.message.summary.map(data => data.open),
                            backgroundColor : "#B70621",
                            maxBarThickness : 50
                        },
                        {
                            label : 'On Progress Ticket',
                            data : response.message.summary.map(data => data.onProgress),
                            backgroundColor : "#333132",
                            maxBarThickness : 50
                        },
                        {
                            label : 'Closed Ticket',
                            data : response.message.summary.map(data => data.closed),
                            backgroundColor : "#00AA91",
                            maxBarThickness : 50
                        }
                    ]
                }
                setChartData(chartData);
            } 
        } catch (e) {
            console.log(e);
        }
    }

    const onGetTicketChartAllCS = async (id) => {
        setLoading();
        try {
            let response = {}
            if (showBy === 'daily') {
                response = await ticketService.getTotalSummaryChartAllCS(id);
            } else {
                response = await ticketService.getTotalSummaryChartByMonthAllCS(id);
            }

            if (response.status === 'SUCCESS') {
                let labels = [];
                if (showBy === 'daily') {
                    labels = response.message.summary.map(data => formatDate(data.date));
                } else {
                    labels = response.message.summary.map(data => formatDate(data.month));
                }

                const chartData = {
                    labels: labels,
                    datasets : [
                        {
                            label : 'Open Ticket',
                            data : response.message.summary.map(data => data.open),
                            backgroundColor : "#B70621",
                            maxBarThickness : 50
                        },
                        {
                            label : 'On Progress Ticket',
                            data : response.message.summary.map(data => data.onProgress),
                            backgroundColor : "#333132",
                            maxBarThickness : 50
                        },
                        {
                            label : 'Closed Ticket',
                            data : response.message.summary.map(data => data.closed),
                            backgroundColor : "#00AA91",
                            maxBarThickness : 50
                        }
                    ]
                }
                setChartData(chartData);
            } 
        } catch (e) {
            console.log(e);
        }
    }

    const onGetTicketChartAssign = async (id) => {
        setLoading();
        try {
            let response = {}
            if (showBy === 'daily') {
                response = await ticketService.getTotalSummaryChartAssign(id);
            } else {
                response = await ticketService.getTotalSummaryChartByMonthAssign(id);
            }
            if (response.status === 'SUCCESS') {
                let labels = [];
                if (showBy === 'daily') {
                    labels = response.message.summary.map(data => formatDate(data.date));
                } else {
                    labels = response.message.summary.map(data => formatDate(data.month));
                }

                const chartData = {
                    labels: labels,
                    datasets : [
                        {
                            label : 'On Progress Ticket',
                            data : response.message.summary.map(data => data.onProgress),
                            backgroundColor : "#333132",
                            maxBarThickness : 50
                        },
                        {
                            label : 'Closed Ticket',
                            data : response.message.summary.map(data => data.closed),
                            backgroundColor : "#00AA91",
                            maxBarThickness : 50
                        }
                    ]
                }
                setChartDataAssign(chartData);
            } 
        } catch (e) {
            console.log(e);
        }
    }

    return {
        summaryData,
        setSummaryData,
        summaryDataAssign,
        setsummaryDataAssign,
        chartData,
        setChartData,
        chartDataAssign,
        showDate,
        setShowDate,
        setShowMonth,
        showMonth,
        filterDate,
        setFilterDate,
        filterMonth,
        setFilterMonth,
        showBy,
        setShowBy,
        handleFilterDate,
        handleFilterMonth,
        handleClearFilter,
        clickCreatedTicket,
        setClickCreatedTicket,
        clickAssignedTicket,
        setAssignedTicket,
        handleClickSummaryCreate,
        handleClickSummaryAssign,
        assignedSummary,
        handleClickSummaryAll
    }
}
