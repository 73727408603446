import './OTPVerify.css';
import { useEffect, useState } from 'react';
import { useDependency } from '../../../shared/hook/UseDependency';
import { useAuth } from '../../../shared/hook/UseAuth';

const useOTPVerify = (props) => {
    const { otpService } = useDependency();
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [incorrectOTP, setIncorrectOTP] = useState(false);
    const [expired, setExpired] = useState(false);
    const [resend, setResend] = useState(true);
    const { onLogin } = useAuth();

    useEffect(() => {
        if (resend) {
            setTimeout(() => {
                setResend(false);
            }, 60000);
            if (!expired) {
                setTimeout(() => {
                    setExpired(true);
                }, 600000);
            }
        }
    }, [resend]);

    const onPostOTP = async (email, otp) => {
        setIsLoading(true);
        try {
            const response = await otpService.postOTP(email, otp);
            if (response.status === 'SUCCESS') {
                setIncorrectOTP(false);
                onLogin(response.loginAsUser);
            }
        } catch (e) {
            setIncorrectOTP(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            onPostOTP(props.email, otp);
        } else {
            setIncorrectOTP(false);
        }
    };

    const handleResend = () => {
        setResend(true);
        setExpired(false);
    };

    return {
        incorrectOTP,
        otp,
        resend,
        expired,
        handleChange,
        handleResend,
    };
};

export default useOTPVerify;
