import React from 'react';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import useAddDepartment from './useAddDepartment';
import './AddDepartment.css';
import { useNavigate } from 'react-router-dom';
import AppUnauthorized from '../../../shared/components/Popup/AppUnauthorized/AppUnauthorized';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';

const AddDepartmentView = (props) => {
    const {
        name,
        handleErrorName,
        handleChangeName,
        code,
        handleErrorCode,
        handleChangeCode,
        save,
        handleSave,
        handleSaveYes,
        setSave,
        cancel,
        handleCancel,
        setCancel,
        success,
        setSuccess,
        submit,
        unAuthorized,
        failedAddDepart,
        setFailedAddDepart,
        errorMessage,
    } = useAddDepartment(props);
    const navigate = useNavigate();

    return (
        <>
        <div className="add-depart-flex-container">
            <div className="add-depart-container">
                <h3 style={{ color: '#b70621', textAlign: 'center' }}>Add Department</h3>
                <div className="underline"></div>
                <AppInputName
                    label={'Department Name'}
                    handleError={handleErrorName}
                    name={name}
                    placeholder={'max 100char'}
                    handleChange={handleChangeName}
                    submit={submit}
                />
                <br />
                <AppInputName
                    label={'Department Code'}
                    handleError={handleErrorCode}
                    name={code}
                    placeholder={'max 100char'}
                    handleChange={handleChangeCode}
                    submit={submit}
                />
                <br />
                <div className="buttons">
                    <div className="add-user-btn-left">
                        <AppButtonSubmitPrimary
                            label={'Save'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleSave}
                        />
                    </div>
                    <div className="add-user-btn-right">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-outline-danger'}
                            onClick={() => {
                                setCancel(!cancel);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                {save && (
                    <AddUserConfirmView
                        label={'Add New Department'}
                        handleYes={handleSaveYes}
                        handleClose={() => {
                            setSave(!save);
                        }}
                    />
                )}
                {success && (
                    <AddUserSuccessView
                        label={'Add Department'}
                        handleYes={() => {
                            setSuccess(!success);
                            navigate('/home/department')
                        }}
                    />
                )}
                {cancel && (
                    <AddUserCancelView
                        handleYes={handleCancel}
                        handleClose={() => {
                            setCancel(!cancel);
                        }}
                    />
                )}
            </div>
        </div>
        {unAuthorized && <AppUnauthorized />}
        {failedAddDepart && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedAddDepart(false)}/>}
        </>
        
    );
};

export default AddDepartmentView;
