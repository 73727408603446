import { Sidebar, Menu, SubMenu, MenuItem } from 'react-pro-sidebar';
import { MdHome, MdCreate } from 'react-icons/md';
import { IoTicket } from 'react-icons/io5';
import { ImUsers } from 'react-icons/im';
import { ImOffice } from 'react-icons/im';
import { MdCategory } from 'react-icons/md';
import { FaProductHunt } from 'react-icons/fa'
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { USER } from '../../../constants';
import { useEffect } from 'react';
import { TbReportAnalytics } from 'react-icons/tb'

export const AppSidebarV2 = ({ sidebar }) => {
    const location = useLocation();
    const navigation = useNavigate();
    const user = JSON.parse(window.localStorage.getItem('user'));

    const [activeMenu, setActiveMenu] = useState(location.pathname);

    const handleClick = (destination) => {
        navigation(destination);
        setActiveMenu(destination);
    };

    // useEffect(() => {
    //     setActiveMenu(location.pathname);
    // }, [location]);

    return (
        <Sidebar backgroundColor="#ececec" defaultCollapsed={true}>
            {sidebar ? (
                <>
                    <div className="card border-light rounded-pill shadow-sm m-3">
                    {window.screen.width >= 900  &&
                        <a className="d-flex flex-column align-items-center text-dark text-decoration-none">
                             <img
                                className="img-sidebar"
                                src="../narindoLogo.png"
                                width="200px"
                                alt="narindo-logo"
                            />
                            <h6>Complaint Ticket System</h6>

                        </a>
                    }
                    </div>
                </>
            ) : (
                <div className="card border-light rounded-pill border-light m-3 d-flex align-items-center">
                    <a className="d-flex flex-column align-items-center text-dark text-decoration-none"></a>
                    <img
                        className="img-sidebar"
                        src="../narindoTitleLogo.png"
                        width="50px"
                        alt="narindo-log"
                    />
                    <a />
                </div>
            )}
            <Menu>
                {user.roleName === USER.ROLE.SA ? 
                  (<MenuItem
                    onClick={() => handleClick('/home/dashboard')}
                    icon=<div
                        style={{
                            backgroundColor:
                                activeMenu === '/home/dashboard'
                                    ? '#B70621'
                                    : null,
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <MdHome
                            size={25}
                            color={
                                activeMenu === '/home/dashboard'
                                    ? 'white'
                                    : 'black'
                            }
                        />
                    </div>
                >
                    <div
                        style={
                            activeMenu === '/home/dashboard'
                                ? {
                                      color: 'white',
                                      backgroundColor: '#B70621',
                                      fontWeight: 'bold',
                                      paddingTop: '5px',
                                      paddingBottom: '5px',
                                      paddingLeft: '15px',
                                      borderRadius: '10px',
                                  }
                                : {}
                        }
                    >
                        Dashboard
                    </div>
                </MenuItem>)  : (<SubMenu
                    icon=<div
                        style={{
                            backgroundColor:
                                activeMenu === '/home/dashboard'
                                    ? '#B70621'
                                    : null,
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <MdHome
                            size={25}
                            color={
                                activeMenu === '/home/dashboard'
                                    ? 'white'
                                    : 'black'
                            }
                        />
                    </div>
                    label="Dashboard"
                >
                    <MenuItem onClick={() => handleClick('/home/dashboard')}>
                        <div
                            style={
                                activeMenu === '/home/dashboard'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : { paddingLeft: '15px' }
                            }
                        >
                            All Analytics
                        </div>
                    </MenuItem>
                    <MenuItem onClick={() => handleClick('/home/dashboard-2')}>
                        <div
                            style={
                                activeMenu === '/home/dashboard-2'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : { paddingLeft: '15px' }
                            }
                        >
                            My Analytics
                        </div>
                    </MenuItem>
                </SubMenu>)}
                
                {user.roleName === USER.ROLE.CS && (
                    <MenuItem
                        onClick={() => handleClick('/home/create-ticket')}
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/create-ticket'
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <MdCreate
                                size={25}
                                color={
                                    activeMenu === '/home/create-ticket'
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                    >
                        <div
                            style={
                                activeMenu === '/home/create-ticket'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : {}
                            }
                        >
                            Create Ticket
                        </div>
                    </MenuItem>
                )}
                {user.roleName === USER.ROLE.CS && (
                    <SubMenu
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/ticket-list' ||
                                    activeMenu === '/home/ticket-list-all' ||
                                    activeMenu === '/home/ticket-list-for-me'
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IoTicket
                                size={25}
                                color={
                                    activeMenu === '/home/ticket-list' ||
                                    activeMenu === '/home/ticket-list-all' ||
                                    activeMenu === '/home/ticket-list-for-me'
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                        label="List of Tickets"
                    >
                        <MenuItem
                            onClick={() => handleClick('/home/ticket-list-all')}
                        >
                            <div
                                style={
                                    activeMenu === '/home/ticket-list-all'
                                        ? {
                                              color: 'white',
                                              backgroundColor: '#B70621',
                                              fontWeight: 'bold',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingLeft: '15px',
                                              borderRadius: '10px',
                                          }
                                        : { paddingLeft: '15px' }
                                }
                            >
                                All Tickets
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleClick('/home/ticket-list')}
                        >
                            <div
                                style={
                                    activeMenu === '/home/ticket-list'
                                        ? {
                                              color: 'white',
                                              backgroundColor: '#B70621',
                                              fontWeight: 'bold',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingLeft: '15px',
                                              borderRadius: '10px',
                                          }
                                        : { paddingLeft: '15px' }
                                }
                            >
                                Created by Me
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                handleClick('/home/ticket-list-for-me')
                            }
                        >
                            <div
                                style={
                                    activeMenu === '/home/ticket-list-for-me'
                                        ? {
                                              color: 'white',
                                              backgroundColor: '#B70621',
                                              fontWeight: 'bold',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingLeft: '15px',
                                              borderRadius: '10px',
                                          }
                                        : { paddingLeft: '15px' }
                                }
                            >
                                Assigned to Me
                            </div>
                        </MenuItem>
                    </SubMenu>
                )}
                {user.roleName !== USER.ROLE.CS && (
                    <MenuItem
                        onClick={() => handleClick('/home/ticket-list')}
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/ticket-list'
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <IoTicket
                                size={25}
                                color={
                                    activeMenu === '/home/ticket-list'
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                    >
                        <div
                            style={
                                activeMenu === '/home/ticket-list'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : {}
                            }
                        >
                            List of Tickets
                        </div>
                    </MenuItem>
                )}
                {user.roleName === USER.ROLE.SA && (
                    <MenuItem
                        onClick={() => handleClick('/home/user-list')}
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/user-list'
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ImUsers
                                size={25}
                                color={
                                    activeMenu === '/home/user-list'
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                    >
                        <div
                            style={
                                activeMenu === '/home/user-list'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : {}
                            }
                        >
                            List of Users
                        </div>
                    </MenuItem>
                )}
                {user.roleName === USER.ROLE.SA && (
                    <MenuItem
                    onClick={() => handleClick('/home/category-list')}
                    icon=<div
                        style={{
                            backgroundColor:
                                activeMenu === '/home/category-list'
                                    ? '#B70621'
                                    : null,
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <MdCategory
                            size={25}
                            color={
                                activeMenu === '/home/category-list'
                                    ? 'white'
                                    : 'black'
                             }
                        />
                    </div>
                >
                    <div
                        style={
                            activeMenu === '/home/category-list'
                                ? {
                                     color: 'white',
                                     backgroundColor: '#B70621',
                                     fontWeight: 'bold',
                                     paddingTop: '5px',
                                     paddingBottom: '5px',
                                     paddingLeft: '15px',
                                     borderRadius: '10px',
                                  }
                                : {}
                    }
                >
                    List of Category
                    </div>
                </MenuItem>
                )}
               {user.roleName === USER.ROLE.SA && (
                <MenuItem
                    onClick={() => handleClick('/home/product-list')}
                    icon=<div
                        style={{
                            backgroundColor:
                                activeMenu === '/home/product-list'
                                    ? '#B70621'
                                    : null,
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <FaProductHunt
                            size={25}
                            color={
                                activeMenu === '/home/product-list'
                                    ? 'white'
                                    : 'black'
                             }
                        />
                    </div>
                >
                    <div
                        style={
                            activeMenu === '/home/product-list'
                                ? {
                                     color: 'white',
                                     backgroundColor: '#B70621',
                                     fontWeight: 'bold',
                                     paddingTop: '5px',
                                     paddingBottom: '5px',
                                     paddingLeft: '15px',
                                     borderRadius: '10px',
                                  }
                                : {}
                    }
                >
                    List of Product
                    </div>
                </MenuItem>
               )}
                {user.roleName === USER.ROLE.SA && (
                    <MenuItem
                        onClick={() => handleClick('/home/department')}
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/department'
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ImOffice
                                size={20}
                                color={
                                    activeMenu === '/home/department'
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                    >
                        <div
                            style={
                                activeMenu === '/home/department'
                                    ? {
                                          color: 'white',
                                          backgroundColor: '#B70621',
                                          fontWeight: 'bold',
                                          paddingTop: '5px',
                                          paddingBottom: '5px',
                                          paddingLeft: '15px',
                                          borderRadius: '10px',
                                      }
                                    : {}
                            }
                        >
                            Departments
                        </div>
                    </MenuItem>
                )}

                <SubMenu
                        icon=<div
                            style={{
                                backgroundColor:
                                    activeMenu === '/home/sla-report-cs' ||
                                    activeMenu === '/home/sla-report-pic' 
                                        ? '#B70621'
                                        : null,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <TbReportAnalytics
                                size={25}
                                color={
                                    activeMenu === '/home/sla-report-cs' ||
                                    activeMenu === '/home/sla-report-pic' 
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                        label="SLA Report"
                    >
                        <MenuItem
                            onClick={() => handleClick('/home/sla-report-cs')}
                        >
                            <div
                                style={
                                    activeMenu === '/home/sla-report-cs'
                                        ? {
                                              color: 'white',
                                              backgroundColor: '#B70621',
                                              fontWeight: 'bold',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingLeft: '15px',
                                              borderRadius: '10px',
                                          }
                                        : { paddingLeft: '15px' }
                                }
                            >
                                SLA Report CS
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleClick('/home/sla-report-pic')}
                        >
                            <div
                                style={
                                    activeMenu === '/home/sla-report-pic'
                                        ? {
                                              color: 'white',
                                              backgroundColor: '#B70621',
                                              fontWeight: 'bold',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              paddingLeft: '15px',
                                              borderRadius: '10px',
                                          }
                                        : { paddingLeft: '15px' }
                                }
                            >
                                SLA Report PIC
                            </div>
                        </MenuItem>
                    </SubMenu>
            </Menu>
        </Sidebar>
    );
};
