import {useState} from "react";

export const useLocalStorage = (keyName, defaultValue) => {
    const isObject = (val) => {
        if (val === null) { return false;}
        return ( (typeof val === 'function') || (typeof val === 'object') );
    }

    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName);

            if (value) {
                return value;
            } else {
                if (defaultValue !== null) {
                    window.localStorage.setItem(keyName, defaultValue);
                }
                return defaultValue;
            }
        } catch (err) {
            return defaultValue;
        }
    });
    const setValue = (newValue) => {
        try {
            if (newValue === null) {
                window.localStorage.removeItem(keyName);
                return
            }
            if (isObject(newValue)) {
                window.localStorage.setItem(keyName, JSON.stringify(newValue));
                setStoredValue(newValue);
                return
            }
            window.localStorage.setItem(keyName, newValue);
            setStoredValue(newValue);
        } catch (err) {
            throw new Error(err)
        }
    };
    return [storedValue, setValue];
}