import React from 'react';
import Table, {
    SelectColumnFilter,
} from '../../shared/components/AppTable/Table';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../shared/hook/UseDependency';
import { useEffect, useState } from 'react';
import DeleteView from '../../shared/components/Popup/Delete/Delete';
import AddUserSuccessView from '../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import './DepartmentList.css';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Tooltip } from '@mui/material';
import { useUnauthorized } from '../../shared/components/Popup/AppUnauthorized/useUnauthorized';
import AppUnauthorized from '../../shared/components/Popup/AppUnauthorized/AppUnauthorized';

export const DepartmentList = (props) => {
    const navigate = useNavigate();
    const { departmentService } = useDependency();
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const {unAuthorized, setUnauthorized} = useUnauthorized();

    useEffect(() => {
        onGetAllDepartment();
    }, [success]);

    const onGetAllDepartment = async () => {
        setIsLoading(true);
        try {
            const response = await departmentService.getAllDepartments();
            if (response.status === 'SUCCESS') {
                setResponseData(response.message);
            }
            // console.log('depart', response)
        } catch (e) {
            // if (e.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     alert('error getting response');
            // }
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onDeleteDepartment = async (id, name, code) => {
        setIsLoading(true);
        try {
            const response = await departmentService.deleteDepartment(id, name, code);
            if (response.status === 'SUCCESS') {
                console.log(`SUCCESS DELETE ${id}`);
                setSuccess(!success);
            }
        } catch (e) {
            alert('error getting response');
        } finally {
            setIsLoading(false);
        }
    };

    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'ID',
            //     accessor: 'id',
            // },
            {
                Header: 'Name',
                accessor: 'name',
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
            },
        ],
        []
    );

    return (
        <>
            <div>
                <button
                    onClick={() => {
                        navigate('/home/department/add-department');
                    }}
                    className="btn btn-outline-secondary btn-add-department"

                >
                    Add New Department
                </button>
                <Table
                    title={'List of Departments'}
                    columns={columns}
                    filterBy={false}
                    data={responseData.map((departmentItem, index) => {
                        return {
                            // id: departmentItem.picDepartmentId,
                            name: departmentItem.picDepartmentName,
                            code: departmentItem.picDepartmentCode,
                            actions: (
                                <>
                                    <button
                                        onClick={() => {
                                            navigate(
                                                '/home/department/edit-department',
                                                {
                                                    state: {
                                                        // id: departmentItem.picDepartmentId,
                                                        name: departmentItem.picDepartmentName,
                                                        code: departmentItem.picDepartmentCode,
                                                    },
                                                }
                                            );
                                        }}
                                        type="button"
                                        className="btn btn-icons"
                                    >
                                        <Tooltip title="Edit User">
                                            <div className="changeColorEdit">
                                                <BiEdit size={25} />
                                            </div>
                                        </Tooltip>
                                    </button>
                                    {/* <button
                                        onClick={() => {
                                            setSelectedData({
                                                id: departmentItem.picDepartmentId,
                                                name: departmentItem.picDepartmentName,
                                                code: departmentItem.picDepartmentCode,
                                            });
                                            setShowDelete(!showDelete);
                                        }}
                                        type="button"
                                        className="btn btn-icons"
                                    >
                                        <Tooltip title="Delete User">
                                            <div className="changeColorDelete">
                                                <RiDeleteBin6Line size={25} />
                                            </div>
                                        </Tooltip>
                                    </button> */}
                                </>
                            ),
                        };
                    })}
                />
            </div>
            <div>
                {/* {showDelete && (
                    <DeleteView
                        handleYes={() => {
                            onDeleteDepartment(
                                selectedData.id,
                                selectedData.name,
                                selectedData.code,
                            );
                            setShowDelete(!showDelete);
                        }}
                        handleClose={() => {
                            setShowDelete(!showDelete);
                        }}
                    />
                )} */}
                {success && (
                    <AddUserSuccessView
                        label={'Delete Department'}
                        handleYes={() => {
                            setSuccess(!success);
                        }}
                    />
                )}
            </div>
            {unAuthorized && <AppUnauthorized />}
        </>
    );
};
