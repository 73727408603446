import React from "react";
import { Link } from "react-router-dom";
import { USER } from "../../../shared/constants";
import "./TicketList.css";
import { useTicketList } from "./useTicketList";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { MdOutlineDelete } from "react-icons/md";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import AddUserConfirmView from "../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView";
import { AppLoading } from "../../../shared/components/AppLoading/AppLoading";
import AddUserSuccessView from "../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView";
import AppUnauthorized from "../../../shared/components/Popup/AppUnauthorized/AppUnauthorized";

export const TicketList = ({
  showAllTicket = false,
  showAssignedToMe = false,
}) => {
  const {
    ticketList,
    user,
    ticketListDept,
    page,
    pageTicketDept,
    handleChangePageAllTicket,
    handleChangePageTicketDept,
    showDept,
    setShowDept,
    handleSearch,
    departements,
    handleFilterDept,
    filterDept,
    handleClearFilter,
    filterStatus,
    showStatus,
    setShowStatus,
    handleFilterStatus,
    showDelete,
    triggerDelete,
    onDeleteTicket,
    loading,
    success,
    setSuccess,
    handleSuccessDelete,
    unAuthorized,
    setSort,
    searchTicket,
    setSearchTicket,
    handleOrder,
    order,
    ticket,
  } = useTicketList(showAllTicket, showAssignedToMe);

  const minutesToDhms = (minutes) => {
    minutes = Number(minutes);
    var d = Math.floor(minutes / 1440);
    var h = Math.floor((minutes % 1440) / 60);
    var m = Math.floor(minutes % 60);

    var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
    return dDisplay + hDisplay + mDisplay;
  };

  const onGoingTicket = (ticket, type = 1) => {
    let subs;
    if (type === 1) {
      subs = new Date() - new Date(ticket.ticketDate);
    } else {
      if (ticket.picId !== 0) {
        subs = new Date() - new Date(ticket.picTime);
      } else {
        return "-";
      }
    }
    const toMinute = new Date(subs).getTime() / (1000 * 60);
    return minutesToDhms(toMinute);
  };

  return (
    <>
      {(user.roleName === USER.ROLE.PIC || showAssignedToMe) && (
        <div className="card border-light shadow-sm p-3 ">
          <h4 className="card-title">Open Ticket In Your Department</h4>
          <div className="card-body">
            <div className="table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Ticket Number</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Department</th>
                    <th scope="col">PIC</th>
                    <th scope="col">Title</th>
                    <th scope="col">Priority</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {ticketListDept.map((ticket) => {
                    return (
                      <tr key={ticket.ticketId}>
                        <td scope="row">{ticket.ticketId}</td>
                        <td>
                          {new Date(ticket.ticketDate).toLocaleDateString()}
                        </td>
                        <td>{ticket.PicDepartment.picDepartmentName}</td>
                        {ticket.picId !== 0 ? (
                          <td>{ticket.Pic.picName}</td>
                        ) : (
                          <td>-</td>
                        )}
                        <td>{ticket.ticketSubject}</td>
                        <td>
                          {ticket.priorityId === 1 && (
                            <span className="badge text-bg-secondary">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 2 && (
                            <span className="badge text-bg-warning">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 3 && (
                            <span className="badge text-bg-danger">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 4 && (
                            <span className="badge text-bg-primary">
                              SPECIAL CASE
                            </span>
                          )}
                        </td>
                        <td>
                          {ticket.statusId === 1 && (
                            <span className="badge text-bg-danger">
                              {ticket.Status.statusName}
                            </span>
                          )}
                          {ticket.statusId === 2 && (
                            <span className="badge text-bg-secondary">
                              {ticket.Status.statusName}
                            </span>
                          )}
                          {ticket.statusId === 3 && (
                            <span className="badge text-bg-success">
                              {ticket.Status.statusName}
                            </span>
                          )}
                        </td>
                        <td>
                          {/*<button className='btn btn-secondary btn-sm'>*/}
                          {/*  <Link className='detail' to={'/home/ticket-detail'} state={{ ticketId: ticket.ticketId, origin: 'open-ticket' }}>*/}
                          {/*    Detail*/}
                          {/*  </Link>*/}
                          {/*</button>*/}
                          <Link
                            className="detail"
                            to={"/home/ticket-detail"}
                            state={{
                              ticketId: ticket.ticketId,
                              origin: "open-ticket",
                            }}
                          >
                            <button className="btn btn-secondary btn-sm">
                              Detail
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center">
              <ul className="pagination">
                {pageTicketDept !== 1 && (
                  <li
                    className="page-item"
                    onClick={() => handleChangePageTicketDept("prev")}
                  >
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                )}
                <li className="page-item disabled">
                  <a className="page-link" href="#">
                    {pageTicketDept}
                  </a>
                </li>
                {ticketList.length >= 10 && (
                  <li
                    className="page-item"
                    onClick={() => handleChangePageTicketDept("next")}
                  >
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="table table-responsive">
        <div className="card border-0 p-3 mt-4">
          <div className="d-flex justify-content-between">
            <div>
              {user.roleName === USER.ROLE.PIC || showAssignedToMe ? (
                <h4 className="card-title search-form">
                  Ticket List Assigned to You
                </h4>
              ) : (
                <h4 className="card-title search-form">Ticket List</h4>
              )}
            </div>
            <div className="d-flex gap-2">
              {/* <input
                type="text"
                className="form-control search-form"
                id="searchTicketNumber"
                placeholder="Search by number or title"
                value={searchTicket}
                onChange={(e) => setSearchTicket(e.target.value)}
              /> */}
              <input
                type="text"
                className="form-control search-form"
                id="searchTicketNumber"
                placeholder="Search by number or title"
                value={searchTicket}
                onChange={(e) => setSearchTicket(e.target.value)}
              />
              <button
                className="btn sort-btn btn-search"
                onClick={() => handleSearch(searchTicket)}
              >
                Search
              </button>

              <DropdownButton
                id="dropdown-basic-button"
                title="Sort by"
                variant="light text-black"
              >
                <Dropdown.Item
                  className="text-black"
                  onClick={() => setSort("CreatedAt")}
                >
                  Created
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-black"
                  onClick={() => setSort("UpdatedAt")}
                >
                  Last Update
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-black"
                  onClick={() => setSort("SolvingDuration")}
                >
                  Working Time
                </Dropdown.Item>
              </DropdownButton>
              <button
                className="btn btn-light btn-order sort-btn"
                onClick={() => handleOrder()}
              >
                {order === "DESC" ? (
                  <ImSortAlphaAsc color="black" size={17} />
                ) : (
                  <ImSortAlphaDesc color="black" size={17} />
                )}
              </button>
              <DropdownButton
                id="dropdown-basic-button"
                title="Filter by"
                variant="light text-black"
              >
                {user.roleName !== USER.ROLE.PIC && (
                  <Dropdown.Item
                    className="text-black"
                    onMouseEnter={() => {
                      setShowDept(true);
                      setShowStatus(false);
                    }}
                  >
                    Department
                  </Dropdown.Item>
                )}
                {showDept && (
                  <>
                    <Dropdown.Divider />
                    {departements.map((dept) => {
                      return (
                        <Dropdown.Item
                          key={dept.picDepartmentId}
                          className="text-black"
                          onClick={() => handleFilterDept(dept.picDepartmentId)}
                        >
                          {dept.picDepartmentName}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item
                  className="text-black"
                  onMouseEnter={() => {
                    setShowStatus(true);
                    setShowDept(false);
                  }}
                >
                  Status
                </Dropdown.Item>
                {showStatus && (
                  <>
                    <Dropdown.Divider />
                    {user.roleName !== USER.ROLE.PIC && (
                      <Dropdown.Item
                        className="text-black"
                        onClick={() => handleFilterStatus(1)}
                      >
                        Open
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      className="text-black"
                      onClick={() => handleFilterStatus(2)}
                    >
                      On Progress
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-black"
                      onClick={() => handleFilterStatus(3)}
                    >
                      Closed
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item
                  className="text-black"
                  onClick={() => handleClearFilter()}
                >
                  Clear Filter
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Ticket Number</th>
                  <th>Created At</th>
                  <th>Department</th>
                  <th>PIC</th>
                  <th>Title</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Working Time</th>
                  <th>Solving Time</th>
                  <th></th>
                  {user.roleName === USER.ROLE.SA && <th></th>}
                </tr>
              </thead>
              {ticketList.length !== 0 && (
                <tbody className="table-group-divider">
                  {ticketList.map((ticket) => {
                    return (
                      <tr key={ticket.ticketId}>
                        <td>{ticket.ticketId}</td>
                        <td>
                          {new Date(ticket.ticketDate).toLocaleDateString()}
                        </td>
                        <td>{ticket.PicDepartment.picDepartmentName}</td>
                        {ticket.picId !== 0 ? (
                          <td>{ticket.Pic.picName}</td>
                        ) : (
                          <td>-</td>
                        )}
                        <td>{ticket.ticketSubject}</td>
                        <td>
                          {ticket.priorityId === 1 && (
                            <span className="badge text-bg-secondary">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 2 && (
                            <span className="badge text-bg-warning">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 3 && (
                            <span className="badge text-bg-danger">
                              {ticket.Priority.priorityName}
                            </span>
                          )}
                          {ticket.priorityId === 4 && (
                            <span className="badge text-bg-primary">
                              SPECIAL CASE
                            </span>
                          )}
                        </td>
                        <td>
                          {ticket.statusId === 1 && (
                            <span className="badge text-bg-danger">
                              {ticket.Status.statusName}
                            </span>
                          )}
                          {ticket.statusId === 2 && (
                            <span className="badge text-bg-secondary">
                              {ticket.Status.statusName}
                            </span>
                          )}
                          {ticket.statusId === 3 && (
                            <span className="badge text-bg-success">
                              {ticket.Status.statusName}
                            </span>
                          )}
                        </td>
                        {ticket.solvingDuration === 0 ? (
                          <td style={{ color: "#aebac9" }}>
                            {" "}
                            {onGoingTicket(ticket)}{" "}
                          </td>
                        ) : (
                          <td> {minutesToDhms(ticket.solvingDuration)} </td>
                        )}
                        {ticket.picDuration === 0 ? (
                          <td style={{ color: "#aebac9" }}>
                            {" "}
                            {onGoingTicket(ticket, 2)}{" "}
                          </td>
                        ) : (
                          <td> {minutesToDhms(ticket.picDuration)} </td>
                        )}
                        <td>
                          <Link
                            className="detail text-white"
                            to={"/home/ticket-detail"}
                            state={{
                              ticketId: ticket.ticketId,
                              origin: "assigned-to-me",
                            }}
                          >
                            <button className="btn btn-secondary btn-sm">
                              {/*<Link className='detail text-white' to={'/home/ticket-detail'} state={{ ticketId: ticket.ticketId, origin:'assigned-to-me' }}>*/}
                              Detail
                              {/*</Link>*/}
                            </button>
                          </Link>
                        </td>
                        {user.roleName === USER.ROLE.SA && (
                          <td>
                            <button
                              className="btn btn-danger p-1"
                              onClick={() => triggerDelete(ticket.ticketId)}
                              disabled={ticket.statusId === 2}
                            >
                              <MdOutlineDelete size={20} />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {ticketList.length === 0 && searchTicket !== "" && (
                <tbody className="table-group-divider p-3">
                  <tr>
                    <td
                      colSpan={user.roleName === USER.ROLE.SA ? 9 : 8}
                      className="fw-bold text-center p-3"
                    >
                      {`Ticket with number or title "${searchTicket}" is not found`}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <ul className="pagination">
              {page !== 1 && (
                <li
                  className="page-item"
                  onClick={() => handleChangePageAllTicket("prev")}
                >
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
              )}
              <li className="page-item disabled">
                <a className="page-link" href="#">
                  {page}
                </a>
              </li>
              {ticketList.length >= 10 && (
                <li
                  className="page-item"
                  onClick={() => handleChangePageAllTicket("next")}
                >
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {showDelete && (
        <AddUserConfirmView
          label="delete ticket"
          handleYes={onDeleteTicket}
          handleClose={() => triggerDelete("")}
        />
      )}

      {loading && <AppLoading />}

      {success && (
        <AddUserSuccessView
          label="Delete Ticket"
          handleYes={handleSuccessDelete}
        />
      )}
      {unAuthorized && <AppUnauthorized />}
    </>
  );
};
