import { useEffect, useState } from 'react';
import React from 'react';

export const AppInputRole = ({ role, handleChange, handleError, submit }) => {
    const [errorRole, setErrorRole] = useState('');

    useEffect(() => {
        setErrorRole('');
        if (submit > 0 && role === '') {
            setErrorRole('*this field is required');
        }
        handleError(errorRole);
    }, [role, submit, errorRole]);

    const handleChangeRole = (e) => {
        handleChange(e.target.value);
    };

    return (
        <div>
            <div className="role" onChange={handleChangeRole}>
                <label>
                    Role
                    <input
                        className="role-item radio"
                        type="radio"
                        value="CUSTOMER SERVICE"
                        name="role"
                    />
                    CS
                    <input
                        className="role-item radio"
                        type="radio"
                        value="PIC"
                        name="role"
                    />
                    PIC
                    <input 
                        className='role-item radio'
                        type='radio'
                        value='SUPER ADMIN'
                        name='role'
                    />
                    Admin
                </label>
            </div>
            <small className="error">
                <strong>
                    <i>{errorRole}</i>
                </strong>
            </small>
        </div>
    );
};
