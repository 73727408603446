import React from 'react';
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useFilters,
    useSortBy,
    usePagination,
} from 'react-table';
import './Table.css';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{' '}
            <input
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search from ${count} records...`}
                className="input form-control"
            />
        </span>
    );
}

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <select
            name={id}
            id={id}
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            className={'department-dropdown'}
            style={{ width: '200px' }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

function Table({ columns, data, title, filterBy }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>
            <div className='table table-responsive'>
                <div className="feature-head">
                    <div className="h4 table-title">{title}</div>
                    <div className="feature-head-item">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </div>
                    {filterBy && (
                        <div className="feature-head-item">
                            {headerGroups.map((headerGroup) =>
                                headerGroup.headers.map((column) =>
                                    column.Filter ? (
                                        <div style={{ margin: '10px' }}>
                                            <div key={column.id}>
                                                <label htmlFor={column.id} >
                                                    {/* {column.render('Header')}:{' '} */}
                                                    {'Filter By '}{column.Header}:{' '}
                                                </label>
                                                {column.render('Filter')}
                                            </div>
                                        </div>
                                    ) : null
                                )
                            )}
                        </div>
                    )}
                </div>
                <table {...getTableProps()} className="table table-striped">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' ▼'
                                                    : ' ▲'
                                                : ' '}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination pagination-flex">
                <span>
                    Page{' '}
                    <strong>
                        {state.pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <div>
                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        className="btn btn-secondary"
                    >
                        {'<<'}
                    </button>{' '}
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="btn btn-secondary"
                    >
                        {'<'}
                    </button>{' '}
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="btn btn-secondary"
                    >
                        {'>'}
                    </button>{' '}
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                        className="btn btn-secondary"
                    >
                        {'>>'}
                    </button>{' '}
                </div>

                <select
                    value={state.pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                    className={'department-dropdown'}
                >
                    {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div>
                <pre>
                    <code>{JSON.stringify(state, null, 2)}</code>
                </pre>
            </div> */}
        </>
    );
}

export default Table;
