import React from 'react';
import './UserList.css';
import Table from '../../shared/components/AppTable/Table';
import DeleteView from '../../shared/components/Popup/Delete/Delete';
import AddUserSuccessView from '../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Tooltip } from '@mui/material';
import AppUnauthorized from '../../shared/components/Popup/AppUnauthorized/AppUnauthorized';
import useUserList from './useUserList';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ImSortAlphaAsc, ImSortAlphaDesc } from 'react-icons/im';
import { useHeader } from '../../shared/components/AppHeader/useHeader';

export const UserList = () => {
    const {navigate,responseData,showDelete,setShowDelete,selectedData,setSelectedData,setPage,unAuthorized,onDelete,columns,success,setSuccess,searchUser,setSearchUser,sort,setSort,handleOrder,order,departements,roles,handleFilterDepartment,handleFilterRole,handleChangePageAll,page,handleClearFilter,showDept,showRole,setShowDept,setShowRole}=useUserList();
    const {user} = useHeader();

    return (
        <>
            <div>
                <button
                    onClick={() => {
                        navigate('/home/user-list/add-user');
                    }}
                    className="btn btn-outline-secondary btn-add-user">
                    Add New User
                </button>
                <div className='table table-responsive'>
                <div className="card border-0 mt-4">
                <div className='d-flex justify-content-between'>
                <div>
                <h4 className='card-title search-form-user'>List of Users</h4>
                </div>
                <div className='d-flex gap-2'>
                <input type="text" className="form-control search-form-user" id="searchUserName" placeholder="Search user name" value={searchUser} onChange={(e)=> setSearchUser(e.target.value)}/>
                <DropdownButton id="dropdown-basic-button" title="Sort by" variant="light text-black">
                    <Dropdown.Item className="text-black" onClick={() => setSort('Name')}>Name</Dropdown.Item>
                    <Dropdown.Item className="text-black" onClick={() => setSort('CreatedAt')}>Created</Dropdown.Item>
                </DropdownButton>

                <button className='btn btn-light btn-order' onClick={() => handleOrder()} >
                    {order === 'DESC' ? <ImSortAlphaAsc color='black' size={17} /> : <ImSortAlphaDesc color='black' size={17} />}
                </button>

                <DropdownButton id="dropdown-basic-button" title="Filter by" variant="light text-black">
                <Dropdown.Item className="text-black" onMouseEnter={() => { setShowDept(true); setShowRole(false) }}>Department</Dropdown.Item>
                
                {showDept &&
                <>
                <Dropdown.Divider />
                {departements.map(dept => {
                    return <Dropdown.Item key={dept.picDepartmentId} className="text-black" onClick={() => handleFilterDepartment(dept.picDepartmentId)}>{dept.picDepartmentName}</Dropdown.Item>
                  })}
                  </>
                }
                <Dropdown.Item className="text-black" onMouseEnter={() => { setShowDept(false); setShowRole(true) }}>Role</Dropdown.Item>
                
                {showRole &&
                <>
                <Dropdown.Divider />
                {roles.map(role => {
                    return <Dropdown.Item key={role.roleId} className="text-black" onClick={() => handleFilterRole(role.roleId)} >{role.roleName}</Dropdown.Item>
                  })}
                  <Dropdown.Divider />
                </>
                }
                <Dropdown.Item className="text-black" onClick={() => handleClearFilter()}>Clear Filter</Dropdown.Item>
                </DropdownButton>
                </div>
                </div>
                <div className="card-body">
                    <table className="table"  class="table table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Department</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody className='table-group-divider'>
                            {/* {console.log(">>>Users",responseData)} */}
                                {responseData.map(users => {
                            return (
                            <tr key={users.userId}>
                                <td>{users.userName}</td>
                                <td>{users.userEmail}</td>
                                <td>{users.roleName}</td>
                                <td>{users.picDepartmentName === "" ? '-': users.picDepartmentName}</td>
                                <td><button  onClick={() => {navigate('/home/user-list/edit-user',{state: {id: users.userId,name:users.userName,email:users.userEmail,role:users.roleName,department:users.picDepartmentName === undefined ? '-': users.picDepartmentName},});}} type="button" className="btn btn-icons">
                                                        <Tooltip title="Edit User">
                                                            <div className="changeColorEdit">
                                                                <BiEdit size={25} />
                                                            </div>
                                                        </Tooltip>
                                    </button>
                                    {(user.userId !== users.userId ) && (
                                        <button
                                            onClick={() => {
                                            setSelectedData({
                                                        id: users.userId,
                                                        name: users.userName,
                                                        email: users.userEmail,
                                                        role: users.roleName,
                                                        department: users.picDepartment === undefined ? '-' : users.picDepartment,});
                                                            setShowDelete(true);
                                                        }} type="button" className="btn btn-icons">
                                    <Tooltip title="Delete User">
                                        <div className="changeColorDelete">
                                            <RiDeleteBin6Line
                                                size={25}
                                            />
                                       </div>
                                    </Tooltip>
                                    </button>
                                    )} 
                                </td>
                            </tr>
                            )})}
                        </tbody>
                        {(responseData.length == 0 && responseData !== '') &&
                        <tbody className='table-group-divider p-3'>
                            <tr>
                            <td colSpan={5} className='fw-bold text-center p-3'>
                                {`Data is not found`}
                            </td>
                            </tr>
                        </tbody>
                        }
                    </table> 
                    <div className='d-flex justify-content-center'>
                        <ul className="pagination">
                        {page !== 1 && 
                            <li className="page-item" onClick={() => handleChangePageAll('prev')}>
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                        }
                        <li className="page-item disabled"><a className="page-link" href="#">{page}</a></li>
                        {responseData.length >= 10 && 
                            <li className="page-item" onClick={() => handleChangePageAll ('next')}>
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        }
                        </ul>
                    </div>
                </div>
                </div>
                </div>
                </div>
                <div>
                {showDelete && (
                    <DeleteView
                        handleYes={() => {
                            onDelete(selectedData.id);
                            setShowDelete(false);
                        }}
                        handleClose={() => {
                            setShowDelete(false);
                        }}
                    />
                )}
                {success && (
                    <AddUserSuccessView
                        label={'Delete User'}
                        handleYes={() => {
                            setSuccess(!success);
                        }}
                    />
                )}
            </div>
            {unAuthorized && <AppUnauthorized />}
        </>
    );
};