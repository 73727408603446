import React, { useEffect, useState } from 'react';
import { AppInputText } from './AppInputText';

export const AppInputName = ({
    label,
    name,
    placeholder,
    handleChange,
    handleError,
    submit,
}) => {
    const [errorName, setErrorName] = useState('');

    useEffect(() => {
        setErrorName('');
        if (submit > 0 && name === '') {
            setErrorName('*this field is required');
        } else if (name.length >= 100) {
            setErrorName('*max 100 char!');
        }
        handleError(errorName);
    }, [name, submit, errorName]);

    const handleChangeName = (value) => {
        handleChange(value);
    };

    return (
        <div>
            <AppInputText
                label={label}
                value={name}
                placeholder={placeholder}
                handleChange={handleChangeName}
            />
            <small className="error">
                <strong>
                    <i>{errorName}</i>
                </strong>
            </small>
        </div>
    );
};
