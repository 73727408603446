import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../../shared/hook/UseDependency'

export const useAddCategory = () => {
    const { categoryService, departmentService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [departmentId, setDepartmentId] = useState(0);
    const [departmentList, setDepartmentList] = useState([])
    const [errorCategoryName, setErrorCategoryName] = useState('');
    const [errorDepartmentName, setErrorDepartmentName] = useState('');
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [failedAddCategory, setFailedAddCategory] = useState(false)
    const [failedAddDepartment, setFailedAddDepartment] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    
    useEffect(() => {
        onGetAllDepartement();
    }, [])

    const onPostCategory = async (name, code) => {
        setIsLoading(true);
        try {
            const response = await categoryService.postCategory(
                name, code
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS POST CATEGORY');
                setSuccess(!success);
            }
        } catch (e) {
            if (e.message === 'Network Error'){
                setFailedAddCategory(true);
                setErrorMessage('Connection problem');
                console.log(e.message);
            } else if (e.response.data.message.Message){
                setFailedAddCategory(true)
                setErrorMessage(e.response.data.message.Detail);
                console.log(e.response.data.message.Message);
            } else {
                setFailedAddCategory(true);
                setErrorMessage('New category can\'t be created');
                console.log(e);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onGetAllDepartement = async () => {
        setIsLoading(true);
        try {
            const response = await departmentService.getAllDepartments();
            if (response.status === 'SUCCESS') {
                setDepartmentList(response.message)
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = (value) => {
        setCategoryName(value);
    };

    const handleErrorName = (value) => {
        setErrorCategoryName(value);
    };

    const handleChangeDepartmentName = (e) => {
        setDepartmentId(e.target.value);
    };


    const handleErrorDepartmentName = () => {
        setErrorDepartmentName('*this field is required');
    };

    const handleSaveYes = () => {
        onPostCategory(categoryName, departmentId);
        setSave(!save);
    };

    const handleCancel = () => {
        setCategoryName('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/category-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            (categoryName !== '' &&
            errorCategoryName === '' ) &&
            (departmentId !== 0 &&
            errorDepartmentName === '')
        ) {
            setSave(!save);
        }
    };
    return {
        isLoading,
        categoryName,
        errorCategoryName,
        save,
        setSave,
        success,
        setSuccess,
        cancel,
        setCancel,
        failedAddCategory,
        failedAddDepartment,
        setFailedAddCategory,
        errorMessage,
        onPostCategory,
        handleChangeName,
        handleErrorName,
        handleSaveYes,
        handleCancel,
        handleSave,
        submit,
        setSubmit,
        handleChangeDepartmentName,
        handleErrorDepartmentName,
        setDepartmentId,
        departmentId,
        setErrorDepartmentName,
        errorDepartmentName,
        departmentList,
    }
}
