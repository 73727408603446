import React from 'react'
import { doc, setDoc } from "firebase/firestore"; 

export const firebaseService = (db) => {
  
    const createNewCollection = async (userId, name = '', role = '') => {
      try {
        await setDoc(doc(db, "notification", userId), {
            listNotif : [],
            name : name,
            role : role
          });
      } catch (error) {
        throw error
      }
    }

    const createNewCommentCollection = async (ticketId) => {
      try {
        await setDoc(doc(db, "comments", ticketId), {
          commentList: []
        })
      } catch (e) {
        throw e
      }
    }

  return {
    createNewCollection,
    createNewCommentCollection
  }
}
