import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../hook/UseAuth';

export const useUnauthorized = () => {
    const location = useLocation();
    const [unAuthorized, setUnauthorized] = useState(false);
    const {onLogout} = useAuth()

    const handleUnauthorized = () => {
        // let lastLoc = location.pathname
        onLogout();
    }

    return {
        unAuthorized ,setUnauthorized, handleUnauthorized
    }
}
