import Lottie from 'react-lottie'
import './AppLoading.css'
import loading from './loading.json'
import React from 'react'

export const AppLoading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loading,
      };
  return (
    <div className='backdrop-container'>
        <div>
            <Lottie options={defaultOptions} loop={true} style={{width:'1000px',height:'500px'}}/>
        </div>
    </div>
  )
}
