export const departmentService = ({ doGet, doPost, doPut, doDelete }) => {
    const getAllDepartments = async () => {
        try {
            return await doGet({ url: '/api/department/list' });
        } catch (error) {
            throw error;
        }
    };

    const postDepartment = async (name, code) => {
        try {
            return await doPost({
                url: '/api/department/create',
                data: {
                    picDepartmentName: name,
                    picDepartmentCode: code,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateDepartment = async (name, code) => {
        try {
            return await doPut({
                url: `/api/department/update-department/${code}`,
                data: {
                    picDepartmentName: name,
                    picDepartmentCode: code,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const deleteDepartment = async (id, name, code) => {
        try {
            return await doDelete({
                url: `/api/department/delete`,
                data: {
                    picDepartmentId: id,
                    picDepartmentName: name,
                    picDepartmentCode: code,
                },
            });
        } catch (e) {
            throw e;
        }
    };

    return {
        getAllDepartments,
        postDepartment,
        updateDepartment,
        deleteDepartment,
    };
};
