import React from 'react'
import { collection, getDocs, query, where, onSnapshot , doc, updateDoc, arrayUnion, arrayRemove} from "firebase/firestore"; 

export const notificationService = (db) => {
  
    const getAllNotification = async (userId, setNotif) => {
        try {
          const unsub = onSnapshot(doc(db, "notification", userId), (doc) => {
              const data = doc.data();
              setNotif(data.listNotif)
          });
        } catch (error) {
          throw error
        }
    }

    const deleteNotif = async (userId, deletedNotif) => {
      try {
        const docRef = doc(db, "notification", userId);
        await updateDoc(docRef, {
          listNotif : arrayRemove(deletedNotif)
        });
      } catch (error) {
        throw error
      }
    }

    const addNotif = async (userId, newNotif) => {
      try {
        const docRef = doc(db, "notification", userId);
        await updateDoc(docRef, {
          listNotif : arrayUnion(newNotif)
        });
      } catch (error) { //?
        throw error
      }
    }

    const createBroadcast = async (roleName, newNotif) => {
      try {
        const q = query(collection(db, "notification"), where("role", "==", roleName));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          addNotif(doc.id, newNotif)
        });
      } catch (error) {
        throw error
      }
    }

  return {
    getAllNotification, deleteNotif, addNotif, createBroadcast
  }
}
