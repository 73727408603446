import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AppButtonSubmitPrimary } from '../../../shared/components/AppButton/AppButtonSubmitPrimary';
import { AppInputName } from '../../../shared/components/AppInput/AppInputName';
import AddFailedView from '../../../shared/components/Popup/AddFailed/AddFailedView';
import AddUserCancelView from '../../../shared/components/Popup/AddUserCancel/AddUserCancelView';
import AddUserConfirmView from '../../../shared/components/Popup/AddUserConfirm/AddUserConfirmView';
import AddUserSuccessView from '../../../shared/components/Popup/AddUserSuccess/AddUserSuccessView';
import { useAddProduct } from './useAddProduct'
import './AddProduct.css';

export const AddProduct = () => {
    const {
        isLoading,
        productName,
        save,
        setSave,
        success,
        setSuccess,
        cancel,
        setCancel,
        failedAddProduct,
        setFailedAddProduct,
        errorMessage,
        handleChangeName,
        handleErrorName,
        handleSaveYes,
        handleCancel,
        handleSave,
        submit,
        setSubmit
    } = useAddProduct()
    const navigate = useNavigate();

    return (
        <>
            <div className='add-product-flex-container'>
                <div className='add-product-container'>
                    <h3 style={{ color: '#b70621', textAlign: 'center' }}>Add Product</h3>
                    <div className="underline"></div>
                    <AppInputName
                        label={'Product Name'}
                        handleError={handleErrorName}
                        name={productName}
                        placeholder={'max 100char'}
                        handleChange={handleChangeName}
                        submit={submit}
                    />
                    <div className='buttons'>
                        <div className='add-user-btn-left'>
                        <AppButtonSubmitPrimary
                            label={'Save'}
                            className={'btn btn-danger new-user-btn'}
                            onClick={handleSave}
                        />
                        </div>
                        <div className="add-user-btn-right">
                        <AppButtonSubmitPrimary
                            label={'Cancel'}
                            className={'btn btn-outline-danger'}
                            onClick={() => {
                                setCancel(!cancel);
                            }}
                        />
                    </div>
                    </div>
                </div>
                {save && (
                    <AddUserConfirmView
                        label={'Add New Product'}
                        handleYes={handleSaveYes}
                        handleClose={() => {
                            setSave(!save);
                        }}
                    />
                )}
                {success && (
                    <AddUserSuccessView
                        label={'Add New Product'}
                        handleYes={() => {
                            setSuccess(!success);
                            navigate('/home/product-list')
                        }}
                    />
                )}
                {cancel && (
                    <AddUserCancelView
                        handleYes={handleCancel}
                        handleClose={() => {
                            setCancel(!cancel);
                        }}
                    />
                )}
            </div>
            {failedAddProduct && <AddFailedView failedMsg={errorMessage} handleYes={() => setFailedAddProduct(false)}/>}
        </>
    )
}
