import { useEffect, useState } from 'react';
import React from 'react';

export const AppInputRoleChecked = ({
    role,
    handleChange,
    handleError,
    submit,
    checked,
}) => {
    const [errorRole, setErrorRole] = useState('');
    const [checkCS, setCheckCS] = useState(false);
    const [checkPIC, setCheckPIC] = useState(false);

    useEffect(() => {
        if (checked === 'CUSTOMER SERVICE') {
            setCheckCS(true);
            setCheckPIC(false);
        } else if (checked === 'PIC') {
            setCheckCS(false);
            setCheckPIC(true);
        }
        setErrorRole('');
        if (submit > 0 && role === '') {
            setErrorRole('*this field is required');
        }
        handleError(errorRole);
    }, [role, submit, errorRole]);

    const handleChangeRole = (e) => {
        handleChange(e.target.value);
    };

    return (
        <div>
            <div className="role" onChange={handleChangeRole}>
                <label>
                    Role
                    <input
                        className="role-item radio"
                        type="radio"
                        value="CUSTOMER SERVICE"
                        name="role"
                        checked={checkCS}
                    />
                    CS
                    <input
                        className="role-item radio"
                        type="radio"
                        value="PIC"
                        name="role"
                        checked={checkPIC}
                    />
                    PIC
                </label>
            </div>
            <small className="error">
                <strong>
                    <i>{errorRole}</i>
                </strong>
            </small>
        </div>
    );
};
