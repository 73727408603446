import React from 'react'
import { useEffect } from 'react'
import  useSlaReport  from './useSlaReport'

export const SlaReportCS = () => {
  const { getSla,  slaList, page, handleChangePageAll} = useSlaReport()
    
  useEffect(() => {
    getSla('CS')
    
  // console.log('sla list', slaList);
  }, [page])

  return (
    <>
        <div className='card border-light shadow-sm p-3'>
          <h4 className='card-title'>SLA Report CS</h4>
          <div className='card-body'>
            <div className='table table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    {/*<th scope="col">Id</th>*/}
                    <th scope="col">Name</th>
                    <th scope="col">Total Created Ticket</th>
                    <th scope="col">Total Assigned Ticket</th>
                    <th scope="col">Score</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Average Solving Duration</th>
                  </tr>
                </thead>
                <tbody className='table-group-divider'>
                  {slaList.length == 0 ? (
                    <tr>
                      <td colSpan='7' className='fw-bold text-center p-3'>
                                        Data is not found
                      </td> 
                    </tr>
                  )  : (
                    slaList.map(sla => (
                      <tr key={sla.userId} data-testid="sla-map">
                        {/*<td>{sla.userId}</td>*/}
                        <td>{sla.userName}</td>
                        <td>{sla.totalCreatedTicket}</td>
                        <td>{sla.totalAssignedTicket}</td>
                        <td>{sla.score}</td>
                        <td>{sla.rank}</td>
                        <td>{sla.averageSolvingDuration} minutes</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className='d-flex justify-content-center'>
                  <ul className='pagination'>
                    {page !== 1 && 
                      <li className="page-item" onClick={() => handleChangePageAll('prev')}>
                        <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                    }
                    <li className='page-item disabled'><a className="page-link" href="#">{page}</a></li>
                    {slaList.length >= 10 &&
                       <li className="page-item" onClick={() => handleChangePageAll ('next')}>
                        <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    }
                  </ul>
                </div>
            </div>
          </div>
        </div>
    </>
  )
}
