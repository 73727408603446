export const categoryService = ({doGet, doPost, doPut}) => {
  
    const getAllCategory = async (page) => {
        try {
            return await doGet({ url: '/api/category/list/'+ page });
        } catch (error) {
            throw error;
        }
    };

    const getAllByDeptIdWithPage = async (page, deptId) => {
        try {
            return await doGet({ 
                url: '/api/category/list-by/'+ page + "?deptId=" + deptId
             });
        } catch (error) {
            throw error;
        }
    };

    const getByDepartmentId = async (deptId) => { 
        try {
            return await doGet({
                url : '/api/category/listBy-deptId' + "?deptId=" + deptId
            });
        } catch (error) {
            throw error
        }
    }

    const searchNameCategory = async (page, name) => { 
        try {
            return await doGet({
                url : '/api/category/search-name/' + page + "?name=" + name,
            })
        } catch (error) {
            throw error
        }
    }

    const postCategory = async (name, code) => {    
        try {
            console.log(code)
            return await doPost({
                url: '/api/category/create',
                data: {
                    categoryName: name,
                    departmentId: Number(code),
                },
            });
        } catch (e) {
            throw e;
        }
    };

    const updateCategory = async (id, categoryName, departmentId) => {
        try {
            return await doPut({
                url: '/api/category/update/' + id,
                data: {
                    categoryName: categoryName,
                    departmentId: Number(departmentId)
                },
            });
        } catch (e) {
            throw e;
        }
    };


    return {
        getAllCategory,
        getAllByDeptIdWithPage,
        getByDepartmentId,
        searchNameCategory,
        postCategory,
        updateCategory
    };
}