import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { categoryService } from '../../../services/CategoryService';
import { productService } from '../../../services/ProductService';
import { useUnauthorized } from '../../../shared/components/Popup/AppUnauthorized/useUnauthorized';
import { useDependency } from '../../../shared/hook/UseDependency'
import useViewState from '../../../shared/hook/UseViewState';

export const useCreateTicket = () => {
    const [loading, setLoading] = useState(true);
    const [departements, setDepartements] = useState([]);
    const [category, setCategory] = useState([]);
    const [product, setProduct] = useState([]);
    const [title, setTitle] = useState('');
    const [departement, setDepartement] = useState(0);
    const [productId, setProductId] = useState(0);
    const [categoryId, setCategoryId] = useState(-10)
    const [listPic, setListPic] = useState([]);
    const [pic, setPic] = useState(0);
    const [priority, setPriority] = useState(-1);
    const [description, setDescription] = useState('');
    const [file, setFile] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const {departementService, picService, productService, categoryService, ticketService, notificationService, firebaseService} = useDependency();
    const user = JSON.parse(window.localStorage.getItem("user"));
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const {unAuthorized, setUnauthorized} = useUnauthorized();
    const [failedCreateTicket, setFailedCreateTicket] = useState(false);
    const [specEstimateDone, setSpecEstimateDone] = useState(0);
    const navigate = useNavigate();

    const onGetAllDepartement = async () => {
        setLoading(true)
        try {
            const response = await departementService.getAllDepartement();
            if (response.status === 'SUCCESS') {
                setDepartements(response.message)
            }
            // console.log('depart', response.message); 
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const onGetAllCategoryByDepartment = async () => {
        setLoading(true)
        try {
            const response = await categoryService.getByDepartmentId(departement);
            if (response.status === 'SUCCESS') {
                // console.log(response.message, "INI MESSAGE ");
                setCategory(response.message)
                console.log(category);
               
            } 
            // console.log('response category', response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const onGetAllProduct = async () => {
        setLoading(true)
        try {
            const response = await productService.getAllProduct();
            if (response.status === 'SUCCESS') {
                setProduct(response.message)
            } 
            // console.log('product', response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const onGetPicDepartement = async () => {
        setLoading(true)
        try {
            const response = await picService.getPicByDepartement(departement);
            if (response.status === 'SUCCESS'){
                // console.log(">>>>>>",response.message);
                if (response.message !== null) {
                    if (response.message.length !== 0) {
                        setListPic(response.message)
                    } 
                }
            }
        } catch (error) {
            // if (error.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(error);
            // }
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    const clearForm = () => {
        setTitle('');
        setDescription('');
        setDepartement(-1);
        setPic('');
        setPriority(-1);
        setFile('');
        setCategoryId('');
    }

    // useEffect(() => {
    //     setCategoryId(-1)
    // }, [departement])

    const handleChangeDept = (e) => {
        // setCategoryId(0)
        // console.log('INI MASUKKK ',e.target.value)

        // setPic(0)
        // setCategoryId(0)

        // setPic(0)
        // setCategoryId(-10)
       

        // console.log("INI PIC ==> ", pic)
        // console.log("INI CATEGORY ==> ", categoryId)
        // console.log("INI DEPT ==> ", departement)
        if (e.target.value === "3") {
            setPic(0)
            // setCategoryId(-1)
            setDepartement(e.target.value)
            console.log('INI MASUK TIGA JANGKRIK');

            console.log("INI PIC Jadi 0 ==> ", pic)
            console.log("INI CATEGORY Jadi 0 ==> ", categoryId)
            console.log("INI DEPT Jadi -1 ==> ", departement)
        } else {
            setDepartement(e.target.value)
            console.log("MASUKKKKK --> ", e.target.value)
            console.log("DEPARTEMENT ", e.target.value);
            console.log("INI PIC ==> ", pic)
            console.log("INI CATEGORY ==> ", categoryId)
        }

        // setDepartement(e.target.value)
        // console.log("MASUKKKKK --> ", e.target.value)
        
    }

    const handleSuccess = () => {
        setSuccess(false);
        navigate('/home/ticket-list', {replace : true});
    }

    const handleCreateTicket = async (e) => {
        setConfirmation(false)
        setLoading(true);
        e.preventDefault();
        const ticketFormData = new FormData();
        ticketFormData.append("csId", user.userId);
        ticketFormData.append("ticketSubject", title);
        ticketFormData.append("ticketMessage", description);
        ticketFormData.append("departmentId", Number(departement));
        ticketFormData.append("priorityId", Number(priority));
        ticketFormData.append("picId", Number(pic));
        ticketFormData.append("categoryId", Number(categoryId));
        ticketFormData.append("productId", Number(productId))
        ticketFormData.append("specEstimateDone", Number(specEstimateDone))
        for (let i = 0; i < file.length; i++) {
            ticketFormData.append("fileAttachment", file[i])
        }

        try {
            const response = await ticketService.createTicket(ticketFormData);
            if (response.status === 'SUCCESS') {
                if (departement === '3') {
                    onCreateNotifToAdmin(response.ticketId)
                }
                if (pic !== 0){
                    const picResponse = await ticketService.getPicByPicId(pic);
                    if (picResponse.status === 'SUCCESS') {
                        onCreateNotif(picResponse.message.picUser, response.ticketId)
                    }
                }
                await firebaseService.createNewCommentCollection(response.ticketId)
                // console.log('createCommentCollection with ID', response.ticketId);
                clearForm();
                setSuccess(true)
            }
        } catch (error) {
            console.log('error create ticket',error);
            if (error.message === 'Network Error') {
                setFailedCreateTicket(true)
            } else if (error.response.data.message === 'File extension is forbidden') {
                setFailed(true)
                console.log(error);
            } else {
                console.log(error);
                setFailedCreateTicket(true)
            }
        } finally {
            setLoading(false);
        }
    }

    const validateFileSize = () => {
        if (file.length === 0) {
            return true
        }

        for (let i = 0; i < file.length; i++) {
            if (file[i].size > 2000000){
                return false
            }
        }

        return true
    }

    const onCreateNotif = async (userId, ticketId) => {
        let newNotif = {
            ticketId : ticketId,
            type : "assignment"
        }
        try {
            await notificationService.addNotif(userId, newNotif)
        } catch (error) {
            console.log(error);
        }
    }

    const onCreateNotifToAdmin = async (ticketId) => {
        let newNotif = {
            ticketId : ticketId,
            type : "created"
        }
        try {
            await notificationService.createBroadcast('sa', newNotif)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        onGetAllDepartement();
        onGetAllProduct();
    }, [])
    
    useEffect(() => {
        onGetPicDepartement();
    }, [departement])

    useEffect(() => {
        onGetAllCategoryByDepartment()
    }, [departement])
  
    return {
        departements, title, setTitle, departement, setDepartement, category, setCategory, product, setProduct, pic, setPic, listPic, priority, setPriority, description, setDescription, file, setFile, handleCreateTicket, confirmation, setConfirmation, loading, success, handleSuccess, validateFileSize, failed, setFailed, handleChangeDept, unAuthorized, failedCreateTicket, setFailedCreateTicket, productId, setProductId, categoryId, setCategoryId, setSpecEstimateDone
    }
}