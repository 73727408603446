import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDependency } from '../../shared/hook/UseDependency'

const useSlaReport = () => {
  const { ticketService } = useDependency()
  const [ slaList, setSlaList ] = useState([])
  const [page, setPage] = useState(1)

   const getSla = async (role) => {
    try {
      const response = await ticketService.getSla(role, page)
      if (response.message===null) {
        setSlaList([])
        return
      }
      setSlaList(response.message)
    } catch (e) {
      console.log(e);
    }
  }

  const handleChangePageAll = (task) => {
    if (task === 'next') {
      setPage(page + 1)
    } else {
      setPage(page - 1)
    }
  }

  return {
    getSla, slaList, page, handleChangePageAll
  }
}

export default useSlaReport