import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDependency } from '../../../shared/hook/UseDependency'

export const useAddProduct = () => {
    const { productService } = useDependency();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [productName, setProductName] = useState('');
    const [errorProductName, setErrorProductName] = useState('');
    const [save, setSave] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [failedAddProduct, setFailedAddProduct] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onPostProduct = async (name) => {
        setIsLoading(true);
        try {
            const response = await productService.postProduct(
                name
            );
            if (response.status === 'SUCCESS') {
                console.log('SUCCESS POST PRODUCT');
                setSuccess(!success);
                //on get Depart
                //setDepartmentList(response.message)
            }
        } catch (e) {
            if (e.message === 'Network Error'){
                setFailedAddProduct(true);
                setErrorMessage('Connection problem');
                console.log(e.message);
            } else if (e.response.data.message.Message){
                setFailedAddProduct(true)
                setErrorMessage(e.response.data.message.Detail);
                console.log(e.response.data.message.Message);
            } else {
                setFailedAddProduct(true);
                setErrorMessage('New department can\'t be created');
                console.log(e);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeName = (value) => {
        setProductName(value);
    };

    const handleErrorName = (value) => {
        setErrorProductName(value);
    };

    const handleSaveYes = () => {
        onPostProduct(productName);
        setSave(!save);
    };

    const handleCancel = () => {
        setProductName('');
        setCancel(!cancel);
        setSubmit(0);
        navigate('/home/product-list');
    };

    const handleSave = () => {
        setSubmit(submit + 1);
        if (
            productName !== '' &&
            errorProductName === ''
        ) {
            setSave(!save);
        }
    };
    return {
        isLoading,
        productName,
        errorProductName,
        save,
        setSave,
        success,
        setSuccess,
        cancel,
        setCancel,
        failedAddProduct,
        setFailedAddProduct,
        errorMessage,
        onPostProduct,
        handleChangeName,
        handleErrorName,
        handleSaveYes,
        handleCancel,
        handleSave,
        submit,
        setSubmit
    }
}
