import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectColumnFilter } from '../../shared/components/AppTable/Table';
import { useUnauthorized } from '../../shared/components/Popup/AppUnauthorized/useUnauthorized';
import { useDependency } from '../../shared/hook/UseDependency';

const useUserList = () => {
    const navigate = useNavigate();
    const { userService,departementService } = useDependency();
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [success, setSuccess] = useState(false);
    const [searchUser, setSearchUser] = useState('');
    const [sort, setSort] = useState('CreatedAt');
    const [deptId, setDeptId] = useState('');
    const [rId, setRId] = useState('');
    const [selectedData, setSelectedData] = useState({});
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('DESC')
    const [clickOrder, setClickOrder] = useState(false);
    const [departements, setDepartements] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filterDept, setFilterDept] = useState('');
    const [showRole, setShowRole] = useState(false);
    const [showDept, setShowDept] = useState(false);
    const [filterStatus, setFilterStatus] = useState('');
    const {unAuthorized, setUnauthorized} = useUnauthorized();

    useEffect(() => {
        onGetAllUser();
    }, [success,rId,deptId]);
    useEffect(() => {
        onGetAllDepartement();
    },[roles])
    useEffect(()=>{
        onGetAllRole();
        onGetAllUser();
    },[])
    useEffect(() => {
        onSearchUser();
    }, [searchUser])
    useEffect(() => {
        onGetAllUser();
    }, [order])
    useEffect(() => {
        onGetAllUser();
    }, [page])

    const onGetAllDepartement = async () => {
        setIsLoading(true)
        try {
            const response = await departementService.getAllDepartement();
            if (response.status === 'SUCCESS') {
                setDepartements(response.message)
            } 
        } catch (error) {
            // if (error.response.status === 401) {
            //     setUnauthorized(true)
            // } else {
            //     console.log(error);
            // }
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const onGetAllRole = async () => {
        setIsLoading(true)
        try {
            const response = await userService.getAllRole();
            if (response.status === 'SUCCESS') {
                setRoles(response.message)
            } 
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const onGetAllUser = async () => {
        setIsLoading(true);
        try {
            const response = await userService.getAllUsers(page,sort,order,deptId,rId);
            // console.log(">>> response",response);
            // console.log(">>> responseData",responseData);
            if (response.status === 'SUCCESS') {    
                if (response.message !== null) {
                    setResponseData(response.message);
                } else {
                    setResponseData([])
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onSearchUser = async () => {
        if(searchUser.length < 2){
            onGetAllUser();
            return
        }
        
        const response = await userService.searchUser(searchUser);
        if (response.status === 'SUCCESS') {
            if (response.message !== null){
                setResponseData(response.message)
            } else {
                setResponseData([])
            }
        }
    }

    const onDelete = async (userId) => {
        setIsLoading(true);
        try {
            const response = await userService.deleteUser(userId);
            if (response.status === 'SUCCESS') {
                console.log(`SUCCESS DELETE ${userId}`);
                setSuccess(!success);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Role',
                accessor: 'role',
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Department',
                accessor: 'department',
                Filter: SelectColumnFilter,
                filter: 'includes',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
            },
        ],
        []
    );

    const handleOrder = () => {
        setClickOrder(!clickOrder)
        setOrder(!clickOrder ? 'ASC': 'DESC')
    }

    const handleFilterRole = (roleId) => {
        // console.log('role_ID',roleId);
        setRId(roleId);
        setPage(1);
    }

    const handleFilterDepartment = (deptId) => {
        setDeptId(deptId);
        setPage(1);
    }

    const handleClearFilter = () => {
        setDeptId('');
        setRId('');
        setPage(1);
    }

    const handleChangePageAll = (task) => {
        if (task === 'next') {
            setPage(page + 1)
            return
        }
        setPage(page - 1)
    }
    // console.log('rd', responseData);
    return { navigate,isLoading,responseData,showDelete,setShowDelete,selectedData,setSelectedData,setPage,unAuthorized,onDelete,columns,success,setSuccess,searchUser,setSearchUser,sort,setSort,handleOrder,order,departements,handleFilterDepartment,roles,onGetAllRole,handleFilterRole,handleChangePageAll,page,handleClearFilter,showDept,showRole,setShowDept,setShowRole};
}

export default useUserList;
