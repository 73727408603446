import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DashboardView } from '../features/dashboard/DashboardView';
import AddDepartmentView from '../features/department/AddDepartment/AddDepartmentView';
import { DepartmentList } from '../features/department/DepartmentList';
import LoginPageView from '../features/login/LoginPage/LoginPageView';
import { TicketList } from '../features/ticket/ticketList/TicketList';
import AddUserView from '../features/userList/AddUser/AddUserView';
import { UserList } from '../features/userList/UserList';
import { CreateTicketView } from '../features/ticket/createTicket/CreateTicketView'
import { AppLayout } from '../shared/components/AppLayout/AppLayout';
import { AuthProvider } from '../shared/context/AuthContext';
import ProtectedPage from './ProtectedPage';
import { TicketDetail } from '../features/ticket/ticketDetail/TicketDetail';
import EditDepartmentView from '../features/department/EditDepartment/EditDepartmentView';
import EditUserView from '../features/userList/EditUser/EditUserView';
import AdminPage from './AdminPage';
import CsPage from './CsPage';
import { ProductList } from '../features/productList/ProductList';
import { AddProduct } from '../features/productList/AddProduct/AddProduct';
import { EditProduct } from '../features/productList/EditProduct/EditProduct';
import { CategoryList }  from '../features/categoryList/CategoryList';
import { AddCategory } from '../features/categoryList/AddCategory/AddCategory';
import { EditCategory } from '../features/categoryList/EditCategory/EditCategory';
import { Dashboard2View } from '../features/dashboard/dashboard2/Dashboard2View';
import PicPage from './PicPage';
import { SlaReportCS } from '../features/slaReport/SlaReportCS';
import { SlaReportPIC } from '../features/slaReport/SlaReportPIC';

export const AppRouter = () => {

    return (
        <AuthProvider>
            <Routes>
                <Route index element={<LoginPageView />} />
                <Route element={<ProtectedPage />}>
                    <Route path="/home" element={<AppLayout />}>
                        <Route path="dashboard" element={<DashboardView />}/>
                        <Route path="ticket-list" element={<TicketList />} />
                        <Route path="ticket-list-all" element={<TicketList showAllTicket={true} />} />
                        <Route path="ticket-list-for-me" element={<TicketList showAssignedToMe={true} />} />
                        <Route path="ticket-detail" element={<TicketDetail />} />
                        <Route path="sla-report-cs" element={<SlaReportCS/>}/>
                        <Route path="sla-report-pic" element={<SlaReportPIC/>}/>
                        <Route element={<CsPage />}>
                            <Route path='create-ticket' element={<CreateTicketView />} />
                        </Route>
                        <Route element={<AdminPage />}>
                            <Route path="user-list" element={<UserList />} />
                            <Route
                                path="user-list/add-user"
                                element={<AddUserView />}
                            />
                            <Route 
                                path="user-list/edit-user"
                                element={<EditUserView />}
                            />
                            <Route path="department" element={<DepartmentList />} />
                            <Route
                                path="department/add-department"
                                element={<AddDepartmentView />}
                            />
                            <Route
                                path="department/edit-department"
                                element={<EditDepartmentView />}
                            />
                            <Route path="product-list" element={<ProductList/>} />
                            <Route 
                                path="product-list/add-product" 
                                element={<AddProduct/>}
                            />
                            <Route 
                                path="product-list/edit-product" 
                                element={<EditProduct/>}
                            />
                            <Route 
                                path="product-list" 
                                element={<ProductList/>} 
                            />
                            <Route 
                                path="category-list" 
                                element={<CategoryList/>} 
                            />
                            <Route 
                                path="category-list/add-category" 
                                element={<AddCategory/>} 
                            />
                            <Route 
                                path="category-list/edit-category" 
                                element={<EditCategory/>} 
                            />
                        </Route>
                        <Route element={<PicPage/>}>
                            <Route path="dashboard-2" element={<Dashboard2View/>}/>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </AuthProvider>
    );
};
